import { Box, Paper, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import BuildMatrixChart from './BuildMatrixChart';
import BusinessCaseTableHeader from './BusinessCaseTableHeader';

const BusinessBuildMatrix = (props:any) => {
  const { title, items, marketWideData, suggestedBuildAreaData } = props;
  const [showChart, setShowChart] = useState(false)
  const [showTable, setShowTable] = useState(true)

    const headingCellsStyle = {
        color:'#0057B8',
        border:'none',
        borderLeft: '1px solid #797C96',
        padding:'6px 10px'
    };

    const valueCellsStyle = {
        color:'white',
        border: "none",
        borderLeft: '1px solid #797C96',
        padding:'2px 10px'
    }

    const textStyle = {
        fontWeight:'bold',
        fontSize:'12px'
    }

    // build matrix chart data
    var trace1 = {
      x: [marketWideData[0], marketWideData[1]],
      y: ['In Suggested Build Area', 'Market Wide',],
      name: 'Eligible Households',
      type: 'bar',
      orientation: 'h',
      marker : {color : '#D21F3C'}

    };

    var trace2 = {
      x: [suggestedBuildAreaData[0], suggestedBuildAreaData[1]],
      y: ['In Suggested Build Area', 'Market Wide',],
      name: 'Non-BEAD Households (Incl. Other Funding Programs)',
      type: 'bar',
      orientation: 'h',
      marker : {color : '#18A8D8'}
    };

    const buildMatrixChart = {
        data:[trace1, trace2],
    }

    return (
        <Box sx={{marginTop:'8px', opacity:'1', borderRadius:'10px', background:'black'}}>
        <BusinessCaseTableHeader
            showChart = {showChart}
            setShowChart = {setShowChart}
            showTable={showTable}
            setShowTable={setShowTable}
            title = {title}
            isChart={true}
        />
        <Box sx={{paddingBottom:'5px'}}>
        {showTable &&
          <TableContainer component={Paper} sx={{ background:'black' }}>
          <Table size='small' sx={{}}>
            <TableHead>
              <TableRow>
                <TableCell width={200} sx={{color:'#0057B8',border:'none', padding:'6px 10px'}}></TableCell>
                <TableCell  width={30} sx={headingCellsStyle}><Typography sx={textStyle}>Market Opportunity</Typography></TableCell>
                <TableCell  width={30} sx={headingCellsStyle}><Typography sx={textStyle}>Build Output</Typography></TableCell>
                <TableCell  width={30} sx={headingCellsStyle}><Typography sx={textStyle}>Difference</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((row:any, index:number) => (
                <TableRow
                  key={"build__"+index}
                >
                  <TableCell sx={{color : 'white',border: "none", padding:'2px 10px'}} ><Typography sx={textStyle}>{row.item}</Typography> </TableCell>
                  <TableCell sx={valueCellsStyle} ><Typography sx={textStyle}>{row.market_opportunity}</Typography></TableCell>
                  <TableCell sx={valueCellsStyle} ><Typography sx={textStyle}>{row.build_output}</Typography></TableCell>
                  <TableCell sx={valueCellsStyle} ><Typography sx={textStyle}>{row.difference}</Typography></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
      {showChart &&
        <Box sx={{padding:'5px', marginLeft:'10px'}}>
            <BuildMatrixChart chartData = {buildMatrixChart} />
      </Box>
      }
      </Box></Box>
    );
}

export default BusinessBuildMatrix