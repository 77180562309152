import { Stack, Switch, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { SwitchProps } from '@mui/material/Switch';
import React from "react";


type ToggleSwitchProps = {
    checked : boolean,
    handleChange : (event : React.ChangeEvent<HTMLInputElement>) => void
}

export const ToggleSwitch = (props:ToggleSwitchProps) => {    

    const CustomSwitch = styled((props: SwitchProps) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 50,
        height: 22,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(28px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: '#029fdb',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#white',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 18,
          height: 18,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: '#029fdb',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));

    
  
    return (
        <Stack direction="row" spacing={1} alignItems="center">
             <Typography textAlign="center" color="text.secondary">County </Typography>                                                 
            <CustomSwitch
               checked={props.checked} 
               onChange={props.handleChange} 
                inputProps={{ 'aria-label': 'controlled' }}
            /> 
            <Typography textAlign="center" color="text.secondary">Municipality </Typography>        
      </Stack>
    )
  }


const CustomOnOffSwitch = styled(Switch)(({ theme }) => ({
  width: "50px",
  height: "28px",
  padding: "0px",
  '& .MuiSwitch-switchBase': {
    color: "#818181",
    padding: "1px",
    "&$checked": {
      "& + $track": {
        backgroundColor: "#23bf58"
      }
    }
  },
  '& .MuiSwitch-track': {
    borderRadius: "20px",
    backgroundColor: "#818181",
    opacity: "1 !important",
    "&:after, &:before": {
      color: "white",
      fontSize: "11px",
      position: "absolute",
      top: "6px"
    },
    "&:after": {
      content: "'ON'",
      left: "3px"
    },
    "&:before": {
      content: "'OFF'",
      right: "3px"
    }
  },
  '& .MuiSwitch-checked': {
    color: "#23bf58 !important",
    transform: "translateX(26px) !important"
  },
  '& .MuiSwitch-thumb': {
    color: "white",
    width: "24px",
    height: "24px",
    margin: "1px"
  }
}));


type OnOffSwitchProps = {
  checked : boolean,
  handleChange : (event : React.ChangeEvent<HTMLInputElement>) => void
}

export const OnOffSwitch = (props:OnOffSwitchProps) =>{
  return (
    <CustomOnOffSwitch
      checked={props.checked} 
      onChange={props.handleChange} 
    />
  );
}