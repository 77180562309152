import { Box, Paper, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { format } from "../../utils/functions";
import SubsidyChartHome from './SubsidyChartHome';
import SubsidyChartMarket from './SubsidyChartMarket';
import BusinessCaseTableHeader from './BusinessCaseTableHeader';

const BusinessSubsidy = (props:any) => {
  const {title, houseHoldData, marketData, subsidyHomeData, subsidyMarketData, backhaulCostPerCBG} = props;
  const [showChart, setShowChart] = useState(false)
  const [showTable, setShowTable] = useState(true)


    const headingCellsStyle = {
        color:'#0057B8',
        border:'none',
        borderLeft: '1px solid #797C96',
        padding:'6px 10px'
    };

    const valueCellsStyle = {
        color:'white',
        border: "none",
        borderLeft: '1px solid #797C96',
        padding:'2px 10px'
    }

    const textStyle = {
        fontWeight:'bold',
        fontSize:'12px'
    }


  // subsidy chart home data

  var subsidyHomeChartData = [
    {
        type: "waterfall",
        orientation: "v",
        measure: [
            "relative",
            "relative",
            "total",
        ],
        x: [
          "Upfront Cost",
          "Effective Subsidy",
          "Cost",
        ],
        textposition: "inside",
        text: [
          `$${format(subsidyHomeData[0])}`,
          `$${format(subsidyHomeData[1])}`,
          `$${format(subsidyHomeData[2])}`
        ],
        y: [
            subsidyHomeData[0],
            -subsidyHomeData[1],
            subsidyHomeData[2]
        ],
        connector: {
          line: {
            color: "rgb(0,0,0)"
          }
        },
        insidetextfont : { color: ["white", "white", "white"] },
        increasing: { marker: { color: "#D21F3C" }},
        decreasing: { marker: { color: "#ffbfbd" }},
        totals: { marker: { color: "#D21F3C"} }
    }
];

// subsidy chart market data

var subsidyMarketChartData = [
  {
      type: "waterfall",
      orientation: "v",
      measure: [
          "relative",
          "relative",
          "total",
      ],
      x: [
          "Upfront Cost",
          "Effective Subsidy",
          "Cost",
      ],
      textposition: "inside",
      text: [
        `$${format(subsidyMarketData[0])}`,
        `$${format(subsidyMarketData[1])}`,
        `$${format(subsidyMarketData[2])}`,
      ], 
      y: [
          subsidyMarketData[0],
          -subsidyMarketData[1],
          subsidyMarketData[2],
      ],
      connector: {
        line: {
          color: "rgb(0,0,0)"
        }
      },
      insidetextfont : { color: ["white", "white", "white"] },
      increasing: { marker: { color: "#18A8D8" }},
      decreasing: { marker: { color: "#8AC7DB" }},
      totals: { marker: { color: "#18A8D8"} }
  }
];


    return (
        <Box sx={{marginTop:'8px', opacity:'1', borderRadius:'10px', background:'black'}}>
        <BusinessCaseTableHeader 
            showChart = {showChart}
            setShowChart = {setShowChart}
            showTable={showTable}
            setShowTable={setShowTable}
            title = {title}
            isChart={true}
        />
        <Box sx={{paddingBottom:'5px'}}>
        {showTable &&
          <TableContainer component={Paper} sx={{ background:'black' }}>
          <Table size='small' sx={{}}>
            <TableHead>
              <TableRow>
                <TableCell width={200} sx={{color:'#0057B8',border:'none', padding:'6px 10px'}}><Typography sx={textStyle}>Per Household</Typography></TableCell>
                <TableCell  width={30} sx={headingCellsStyle}><Typography sx={textStyle}>Market Opportunity</Typography></TableCell>
                <TableCell  width={30} sx={headingCellsStyle}><Typography sx={textStyle}>Build Output</Typography></TableCell>
                <TableCell  width={30} sx={headingCellsStyle}><Typography sx={textStyle}>Difference</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {houseHoldData.map((row:any, index:number) => (
                <TableRow
                  key={"subsidy_"+index}
                >
                  <TableCell sx={{color : 'white',border: "none", padding:'2px 10px'}} ><Typography sx={textStyle}>{row.item}</Typography> </TableCell>
                  <TableCell sx={valueCellsStyle} ><Typography sx={textStyle}>{row.market_opportunity}</Typography></TableCell>
                  <TableCell sx={valueCellsStyle} ><Typography sx={textStyle}>{row.build_output}</Typography></TableCell>
                  <TableCell sx={valueCellsStyle} ><Typography sx={textStyle}>{row.difference}</Typography></TableCell>
                </TableRow>
              ))}

                <TableRow>
                    <TableCell sx={{color : '#0057B8',border: "none", padding:'6px 10px'}} ><Typography sx={textStyle}>Per Market</Typography> </TableCell>
                    <TableCell sx={valueCellsStyle} ></TableCell>
                    <TableCell sx={valueCellsStyle} ></TableCell>
                    <TableCell sx={valueCellsStyle} ></TableCell>
                </TableRow>
                {marketData.map((row:any, index:number) => (
                    <TableRow
                    key={"subsidy2_"+index}
                    >
                      <TableCell sx={{color : 'white',border: "none", padding:'2px 10px'}} ><Typography sx={textStyle}>{row.item}</Typography> </TableCell>
                      <TableCell sx={valueCellsStyle} ><Typography sx={textStyle}>{row.market_opportunity}</Typography></TableCell>
                      <TableCell sx={valueCellsStyle} ><Typography sx={textStyle}>{row.build_output}</Typography></TableCell>
                      <TableCell sx={valueCellsStyle} ><Typography sx={textStyle}>{row.difference}</Typography></TableCell>
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell sx={{color : '#0057B8',border: "none", padding:'6px 10px'}} ><Typography sx={textStyle}>Avg Backhaul Cost Per CBG</Typography></TableCell>
                    <TableCell sx={valueCellsStyle} ><Typography sx={textStyle}>{backhaulCostPerCBG.avg_backhaul_cost_per_cbg}</Typography></TableCell>
                    <TableCell sx={valueCellsStyle} ><Typography sx={textStyle}>{backhaulCostPerCBG.avg_backhaul_cost_per_cbg_clustered}</Typography></TableCell>
                    <TableCell sx={valueCellsStyle} ><Typography sx={textStyle}>{backhaulCostPerCBG.difference}</Typography></TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      }
      {showChart &&
        <Box sx={{padding:'5px', marginLeft:'10px'}}>
            <Box sx={{display:'flex', justifyContent:'space-around'}}>
            <SubsidyChartHome chartData = {subsidyHomeChartData} />
            <SubsidyChartMarket chartData = {subsidyMarketChartData} />
            </Box>
            <Box sx={{display:'flex', justifyContent:'space-around'}}>
            <Box sx={{display:'flex'}}><Box margin={'5px'} width={12} height={12} bgcolor='#D21F3C'></Box> Home</Box>
            <Box sx={{display:'flex'}}><Box margin={'5px'} width={12} height={12} bgcolor='#18A8D8'></Box> Market</Box>
            </Box>
      </Box>
      }
      </Box></Box>
    );
}

export default BusinessSubsidy