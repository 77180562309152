import AddIcon from '@mui/icons-material/AddCircleOutlined';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Backdrop, CircularProgress, Divider, FormControl, FormHelperText, IconButton, InputAdornment, Modal, Paper, Stack, TextField, Tooltip, Typography, Autocomplete } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useForm } from "react-hook-form";
import ConfigMap from '../../components/mapbox/configMap';
import {  ICounty, useConfigure } from '../../context/ConfigureContext';
import { UniqueScenarioName, useScenario } from '../../context/ScenarioContext';
import { useVega } from '../../hooks/useVega';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { toDecimal } from '../../utils/functions';
import { convertPercentage } from './ConfigurationModal';
import CreateScenarioCard from './CreateSenarioCard';
import ScenarioConfiguration, { DEFAULT_IRR_THRESHOLD } from './ScenarioConfiguration';
// Authentication
import { useOktaAuth } from "@okta/okta-react";
import { OnOffSwitch } from '../../components/switch';
import { VegaParams } from '../../@types/VegaParams';

const style = {
    position: 'absolute' as 'absolute',
    top: '20%',
    left: '15%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    border: '2px solid grey',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
    cursor: 'move'
};

const configStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 1400,
    width : '90%',
    height: '92%',
    border: '2px solid grey',
    boxShadow: 24,
    borderRadius: '10px',
    // padding: 6,
    p: 1,
    overflow : 'auto'
}

interface IFormData {
    irr_thresh: string,
    min_thresh: string,
    cost_per_foot: string,
    exclude: string,
    fixed_cost_per_home: string,
    irr: string,
    time_to_terminal_pen: string,
}

interface Assumptions {
    archetype : string,
    value : number,
    total_households:number
}



const UniqueScenarioIcon = (unique_scenario: UniqueScenarioName) => {
    switch(unique_scenario) {
        case UniqueScenarioName.IS_UNQIQUE:
            return <CheckIcon color="success" />
        case UniqueScenarioName.NOT_UNQIQUE:
            return <CloseIcon color="error" />
        default:
            return null;
    }
}

const AddSenario = ({ variant = "button" }: { variant: "button" | "card" }) => {
    const {
        changeSelectedCounty,
        clearConfiguration,
        selectedCounty,
        isConfigEnabled,
        resetState,
        loadingValidation,
        counties,
        defaultConfigs,
    } = useConfigure();
    const { authState } = useOktaAuth();

    const [ isOpen, setIsOpen ] = useState(false);
    const { height, width } = useWindowDimensions();
    const [ name, setName ] = useState('');
    const [ irr_thresh, setIrrThresh ] = useState<any>('0');
    const [ min_thresh, setMinThresh ] = useState<any>('0');
    const [ isFetchingDefault, setIsFetchingDefault ] = useState(false);
    const { register, setValue, getValues, reset,  handleSubmit, watch, formState: { errors, isSubmitSuccessful, isDirty } } = useForm();
    // Set up Map Boundary and centroid and clear on close.
    const [ longitude, setLongitude ] = useState<number>(-80.0285112578555);
    const [ latitude, setLatitude ] = useState<number>(40.4653994974053);
    const [ neBound, setNeBound ] = useState<Record<string, any>>({ lat: 40.65606468059741  , lng:  -79.49472741542539});
    const [ swBound, setSwBound ] = useState<Record<string, any>>({ lat: 40.274191536374076  , lng:  -80.48075036464428});

    // OSM Data Image after useVega call
    const [ blobImg, setBlobImg ] = useState<any>(undefined); // OSM Validation Layer State.
    const [ householdImg, setHouseholdImg ] = useState<any>(undefined); // chloropleth.

    // Vega Query
    const [ vegaQuery, setVegaQuery ] = useState<VegaParams>();
    const [ householdVegaQuery, setHouseholdVegaQuery ] = useState<VegaParams>();

    // For county / municipality dropdown
    const [selectByMunicipality, setSelectByMunicipality] = useState(false);
    const [selectByOptions, setSelectByOptions] = useState<ICounty[]>([])


    const [assumptions, setAssumptions] = useState<Assumptions[]>([])

    const [beadFundEnabled, setBeadFundEnabled] = useState(true)
    const [beadCoverAllHouse, setBeadCoverAllHouse] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const handleBeadFundToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBeadFundEnabled(event.target.checked);

    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectByMunicipality(event.target.checked);

    };
    const handleSearchChange = (value: any) => {
        changeSelectedCounty(value)
    }

    useEffect(()=>{
        if(selectByMunicipality) {
            setSelectByOptions(counties)// TODO: Replace with municipality state
        }else{
            setSelectByOptions(counties)
        }
    }, [counties, selectByMunicipality])


    useEffect(() => {
        if (!selectedCounty) return;
        setLongitude(((selectedCounty?.lon_max || 0 )+ (selectedCounty?.lon_min || 0)) / 2); // longitude,
        setLatitude(((selectedCounty?.lat_max || 0 )+ (selectedCounty?.lat_min || 0)) / 2);// latitude,
        setNeBound({ lat: selectedCounty?.lat_max , lng: selectedCounty?.lon_min });// neBound,
        setSwBound({ lat: selectedCounty?.lat_min , lng: selectedCounty?.lon_max }); //swBound
    }, [selectedCounty]);

    useEffect(() => {
        if (!selectedCounty) return;
        selectedCounty ? setVegaQuery({
            w:width,
            h:height,
            query_type: 'osm_household',
            lng:longitude,
            lat:latitude,
            neBound,
            swBound
    }) : setVegaQuery(undefined);

        selectedCounty ? setHouseholdVegaQuery({
            w:width,
            h:height,
            query_type: 'household',
            lng: longitude,
            lat: latitude,
            neBound,
            swBound
    }) : setHouseholdVegaQuery(undefined);
    }, [
        selectedCounty,
        width,
        height,
        longitude,
        latitude,
        neBound,
        swBound
    ]);

    // Vega calls for each layer.
    const { data, loading: vegaLoading, error } = useVega(vegaQuery);

    const { data: householdData, loading : householdLoading, error: househouldError } = useVega(householdVegaQuery);

    const [archetypeTotalHouseholds, setArchetypeTotalHouseholds] = useState<{archetype: number; total_hhs: number;}[]>([]);


    const {
        setIsValid,
        isValid,
        validNameLoading,
        validScenarioName,
        run_models,
        createScenario,
        setIsScenarioCreated,
        isScenarioCreated,
        loading,
        createdScenario,
        isRunning,
        isSaving,
        updateScenarioConfig,
        getArchetypeTotalHouseholds
    } = useScenario();

    const onSubmit = (data: IFormData | any) => {
        setIsSaved(true);
        if(createdScenario) {
            updateScenarioConfig({
                id: createdScenario,
                county_id: selectedCounty?.id,
                time_to_terminal_penetration: data?.time_to_terminal_penetration,
                cost_per_foot: data?.cost_per_foot,
                fixed_cost_per_home: data?.fixed_cost_per_home,
                irr_thresh: toDecimal(irr_thresh || '0'),
                min_thresh: toDecimal(min_thresh || '0'),
                bead_funding: beadFundEnabled ? data?.bead_funding: 0,
                arpu: data?.arpu,
                take_up_assumptions: {
                    no_services_available:data?.no_services_available > 0 ? data?.no_services_available/100: 0,
                    one_competitor_at_low_speed: data?.one_competitor_at_low_speed > 0 ? data?.one_competitor_at_low_speed/100 : 0,
                    one_competitor_at_high_speed: data?.one_competitor_at_high_speed > 0 ? data?.one_competitor_at_high_speed/100: 0,
                    two_competitors_with_one_at_high_speed: data?.two_competitors_with_one_at_high_speed > 0 ?data?.two_competitors_with_one_at_high_speed/100: 0,
                    two_competitors_at_high_speed: data?.two_competitors_at_high_speed > 0 ? data?.two_competitors_at_high_speed/100: 0,
                    more_than_two_competitors_with_two_at_high_speed: data?.more_than_two_competitors_with_two_at_high_speed > 0 ? data?.more_than_two_competitors_with_two_at_high_speed/100: 0,
                    more_than_two_competitors_at_high_speed: data?.more_than_two_competitors_at_high_speed > 0 ? data?.more_than_two_competitors_at_high_speed/100: 0
                }
            }, false);
        }
    };

    const updateVegaParams = (vegaParams: Record<string, any>) => {
        const query = {
            w:width,
            h:height,
            query_type: 'osm_household',
            lng:vegaParams.lng,
            lat:vegaParams.lat,
            neBound:vegaParams.northEastBounds,
            swBound:vegaParams.southWestBounds,
        };

        const houseQuery = {
            w:width,
            h:height,
            query_type: 'household',
            lng:vegaParams.lng,
            lat:vegaParams.lat,
            neBound:vegaParams.northEastBounds,
            swBound:vegaParams.southWestBounds,
        };

        setVegaQuery(query);
        setHouseholdVegaQuery(houseQuery);

        setLongitude(vegaParams.lng);
        setLatitude(vegaParams.lat);
        setNeBound(vegaParams.northEastBounds);
        setSwBound(vegaParams.southWestBounds);
    };

    useEffect(() => {
        if (data) setBlobImg(data);
    }, [data]);


    useEffect(() => {
        if (householdData) setHouseholdImg(householdData);
    }, [householdData]);

    useEffect(() => {
        if(name && name.length > 3) {
            const delayFn = setTimeout(() => {
                validScenarioName(name);
            }, 1000);

            return () => clearTimeout(delayFn);
        }
    }, [name]);

    useEffect(() => {
        if(createdScenario) {
            getArchetypeTotalHouseholds(createdScenario).then((householdData: {archetype: number; total_hhs: number;}[]) =>{
                setArchetypeTotalHouseholds(householdData);
                let households1 = householdData?.find(h=> h.archetype === 1)?.total_hhs ?? 0
                let households2 = householdData?.find(h=> h.archetype === 2)?.total_hhs ?? 0
                let households3 = householdData?.find(h=> h.archetype === 3)?.total_hhs ?? 0
                let households4 = householdData?.find(h=> h.archetype === 4)?.total_hhs ?? 0
                let households5 = householdData?.find(h=> h.archetype === 5)?.total_hhs ?? 0
                let households6 = householdData?.find(h=> h.archetype === 6)?.total_hhs ?? 0
                let households7 = householdData?.find(h=> h.archetype === 7)?.total_hhs ?? 0
                const assums = [
                    { archetype: "no_services_available", value: (households1 ? defaultConfigs?.take_up_assumptions?.no_services_available : 0) * 100, total_households: households1 },
                    { archetype: "one_competitor_at_low_speed", value: (households2 ? defaultConfigs?.take_up_assumptions?.one_competitor_at_low_speed : 0) * 100, total_households: households2 },
                    { archetype: "one_competitor_at_high_speed", value: (households3 ? defaultConfigs?.take_up_assumptions?.one_competitor_at_high_speed : 0) * 100, total_households: households3 },
                    { archetype: "two_competitors_with_one_at_high_speed", value: (households4 ? defaultConfigs?.take_up_assumptions?.two_competitors_with_one_at_high_speed : 0) * 100, total_households: households4 },
                    { archetype: "two_competitors_at_high_speed", value: (households5 ? defaultConfigs?.take_up_assumptions?.two_competitors_at_high_speed : 0) * 100, total_households: households5 },
                    { archetype: "more_than_two_competitors_with_two_at_high_speed", value: (households6 ? defaultConfigs?.take_up_assumptions?.more_than_two_competitors_with_two_at_high_speed : 0) * 100, total_households: households6 },
                    { archetype: "more_than_two_competitors_at_high_speed", value: (households7 ? defaultConfigs?.take_up_assumptions?.more_than_two_competitors_at_high_speed : 0) * 100, total_households: households7 }
                ];
                setAssumptions(assums);
                assums.forEach((item)=>{
                    setValue(item.archetype, item.value)
                    setValue(item.archetype+"_households", item.total_households)
                });
            });
        }
    }, [createdScenario]);

    const fetchDefaultValues = async() => {
        if (authState?.idToken?.idToken) {
            setValue("time_to_terminal_penetration", defaultConfigs?.cashflow?.time_to_terminal_penetration);
            setValue("cost_per_foot", defaultConfigs?.cashflow?.cost_per_foot);
            setValue("fixed_cost_per_home", defaultConfigs?.cashflow?.fixed_cost_per_home);
            setValue("bead_funding", defaultConfigs?.cashflow?.bead_funding);
            setValue("arpu", defaultConfigs?.cashflow?.arpu);            
            setIrrThresh(convertPercentage(defaultConfigs?.clustering?.irr_thresh, true));
            setMinThresh(convertPercentage(defaultConfigs?.clustering?.min_thresh, true));
        }
    }

    useEffect(() => {
        setIsFetchingDefault(true);
        fetchDefaultValues();
        setIsFetchingDefault(false);
    }, [isOpen]);

    useEffect(() => {
        if(isOpen) {
            setName('');
            setIsValid(UniqueScenarioName.NOT_CHECKED);
            resetState();
            setIsScenarioCreated(false);
            clearConfiguration();
            setBlobImg(undefined);
        }
    }, [isOpen]);

    useEffect(() => {
        setBeadCoverAllHouse(!(parseInt(min_thresh) === DEFAULT_IRR_THRESHOLD));
    }, [min_thresh]);

    return (
        <>
            {
                variant === "button" ?
                (
                    <Tooltip title="Add a new scenario card">
                        <Button
                            onClick={() => setIsOpen(true)}
                            startIcon={<AddIcon />}
                            style={{boxShadow: 'none',color: "gray", backgroundColor: "#E4E6F4", alignSelf: 'center' }}
                            variant="contained">
                            New Scenario
                        </Button>
                    </Tooltip>
                ): (
                    <CreateScenarioCard onClick={() => setIsOpen(true)} />
                )
            }
            {
                isOpen && (
                <>
                    <Modal
                        disableRestoreFocus
                        open={isOpen}
                        onClose={() => {
                            resetState();
                            setIsScenarioCreated(false);
                            setIsOpen(false)
                            setBlobImg(undefined);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                <form onSubmit={handleSubmit(onSubmit)}>
                    {
                        blobImg && (
                            <>
                                <ConfigMap
                                    long={longitude}
                                    latitude={latitude}
                                    sourceName='map1'
                                    onGetLocation={(params) => updateVegaParams(params)}
                                    sourceUrl={blobImg}
                                    householdUrl={householdImg} // Households points
                                    mapZoom={11}
                                    mapType={'dark-v10'}
                                    sourceCoordinates={[
                                        [ swBound.lng, neBound.lat ],
                                        [ neBound.lng, neBound.lat ],
                                        [ neBound.lng, swBound.lat ],
                                        [ swBound.lng, swBound.lat ],
                                    ]}
                                />
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={vegaLoading || householdLoading}
                                >
                                    <CircularProgress />
                                </Backdrop>
                        </>
                        )
                    }
                    {
                        !isScenarioCreated ? (
                            <Draggable>
                                <Paper sx={style}>
                                    <Typography fontSize="large" textAlign="center"  color="text.secondary">Add a new scenario</Typography>
                                    <Divider sx={{ marginTop: '2%', marginBottom: '2%'}} />
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            resetState();
                                            setIsScenarioCreated(false);
                                            clearConfiguration();
                                            setIsOpen(false);
                                            setBlobImg(undefined);
                                        }}
                                        style={{
                                            background: 'white',
                                            position: 'absolute',
                                            top: 0,
                                            right: 0
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <Box
                                        style={{
                                            paddingLeft: '3%',
                                            paddingRight: '3%',
                                            paddingTop: '3%',
                                            paddingBottom: '2%'
                                        }}
                                    >
                                        <Stack spacing={3}>
                                            <FormControl>
                                                <TextField
                                                    {...register("scenario_name", { required: true, minLength: 3, maxLength: 100 })}
                                                    required={true}
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    size="small"
                                                    id="outlined-basic"
                                                    label="Please type your scenario name"
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment:<InputAdornment position="end">
                                                            {validNameLoading && (
                                                                <CircularProgress size={20} />
                                                            )}
                                                            {UniqueScenarioIcon(isValid)}
                                                        </InputAdornment>
                                                    }}
                                                />
                                                <FormHelperText error>
                                                    {isValid === UniqueScenarioName.NOT_UNQIQUE && "Scenario name already exists. Please type in a unique name"}
                                                </FormHelperText>
                                            </FormControl>
                                            {/* <Stack
                                                direction="row"
                                                display="flex"
                                                spacing={2}
                                            >
                                                <Typography textAlign="center" color="text.secondary">Select by : </Typography>

                                               <ToggleSwitch 
                                                   checked={selectByMunicipality}
                                                   handleChange={(e)=>handleChange(e)}
                                               />

                                            </Stack> TODO: Added back when municipality got added*/}
                                            <Autocomplete
                                                disablePortal
                                                id="county"
                                                options={selectByOptions}
                                                getOptionLabel={(option) => option.county_name}
                                                renderInput={(params) => <TextField {...params} label={selectByMunicipality ? "Please select a municipality" : "Please select a county"} InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment:loadingValidation ? (
                                                            <InputAdornment position="end">
                                                                <CircularProgress sx={{position:'absolute', right:'10px'}} size={20} />
                                                            </InputAdornment>
                                                        ): null,
                                                  }}/>}
                                                onChange={(e, value) => handleSearchChange( value)}
                                                value={selectedCounty}
                                                size='small'
                                            />
                                        </Stack>
                                        <Box
                                            sx={{
                                                marginTop: '5%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%'
                                            }}
                                        >
                                            {
                                                isScenarioCreated ? (
                                                    <Button
                                                        startIcon={<ArrowForwardRoundedIcon />}
                                                        variant="contained">Configure Screen</Button>
                                                ): (
                                                    <>
                                                        <Button
                                                            onClick={async () => {
                                                                createScenario({
                                                                    scenario_name: name,
                                                                    county_id: selectedCounty?.id || ''
                                                                })
                                                            }}
                                                            disabled={loading || validNameLoading || !isConfigEnabled || (isValid === UniqueScenarioName.NOT_UNQIQUE) }
                                                            startIcon={<AddIcon />}
                                                            variant="contained">
                                                            { loading ? "Creating Scenario..." : "Create Scenario" }
                                                        </Button>
                                                    </>
                                                )
                                            }
                                        </Box>
                                    </Box>
                                </Paper>
                            </Draggable>
                        ): (
                            <>
                                <Paper sx={configStyle}>
                                    <Box width="100%" sx={{ textAlign:'right', paddingBottom:'5px'}}>
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            resetState();
                                            setIsScenarioCreated(false);
                                            clearConfiguration();
                                            setIsOpen(false);
                                            setBlobImg(undefined);
                                        }}
                                        sx={{
                                            background: 'white',
                                            alignSelf:'right'
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    </Box>
                                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>

                                    <Typography paddingLeft={3} fontWeight="bold"  fontSize="large" textAlign="center" mb={2} color="text.primary">Set inputs for new scenario: {name}</Typography>
                                    <Stack direction="row" spacing={1} paddingRight={4} >
                                        <Typography>BEAD funding</Typography>
                                        <OnOffSwitch
                                            checked = {beadFundEnabled}
                                            handleChange={handleBeadFundToggle}
                                        />
                                    </Stack>

                                    </Box>

                                    <Divider />
                                    <ScenarioConfiguration
                                        min_thresh={min_thresh}
                                        setMinThresh={setMinThresh}
                                        irr_thresh={irr_thresh}
                                        setIrrThresh={setIrrThresh}
                                        errors={errors}
                                        watch={watch}
                                        register={register}
                                        getValues={getValues}
                                        beadFundEnabled={beadFundEnabled}
                                        beadCoverAllHouse={beadCoverAllHouse}
                                        setBeadCoverAllHouse={setBeadCoverAllHouse}
                                        isTotalsLoading={archetypeTotalHouseholds?.length <= 0}
                                        sectionFrom='add_conf'
                                        />
                                    <Stack
                                        direction="row"
                                        display="flex"
                                        justifyContent="flex-end"
                                        spacing={2}
                                        paddingRight={4}
                                        paddingBottom={2}
                                        gap={"52px"}
                                    >
                                        <Button
                                            type="submit"
                                            sx={{
                                                minWidth: "15%"
                                            }}
                                            variant="contained"
                                            disabled={(createdScenario === null) || !isDirty || loading}
                                        >
                                            {"Save"}
                                            {
                                                isSaving && <CircularProgress sx={{ color: "white" }} size={15} />
                                            }
                                        </Button>
                                        <Button 
                                            disabled={(createdScenario === null) || !isSubmitSuccessful || loading || !isSaved }
                                            onClick={async () => {
                                                if(createdScenario) {
                                                    setIsSaved(false);
                                                    setIsOpen(false);
                                                    resetState();
                                                    setIsScenarioCreated(false);
                                                    clearConfiguration();
                                                    reset();
                                                    run_models(createdScenario, selectedCounty?.id as string, beadFundEnabled)
                                                    setBlobImg(undefined);
                                                }
                                            }}
                                            sx={{
                                                minWidth: "15%"
                                            }}
                                            variant="contained"
                                        >
                                            Run
                                            {
                                                isRunning && <CircularProgress sx={{ color: "white" }} size={15} />
                                            }
                                        </Button>
                                    </Stack>
                                </Paper>
                            </>
                        )
                    }
                        </form>
                    </Modal>
                </>
                )
            }
        </>
    )
}

export default AddSenario;

