import React, { createContext, useContext, useEffect, useState } from 'react';

const DashboardContext = createContext<IDashboardState | undefined>(undefined);

interface IDashboardProvder {
    children: JSX.Element
}

interface IModel {
    name: string,
    county: string
}
interface IDashboardState {
    models: IModel[],
    addModel: (model: IModel) => void
}


const DashboardProvider = ({ children }: IDashboardProvder) => {
    const [ models, setModel ] = useState<IModel[]>([]);

    const addModel = (model: IModel) => {
        setModel([...models, model])
    }
    return (
        <DashboardContext.Provider
            value={{
                models,
                addModel
            }}
        >
            {children}
        </DashboardContext.Provider>
    )
}
const useDashboard = () => {
    const context = useContext(DashboardContext);
    if(context === undefined) {
        throw new Error("useDashboard can only used inside DashboardProvider")
    }

    return context;
}

export {
    DashboardProvider,
    useDashboard
};
