import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Modal, Paper, TextField, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import React, { useState } from 'react';
import { useScenario } from '../../context/ScenarioContext';

const style = {
    position: 'absolute' as 'absolute',
    top: '30%',
    left: '30%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    border: '2px solid grey',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4
};

const ModelInputButton = ({ id, name }: { id: string, name: string }) => {
    const [ isRenameModelOpen, setIsRenameModelOpen ] = useState<boolean>(false);
    const [ newName, setNewName ] = useState(name);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const { archiveScenario, rename }  = useScenario();
    
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
  
    return (
    <div>
       <IconButton onClick={(e) => handleClick(e)} size="small" aria-label="settings">
            <MoreVertIcon htmlColor="#019BD9" fontSize="small" />
        </IconButton>
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
      >
        <List style={{ margin: 0, padding: 0 }} >
            <ListItem
                key='rename'
                style={{ margin: 0 }} 
                disablePadding
            >
                <ListItemButton onClick={() => setIsRenameModelOpen(true)} style={{ margin: 0, padding: 5, paddingTop: 0, paddingRight: 10 }} role={undefined} dense>
                    <ListItemAvatar style={{ margin: 0, color: "black" }} >
                        <IconButton style={{ margin: 0, color: "black" }} edge="end" aria-label="comments">
                            <EditIcon color="inherit" fontSize="small" />
                        </IconButton>
                    </ListItemAvatar>
                    <ListItemText style={{ color: 'grey'}} primary={`Rename Scenario`} />
                </ListItemButton>
                
            </ListItem>
            <ListItem
                key='remove'
                sx={{ margin: 0 }} 
                onClick={() => archiveScenario(id)}
                disablePadding
            >
                <ListItemButton style={{ margin: 0, padding: 5, paddingTop: 0, paddingRight: 10 }} role={undefined} dense>
                    <ListItemAvatar style={{ margin: 0, color: "black" }} >
                        <IconButton style={{ margin: 0, color: "black" }} edge="end" aria-label="comments">
                            <DeleteIcon color="inherit" fontSize="small" />
                        </IconButton>
                    </ListItemAvatar>
                    <ListItemText style={{ color: 'grey'}} primary={`Remove Scenario`} />
                </ListItemButton>
                
            </ListItem>  
        </List>
      </Popover>
      {
        isRenameModelOpen && (
            <Modal 
                open={isRenameModelOpen}
                onClose={() => {
                    setIsRenameModelOpen(false);
                    handleClose();
                }}    
            >
                <>
                   
                    <Paper sx={style}>
                        <IconButton 
                            onClick={() => {
                                setIsRenameModelOpen(false);
                                handleClose();
                            }} 
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ marginBottom: 1 }}  fontSize="large" textAlign="center"  color="text.secondary">Rename Scenario</Typography>
                        <Divider  />
                        <Box
                            sx={{
                                marginTop: '5%',
                                marginBottom: '5%'
                            }}
                        >
                            <TextField
                                value={newName} 
                                onChange={(e) => setNewName(e.target.value)}
                                required
                                fullWidth
                                size="small"
                                variant="outlined"
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            
                        >
                            <Button
                                onClick={() => {
                                    rename(id, newName);
                                    setIsRenameModelOpen(false);
                                    handleClose();
                                }}
                                
                                disabled={(newName === name)}
                                type="submit"
                                sx={{
                                    minWidth: "20%"
                                }}
                                variant="contained"
                            >
                                Save
                            </Button>
                        </Box>
                    </Paper>
                </>
            </Modal>
        )
      }
    </div>
  );
}

export default ModelInputButton