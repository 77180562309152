import { Box } from '@mui/material';
import React from 'react';


const Dashboard = ({ children }: { children: React.ReactNode}) => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            sx={{
                backgroundColor: "#F8F9FD"
            }}
        >
            <Box
                display="flex"
                height="auto"
                width="3.5vw"
                justifyContent="center"
                sx={{
                    background: "transparent linear-gradient(241deg, #029FDB 0%, #0057B8 100%) 0% 0% no-repeat padding-box"
                }}
            >   
            </Box>
            <Box
                display="flex"
                width="100%"
                height="100%"
                flexDirection="column"
            >
                {children}
            </Box>
        </Box>
    )
}



export default Dashboard;