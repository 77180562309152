
import {
    InfoOutlined
} from '@mui/icons-material';
import { FormControl, FormLabel, InputAdornment, Slider as MuiSlider, Stack, TextField, Tooltip, Typography, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { OnOffSwitch } from '../../components/switch';
import ScenarioAssumptions from './ScenarioAssumptions';
import { useEffect, useState } from 'react';
import axios from 'axios';
import BASE_API_URL from '../../utils/baseUrl';
import { useOktaAuth } from "@okta/okta-react";
import { DataGrid } from '@mui/x-data-grid';

const inputBoxStyle = {
    width: '100%',
    padding: '2% 2% 1% 2%'
};

const clusteringBoxStyle = {
    minWidth: '400px',
    padding: '2% 2% 1% 2%'
};

const infoIconStyle = {
    color: 'primary.light',
    fontSize: '0.95rem'
};

const GLOBAL_INPUTS = [
    {
        id: "time_to_terminal_penetration",
        name: "Time to terminal penetration",
        unit: "Yrs",
        position: "end",
        min: 0,
        max: 100000000,
        tooltip: "Period in years before final terminal penetration rate is reached"
    },
    {
        id: "cost_per_foot",
        name: "Cost per foot",
        unit: "$",
        position: "start",
        min: 0,
        max: 100000000,
        tooltip: "Cost to lay a foot of fiber"
    },
    {
        id: "fixed_cost_per_home",
        name: "Fixed cost per house",
        unit: "$",
        position: "start",
        min: 0,
        max: 100000000,
        tooltip: "Average fixed cost per household accounting for cost of cabinets, hubs, switches and splicing"
    },
    {
        id: "bead_funding",
        name: "BEAD funding request per house",
        unit: "$",
        position: "start",
        min: 0,
        max: 100000000,
        tooltip: "BEAD funding request per house"
    },
    {
        id: "arpu",
        name: "Average Revenue Per User (ARPU)",
        unit: "$",
        position: "start",
        min: 0,
        max: 100000000,
        tooltip: "Average Revenue Per User (ARPU)"
    }
];

const MIN_IRR_THRESHOLD = -10;
export const DEFAULT_IRR_THRESHOLD = -100;

interface IColumnHeader {
    field: string, 
    headerName: string,
    width: number,
    sortable?:boolean,
    renderCell?: (params:any) => void,
    cellClassName? : string
}

const ScenarioConfiguration = ({
    min_thresh,
    setMinThresh,
    irr_thresh,
    setIrrThresh,
    register,
    errors,
    getValues,
    watch,
    beadFundEnabled,
    setBeadCoverAllHouse,
    beadCoverAllHouse,
    isTotalsLoading,
    scenarioId,
    sectionFrom
}: any) => {
    const { authState } = useOktaAuth();

    const [cbgDataList, setCbgDataList] = useState<any[]>([])

    const aggregatedColumns: IColumnHeader[] = [
        {
            field: 'id',
            headerName: 'GeoId',
            width: 200,
        }
    ]

    const handleCoverAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        !event.target.checked ? setMinThresh(DEFAULT_IRR_THRESHOLD): setMinThresh(0);
        setBeadCoverAllHouse(event.target.checked);
    }

    const config = {
        headers: {
            Authorization: 'Bearer ' + authState?.idToken?.idToken,
            "Content-Type": "application/json"
        }
    };

    useEffect(() => {
        if(sectionFrom==='dashboard'){
            axios.get(`${BASE_API_URL}clustering/must_build_areas?scenario_id=${scenarioId}`, config)
            .then(response => {
                setCbgDataList([...response.data].filter(Boolean));
            })
        }
    },[])

    return (
        <>
            <Stack
                direction='row'
                spacing={2}
                mb={2}
                mt={1}
                sx={{
                    maxHeight: '900px'
                }}
            >
                <Box sx={{...inputBoxStyle}}>
                    <>
                        <Typography mb={2} fontWeight={800} >
                            GLOBAL INPUTS
                            <Tooltip title='Provide global configuration needed to run the cash flow model'>
                                <InfoOutlined
                                    fontSize="small"
                                    sx={infoIconStyle}
                                />
                            </Tooltip>
                        </Typography>
                        <Stack direction='row' spacing={1} justifyContent="space-between" paddingBottom="15px">
                            <Box sx={{ width: '230px' }}><Typography fontWeight='bold'>Input Name</Typography></Box>
                            <Box sx={{ width: '115px' }}><Typography fontWeight='bold'>Value</Typography></Box>
                        </Stack>
                        {
                            GLOBAL_INPUTS.map((item, i) => (
                                <Stack
                                    direction='row'
                                    spacing={1}
                                    key={i}
                                    p='.2em 0 .2em 0'
                                    justifyContent="space-between"
                                    sx={{ background: (i % 2 == 0) ? '#F8F8F8' : '#F0F0F0'}}
                                >
                                    <Box sx={{ width: '230px' }}>
                                        <Tooltip title={item?.tooltip ? item?.tooltip : ''}>
                                            <FormLabel sx={{ paddingLeft:'5px', fontSize:'14px', color:(! beadFundEnabled && item.id==='bead_funding') ? "gray" : "#029FDB", alignSelf: "center", cursor: "pointer"}}>
                                                {item.name}
                                            </FormLabel>
                                        </Tooltip>
                                        </Box>
                                        <Box sx={{ width: '122px' }}>
                                            <FormControl error={errors[item.id]}>
                                                <TextField
                                                    disabled = {(! beadFundEnabled && item.id==='bead_funding') ? true : false}
                                                    type="number"
                                                    size="small"
                                                    sx={{ input: { color: "#029FDB", fontSize:'14px' }, width: "115px", background:'white' }}
                                                    {...register(item.id, { required: true })}
                                                    InputProps={{
                                                        inputProps: {
                                                            max: item.max, min: item.min,
                                                            step: item.unit === 'Yrs' ? 1 : 0.01
                                                        },
                                                        ...(item.position === "end" ? {
                                                            endAdornment: <InputAdornment position={item.position as "start" | "end"}><Box component="span" sx={{ color: "#029FDB" }}>{item.unit}</Box></InputAdornment>
                                                        } : {
                                                            startAdornment: <InputAdornment position={item.position as "start" | "end"}><Box component="span" sx={{ color:(! beadFundEnabled && item.id==='bead_funding') ? "gray" : "#029FDB" }}>{item.unit}</Box></InputAdornment>
                                                        } )
                                                    }}
                                                />
                                            </FormControl>
                                        </Box>
                                </Stack>
                            ))
                        }
                    </>
                    <Box sx={{marginTop:'20px'}}>
                    {cbgDataList.length > 0 &&
                        <Box >
                            <Typography mb={2} fontWeight={800} >
                                Must Build Areas
                                <Tooltip title='Must Build Areas'>
                                    <InfoOutlined
                                        fontSize="small"
                                        sx={infoIconStyle}
                                    />
                                </Tooltip>
                            </Typography>
                            <Box>
                                <DataGrid
                                    sx={{
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                    },
                                    '.MuiDataGrid-columnHeaders': {
                                        border:'none',
                                        fontWeight:'bold',
                                        borderBottom:'1px solid gray',
                                        lineHeight:'30px'
                                    },
                                    '.MuiDataGrid-columnHeaderTitle': {
                                        fontWeight : '900'
                                    },
                                    '.MuiDataGrid-cell' : {
                                        border:'none',
                                        height:'10px',
                                    },
                                    '& .middleCell': {
                                        borderRight: '1px solid gray',
                                        borderLeft:'1px solid gray',
                                    },
                                    '.MuiDataGrid-footerContainer' : {
                                        border:'none',
                                        fontSize:'10px',
                                    },
                                    '&.MuiDataGrid-root': {
                                        border: 'none',
                                        height:'100%',
                                        background:'#D3D3D3'
                                    }
                                    }}
                                    autoHeight
                                    rowHeight={30}
                                    loading={false}
                                    rows={cbgDataList ?? []}
                                    //@ts-ignore
                                    columns={aggregatedColumns}
                                    pageSize={10}
                                    rowsPerPageOptions={[5]}
                                    hideFooterSelectedRowCount={true}
                                    getRowId={(row: any) => row.id + Math.random().toString(36).slice(2, 7)}
                                />

                            </Box>
                        </Box>
                    }
                    </Box>
                </Box>
                <Box sx={{...clusteringBoxStyle}}>
                    <>
                        <Typography mb={2} fontWeight={800}  paddingBottom="45px">
                            CLUSTERING PARAMETERS
                            <Tooltip title='Define a cumulative expected IRR and minimum IRR threshold for areas to be included in final build cluster'>
                                <InfoOutlined
                                    fontSize="small"
                                    sx={infoIconStyle}
                                />
                            </Tooltip>
                        </Typography>
                        <Stack direction='column' justifyContent='space-between' mb={1}>
                            <>
                                <Typography
                                    variant='body2' component="div"
                                >
                                    Solve for build areas with cumulative IRR at or above
                                    <Box sx={{ fontWeight: 700, m:1 }} display='inline'>
                                        {irr_thresh}%
                                    </Box>
                                </Typography>
                                <MuiSlider
                                    {...register("irr_thresh")}
                                    defaultValue={parseInt(irr_thresh)}
                                    min={parseInt(min_thresh) + 1}
                                    value={parseInt(irr_thresh)}
                                    onChange={(e: any) => setIrrThresh(e.target.value)}
                                />
                            </>
                            <>
                                <Typography
                                    variant='body2'  paddingTop="20px" component="div"
                                >
                                    Exclude zones less than
                                    <Box sx={{ fontWeight: 700, m:1 }} display='inline'>
                                        {!beadCoverAllHouse ? '': `${min_thresh}%`}
                                    </Box>
                                </Typography>
                                <MuiSlider
                                    disabled={!beadCoverAllHouse}
                                    {...register("min_thresh")}
                                    max={irr_thresh - 1}
                                    min={MIN_IRR_THRESHOLD}
                                    value={parseInt(min_thresh)}
                                    onChange={(e: any) => !beadCoverAllHouse? setMinThresh(DEFAULT_IRR_THRESHOLD): setMinThresh(e.target.value)}
                                />
                            </>
                            <>
                            <Stack direction='row' justifyContent='space-between' paddingTop="20px" mt={2}>
                                <Typography
                                    variant='body2'
                                    sx={{marginTop:'4px'}}
                                >
                                    Apply a minimum IRR threshold to clustering
                                </Typography>
                                <OnOffSwitch checked={beadCoverAllHouse} handleChange={handleCoverAll} />
                            </Stack>
                            </>
                        </Stack>
                    </>
                </Box>

                <Box sx={{...inputBoxStyle}}>
                    <>
                        <Typography mb={2} fontWeight={800}>
                            TAKE UP ASSUMPTIONS
                            <Tooltip title='Take up assumptions sums up to the terminal penetration.\nTerminal penetration is the expected % of customers that will take up fibre in the long run.'>
                                <InfoOutlined
                                    fontSize="small"
                                    sx={infoIconStyle}
                                />
                            </Tooltip>
                        </Typography>
                        {isTotalsLoading ? <CircularProgress/> :
                        <ScenarioAssumptions
                            register={register}
                            errors={errors}
                            getValues={getValues}
                            watch={watch}
                        />}
                    </>
                </Box>
            </Stack>
        </>
    )
}

export default ScenarioConfiguration;