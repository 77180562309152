import React from 'react';
import { FormControl, FormLabel, Stack, TextField, Tooltip, Typography,InputAdornment } from '@mui/material';
import { Box } from '@mui/system';

const GLOBAL_INPUTS = [
    {
        id: "no_services_available",
        name: "No services available",
        min: 0,
        max: 100000000,
        tooltip: "No services available",
        unit: "%",
        position: "end",
    },
    {
        id: "one_competitor_at_low_speed",
        name: "One competitor at low speed (< 1Gbps)",
        min: 0,
        max: 100,
        tooltip: "One competitor at low speed (< 1Gbps)",
        unit: "%",
        position: "end",
    },
    {
        id: "one_competitor_at_high_speed",
        name: "One competitor at high speed (>= 1Gbps)",
        min: 0,
        max: 100,
        tooltip: "One competitor at high speed",
        unit: "%",
        position: "end",
    },
    {
        id: "two_competitors_with_one_at_high_speed",
        name: "Two competitors with at least one at high speed (>= 1Gbps)",
        min: 0,
        max: 100000000,
        tooltip: "Two competitors with at least one at high speed (>= 1Gbps)",
        unit: "%",
        position: "end",
    },
    {
        id: "two_competitors_at_high_speed",
        name: "Two competitors at high speed (>= 1Gbps)",
        min: 0,
        max: 100000000,
        tooltip: "Two competitors at high speed (>= 1Gbps)",
        unit: "%",
        position: "end",
    },
    {
        id: "more_than_two_competitors_with_two_at_high_speed",
        name: "More than two competitors with at most two at high speed (>= 1Gbps)",
        min: 0,
        max: 100000000,
        tooltip: "More than two competitors with at most two at high speed (>= 1Gbps)",
        unit: "%",
        position: "end",
    },
    {
        id: "more_than_two_competitors_at_high_speed",
        name: "More than two competitors at high speed (>= 1Gbps)",
        min: 0,
        max: 100000000,
        tooltip: "More than two competitors at high speed (>= 1Gbps)",
        unit: "%",
        position: "end",
    }
];



const ScenarioAssumptions = ({register,errors,getValues, watch}: any) => {

  const[weightedTotal, setWeightedTotal] = React.useState<number>()
  const[totalHouseholds, setHouseHolds] = React.useState<number>()

    
  React.useEffect(() => {
    
    const subscription = watch(() =>{
        let overallTotal = GLOBAL_INPUTS.reduce((a,b) => a + getValues(b.id+"_households"), 0)
        let total = 0
        GLOBAL_INPUTS.map((item, i) => {
            total = total + (getValues(item.id) * getValues(item.id+"_households"))
        })
        setWeightedTotal(total/overallTotal)
        setHouseHolds(overallTotal)
    })
    
    return () => subscription.unsubscribe()
  }, [watch])

  React.useEffect(() => { 
        let overallTotal = GLOBAL_INPUTS.reduce((a,b) => a + getValues(b.id+"_households"), 0)   
        let total = 0
        GLOBAL_INPUTS.map((item, i) => {
            total = total + (getValues(item.id) * getValues(item.id+"_households"))
        })
        setWeightedTotal(total/overallTotal)
        setHouseHolds(overallTotal)
   
  },[])


    return (
        <>
        <Stack direction='row' spacing={1} paddingBottom="15px" justifyContent="space-between">
            <Box sx={{ width: '300px' }}><Typography fontWeight='bold'>Archetype</Typography></Box>
            <Box sx={{ width: '115px' }}><Typography fontWeight='bold'>Take up</Typography></Box>
            <Box sx={{ width: '100px' }}><Typography fontWeight='bold'>Households</Typography></Box>       
        
        </Stack>                
        {
            GLOBAL_INPUTS.map((item, i) => (
                <Stack
                    direction='row'
                    spacing={1}
                    key={i}
                    p='.2em 0 .2em 0'
                    justifyContent="space-between"
                    sx={{ background: (i % 2 == 0) ? '#F8F8F8' : '#F0F0F0'}}
                >
                    <Box sx={{ width: '300px', padding:'2px' }}>
                        <Tooltip title={item?.tooltip ? item?.tooltip : ''}>
                            <FormLabel sx={{ paddingLeft:'5px', fontSize:'14px', color: "#029FDB", alignSelf: "center", cursor: "pointer"}}>
                                {item.name}
                            </FormLabel>
                        </Tooltip>
                    </Box>
                    <Box sx={{ width: '115px' }}>
                        <FormControl error={errors[item.id]}>
                            <TextField                                
                                type="number"
                                size="small" 
                                disabled={getValues(item.id+"_households") ? false : true}
                                sx={{ input: { color: "#029FDB", fontSize:'14px',  background:'white' }, width: "115px" }}
                                {...register(item.id, { required: true })}
                                InputProps={{
                                    inputProps: {
                                        max: item.max, min: item.min,
                                        step: 0.01
                                    },
                                    ...(item.position === "end" ? {
                                        endAdornment: <InputAdornment position={item.position as "start" | "end"}><Box component="span" sx={{ color: getValues(item.id+"_households") ? "#029FDB" : "gray" }}>{item.unit}</Box></InputAdornment>
                                    } : {
                                        startAdornment: <InputAdornment position={item.position as "start" | "end"}><Box component="span" sx={{ color: getValues(item.id+"_households") ? "#029FDB" : "gray" }}>{item.unit}</Box></InputAdornment>
                                    } )
                                }}  
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ width: '100px' }}>
                        <Typography textAlign="center" sx={{fontSize:'14px', paddingTop:'8px'}}>{getValues(item.id+"_households")}</Typography>
                    </Box>
                </Stack>
            ))
        } 
        <Stack direction='row' sx={{background:'#6b7493' , color:'white', padding:'4px'}} justifyContent="space-between" spacing={1}>
            <Box sx={{ width: '300px' }}><Typography fontWeight='bold' fontSize='14px' >Weighted average expected terminal penetration</Typography></Box>
            <Box sx={{ width: '115px' }}><Typography fontWeight='bold' fontSize='14px' paddingTop='8px' paddingLeft="14px" >{weightedTotal?.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}%</Typography></Box>     
            <Box sx={{ width: '100px' }}><Typography textAlign="center" fontWeight='bold' fontSize='14px' paddingTop='8px' paddingLeft="8px" >{totalHouseholds}</Typography></Box>     
        
        </Stack>  
        </>
               
    )
}

export default ScenarioAssumptions;