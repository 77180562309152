import { Box, Typography } from '@mui/material';
import React from 'react';
import Options from './Options';

const DashboardHeader = () => {

    return (
        <>
            <Box
                display="flex"
                width="100%"
                height="100%"
                justifyItems="column"
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    width="100%"
                    maxHeight="10vh"
                    justifyContent="space-between"
                    paddingTop="1%"
                    paddingBottom="1%"
                    paddingLeft="3%"
                    paddingRight="3%"
                    alignItems="center"
                >
                    <Box>
                        <Typography
                            color="#029FDB"  
                            fontSize="25px"
                            fontWeight="400"
                        >Fiber Planning Application</Typography>
                    </Box>
                    {/* <Box>
                        <Search />
                    </Box> */}
                    <Box>
                        <Options />

                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default DashboardHeader;