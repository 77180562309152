/* eslint-disable no-empty-pattern */
import Box from '@mui/material/Box';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useRef, useState } from 'react';
import { useConfigure } from '../../context/ConfigureContext';

const mapContainerStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
};

// const sidebarStyle = {
//     display: 'inline-block',
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     margin: '12px',
//     backgroundColor: '#404040',
//     color: '#ffffff',
//     zIndex: '1 !important',
//     padding: '6px',
//     fontWeight: 'bold',
// };
interface ConfigMapProps {
    long?: number;
    latitude?: number;
    mapZoom?: number;
    mapWidth?: number;
    mapHeight?: number;
    sourceName? : string;
    sourceUrl?: string;
    householdUrl?: string;
    sourceCoordinates?: any[];
    mapType?: string;
    streetMapView?: boolean;
    onGetLocation: (params: Record<string,any>) => void;
    onClick?: () => void;
};

const ConfigMap = ({
    long = -80.0285112578555,
    latitude = 40.4653994974053,
    mapZoom = 9,
    mapWidth = 100,
    mapHeight = 100,
    sourceName,
    sourceUrl,
    householdUrl,
    sourceCoordinates,
    mapType,
    streetMapView,
    onGetLocation,
    onClick,
    ...props
}: ConfigMapProps) => {
    const mapContainer : any = useRef(null);
    const map : any = useRef(null);
    // Map Coordinates
    const [lng, setLng] = useState(long);
    const [lat, setLat] = useState(latitude);
    const [zoom, setZoom] = useState(mapZoom);
    // Boundary of current map view.
    const [northEastBounds, setNorthEastBounds] = useState<Record<string,any> >({ lat: 35.20648604440575 , lng: -106.47193617478133 });
    const [southWestBounds, setSouthWestBounds] = useState<Record<string,any>>({ lat: 34.99478701099892 , lng: -106.81237417453555 });
    const { configEnabled, setLoadingValidation } = useConfigure();

    const getMapPosition = async () => {
        // Move
        setLng(await map.current.getCenter().lng.toFixed(4));
        setLat( await map.current.getCenter().lat.toFixed(4));
        setZoom( await map.current.getZoom().toFixed(2));
        // Zoom
        setNorthEastBounds( await map.current.getBounds()._ne);
        setSouthWestBounds( await map.current.getBounds()._sw);
        // Emit to parent
        if (northEastBounds && southWestBounds) onGetLocation({lng, lat, zoom, northEastBounds, southWestBounds});
    };

    const changeImage = (url: string, mapSrc: string) => {
        if (!map.current) return;
        if (map.current.isSourceLoaded(mapSrc)) {
            if (northEastBounds && southWestBounds) {
                const imgSource = map.current.getSource(mapSrc);
                imgSource.updateImage({
                    url: url,
                    coordinates: [
                        [southWestBounds.lng, northEastBounds.lat],
                        [northEastBounds.lng, northEastBounds.lat],
                        [northEastBounds.lng, southWestBounds.lat],
                        [southWestBounds.lng, southWestBounds.lat]
                    ]
                });
            };
        };
    };

    const removeImage = (mapLayer: string, mapSrc: string) => {
        if (!map.current) return;
        map.current.removeLayer(mapLayer);
        map.current.removeSource(mapSrc);
    };

    const addMapSource = (
        srcName: string,
        layerName: string,
        blobImgUrl: string
    ) => {
        map.current.on('load', () => {
            if (map.current.getSource(srcName) !== undefined) {
                removeImage(layerName, srcName);
            };

            map.current.addSource(srcName, {
                'type': 'image',
                'url': blobImgUrl,
                'coordinates': sourceCoordinates
            });

            map.current.addLayer({
                'id': layerName,
                'source': srcName,
                'type': 'raster',
                'paint': {
                    'raster-fade-duration': 0
                }
            });
        });

        if (map.current.getSource(srcName) === undefined) {
            map.current.addSource(srcName, {
                'type': 'image',
                'url': blobImgUrl,
                'coordinates': sourceCoordinates
            });

            map.current.addLayer({
                'id': layerName,
                'source': srcName,
                'type': 'raster',
                'paint': {
                    'raster-fade-duration': 0
                }
            });
        };
    };

    useEffect(() => {
        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/dark-v10',
            center: [lng, lat],
            zoom: zoom
        });
    }, []);

    useEffect(() => {
        if (!map.current || !mapType) return;
            map.current.setStyle('mapbox://styles/mapbox/' + mapType);

            map.current.on('style.load', async () => {
                if (sourceUrl) await addMapSource('map1', 'map1-layer', sourceUrl);
                if (householdUrl) await addMapSource('householdSrc', 'household-layer', householdUrl);
            });
    }, [mapType])

    useEffect(() => {
        if (!map.current) return;

        map.current.on('move', () => {
            getMapPosition();
        });

        map.current.on('zoom', () => {
            getMapPosition();
        });
    });

    useEffect(() => {
        if (!map.current) return;
        if (!sourceUrl) return;

        map.current.on('load', () => {
            addMapSource('map1', 'map1-layer', sourceUrl);
        });
        configEnabled();
        setLoadingValidation(false);
    }, []);

    useEffect(() => {
        if (!sourceUrl) return;
        if (map.current.getSource('map1') !== undefined) changeImage(sourceUrl, 'map1');
    }, [sourceUrl]); // Never add changeImage function to the dependency array.

    useEffect(() => {
        if (!householdUrl) return;
        if (map.current.getSource('householdSrc') !== undefined) changeImage(householdUrl, 'householdSrc');
    }, [householdUrl]); // Never add changeImage function to the dependency array.

    useEffect(() => {
        if (!sourceUrl) return;
        if (!map.current.isStyleLoaded()) return;

        addMapSource('map1', 'map1-layer', sourceUrl);
        addMapSource('householdSrc', 'household-layer', householdUrl as string);
    }, [streetMapView]);

    return (
        <>
            {/* <Box sx={sidebarStyle}>
                Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
            </Box> */}
            <Box
                ref={mapContainer}
                sx={
                    {
                        ...mapContainerStyle,
                        height: `${mapHeight}%`,
                        width: `${mapWidth}%`,
                    }
                }
            />
        </>
    );
};

export default ConfigMap;
