import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    IconButton, Modal, Paper
} from "@mui/material";
import { useState } from "react";
import DetailsModal from "./DetailsModal";
import { IScenarios } from '../../context/ScenarioContext';

const style = {
    position: 'fixed',
    width: "100vw",
    height: "100vh",
    borderRadius: '2px',
    border: '2px solid grey',
    boxShadow: 24,
    p: 0,
    m: 0
};

interface IDetails {
    scenario: IScenarios
};

const Details = ({scenario}: IDetails) => {
    const [ isOpen, setIsOpen ] = useState<boolean>(false);

    return (
        <>
            <Button
                onClick={() => {setIsOpen(true)}}
                variant="outlined"
                style={{borderColor: "#E4E6F4", color: "#747579", fontWeight: 400 }}
            >
                Details
            </Button>
                    <Modal
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                    >
                        <Paper sx={style}>
                            <IconButton onClick={() => setIsOpen(false)} style={{
                                position: 'fixed',
                                right: 0,
                                color: 'primary',
                                border: '1px solid primary'
                            }}>
                                <CloseIcon />
                            </IconButton>
                            <DetailsModal
                                closeDetailsModal={() => setIsOpen(false)}
                                scenario={scenario}
                            />
                        </Paper>
                    </Modal>
        </>
    )
}

const providedDetails = ({scenario}:IDetails) => {
    return (
            <Details
                scenario={scenario}
            />
    )
}

export default providedDetails;