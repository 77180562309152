import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SettingsIcon from '@mui/icons-material/Settings';
import { Badge, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Modal, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import React, { useState } from 'react';
import { ConfigItems, ModelStatus } from '../../context/ConfigureContext';
import { useScenario } from '../../context/ScenarioContext';
import ConfigurationModal from './ConfigurationModal';

const ModelStatusIcon = (status: string, type: string) => {
  if(type === "competitive_status" || type === "cost_to_pass_status") {
    return <CheckCircleIcon
      sx={{position: "absolute", bottom: -5, right: 3, fontSize: '15px', }}
    />
  }

  switch(status) {
    case ModelStatus.RUNNING:
      return <HourglassBottomIcon
        sx={{position: "absolute", bottom: -5, right: 3, fontSize: '15px'}}
      />
    case ModelStatus.CONFIGURED:
      return <SettingsIcon
        sx={{position: "absolute", bottom: -5, right: 3, fontSize: '15px'}}
      />
    case ModelStatus.SUCCESSFUL_RUN:
      return <CheckCircleIcon
      sx={{position: "absolute", bottom: -5, right: 3, fontSize: '15px', }}
    />
    case ModelStatus.FAILED_RUN:
      return (
        <ErrorOutlineIcon
          sx={{position: "absolute", bottom: -5, right: 3, fontSize: '16px', background: 'white',
          borderRadius: '15px' }}
        />
      )
    default:
      return null;
  }
}

const ModelInputButton = ({ icon, scenario, type = '', beadFundEnabled }: any) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);
  const { run_cashflow, run_clustering } = useScenario();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const statusStyle = (scenario: any) => {
    // @TODO: We're configuring this in the backend for now: default success screen
    if(type === "competitive_status" || type === "cost_to_pass_status") {
      return {
        backgroundColor: "#c8edac",
        border: "1px solid #78C142"
      }
    }

    switch(scenario[type] as any) {
      case ModelStatus.RUNNING:
        return {
          backgroundColor: "#FFEAC3",
          border: '1px solid #FAB901'
        }
      case ModelStatus.SUCCESSFUL_RUN:
        return {
          backgroundColor: "#c8edac",
          border: "1px solid #78C142",
        }
      case ModelStatus.CONFIGURED:
        return {
          backgroundColor: "#FFEAC3",
          border: '1px solid #FAB901'
        }
      case ModelStatus.FAILED_RUN:
        return {
          backgroundColor: "#FEB1B4",
          border: '1px solid #FA646A'
        }
      default: 
        return {
          backgroundColor: '#F8F9FD'
        }
    }
  }
  const open = Boolean(anchorEl);

  const modelTooltip = (status: string, type: string): string => {
    switch(status) {
      case ModelStatus.RUNNING:
        return "Currently Running"
      case ModelStatus.CONFIGURED:
        return "Configured"
      case ModelStatus.SUCCESSFUL_RUN:
        return "Ran Successfully"
      case ModelStatus.FAILED_RUN:
        return "Failed To Run"
      default:
        return "Ran Successfully";
    }
  }

  return (
    <div>
        <Tooltip title={modelTooltip(scenario[type], type)}>
        <Button color="success"  onClick={(e) => handleClick(e)} style={{ boxShadow: 'none',  padding: 5, paddingLeft: 0, minWidth: "50px",
                                    height: "40px", paddingRight: 0, border: '0px',  color: 'black', ...statusStyle(scenario)}} variant="contained">
          <Badge color="secondary" badgeContent={0}>
            {icon}
          </Badge>
          {ModelStatusIcon(scenario[type], type)}
        </Button>
        </Tooltip>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          sx={{
              marginTop: "10px"
          }}
        >
        <List >
            <ListItem
              key={0}
              sx={{ margin: 0 }}
              disablePadding
            >
              <ListItemButton onClick={() => setIsModalOpen(true)} style={{ margin: 0 }} role={undefined} dense>
                <ListItemAvatar style={{ margin: 0, color: "black" }} >
                  <IconButton style={{ margin: 0, color: "black" }} edge="end" aria-label="Configure model">
                    <LibraryBooksOutlinedIcon color="inherit" fontSize="small" />
                  </IconButton>
                </ListItemAvatar>
                <ListItemText color="grey"primary={`Configure model`} />
              </ListItemButton>
            </ListItem>
            <ListItem
              key={1}
              style={{ margin: 0 }}
              disablePadding
            >
              <ListItemButton
                disabled={scenario[type] !== ModelStatus.CONFIGURED}
                onClick={() => {
                  if(type === ConfigItems.CASHFLOW_STATUS) {
                    run_cashflow(scenario.id, beadFundEnabled, scenario?.county_id)
                  } else if( type === ConfigItems.CLUSTERING_STATUS) {
                    run_clustering(scenario.id, beadFundEnabled, scenario?.county_id);
                  }
                }}
                style={{ margin: 0 }} role={undefined} dense>
                <ListItemAvatar sx={{ margin: 0 }} >
                    <IconButton sx={{ margin: 0, color: "black" }} edge="end" aria-label="Execute model">
                        <PlayArrowIcon color="inherit" fontSize="small" />
                    </IconButton>
                </ListItemAvatar>
                <ListItemText color="grey" primary={`Execute model`} />
              </ListItemButton>
            </ListItem>
        </List>
      </Popover>
      {
        isModalOpen && (
          <Modal
            open={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
            }}
          >
            <>
              <ConfigurationModal
                closeModal={() => setIsModalOpen(false)}
                scenario={scenario}
                detailsOpen={false}
                sectionFrom="dashboard"
                />
            </>
          </Modal>
        )
      }
    </div>
  );
}

export default ModelInputButton