
import EmojiEventsIcon from '@mui/icons-material/EmojiEventsOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, CircularProgress, Divider, IconButton, MenuItem, Pagination, Select, Stack, Tooltip, Typography } from '@mui/material';
import { ConfigureProvider } from '../../context/ConfigureContext';
import { DashboardProvider } from '../../context/DashboardContext';
import { ScenarioProvider, useScenario } from '../../context/ScenarioContext';
import Dashboard from '../../layouts/Dashboard';
import ActivityLog from './ActivityLog';
import AddSenario from './AddSenario';
import DashboardHeader from './Header';
import LoadingCard from './LoadingCard';
import ModelCard from './ModelCard';
import { useEffect } from 'react';

const PAGINATION_OPTIONS = [
    10, 20, 30, 40
]

const CLEAR_INTERVAL_TIMEOUT = 300000;

const DashboardView = () => {
    const { scenarios, openedScenario, setPage, page, size, setSize, count, startShortPolling, intervalId, clearIntervalId } = useScenario();

     useEffect(() => {
        setTimeout(clearIntervalId, CLEAR_INTERVAL_TIMEOUT);
     },[]);

    return (
        <Dashboard>
            <>
                <DashboardHeader />
                <Box paddingRight="3%" paddingLeft="3%">
                    <Divider  />
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="row"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        width="100%"
                        paddingLeft="3%"
                        paddingRight="2%"
                        paddingTop="1%"
                        height="100%"
                    >
                        <Box
                            display="flex"
                            width="100%"
                            justifyContent="space-between"
                        >
                            <Stack flexDirection="row" alignItems="center">
                                <Typography
                                    color="#797C96"
                                    fontSize="20px"
                                >Scenarios</Typography>

                                
                                <IconButton onClick={() => {startShortPolling()}}>
                                {intervalId ? 
                                    <CircularProgress size={16} color='info'></CircularProgress> :
                                    <RefreshIcon fontSize="small" />
                                }
                                </IconButton>
                                <Tooltip title="To refresh the statuses of the scenarios, click this button. Please note when statuses are being refreshed, this will only be for a period of 5mins if it takes longer you'll have to refresh the page">
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        color="info"
                                    />
                                </Tooltip>
                            </Stack>
                        </Box>
                        <Box
                            display="flex"
                            paddingTop="1%"
                            minHeight="72vh"
                            height="auto"
                        >
                            <Box>
                                <Box flexWrap="wrap" display="flex">
                                    {
                                        !scenarios && [...new Array(4)].map((_, i) => <LoadingCard key={i} />)
                                    }
                                    {
                                        scenarios && scenarios.map((item: any) => (
                                            <ModelCard key={item.id} scenario={item} openedScenario={openedScenario} />
                                        ))
                                    }
                                    { scenarios && scenarios.length < 1 && <AddSenario variant="card" />}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <ActivityLog />
                </Box>
                <Box
                    paddingLeft="3%"
                    paddingRight="3%"
                >
                    <Divider />
                <Box
                    display="flex"
                    alignSelf="flex-end"
                    width="100%"
                    justifyContent="space-between"
                    paddingRight="3%"
                    paddingTop="1%"
                    paddingBottom="1%"
                >
                    <Pagination
                        onChange={(_, page) => {
                            setPage(page - 1);
                        }}
                        page={(page + 1)}
                        count={Math.ceil(count / size)}
                        color="primary"
                        variant="text"
                        shape="rounded"
                        defaultValue={1}
                    />
                    <Box
                        display="flex"
                    >
                        <Select
                            size="small"
                            value={size}
                            onChange={(e) => {
                                setPage(0);
                                setSize(e.target.value as number);
                            }}
                        >
                            {
                                PAGINATION_OPTIONS.map((item, i) => <MenuItem value={item} key={i}>{item}</MenuItem>)
                            }
                        </Select>
                    </Box>
                    <Box />
                </Box>
                    <Divider />
                </Box>
                <Box
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                    paddingLeft="3%"
                    paddingRight="3%"
                    paddingTop="1%"
                    paddingBottom="1%"
                    color="#797C96"
                >
                    <Stack color="#797C96" direction="row">
                        <IconButton>
                            <EmojiEventsIcon fontSize="small" />
                        </IconButton>
                        <Typography alignSelf="center" fontSize="14px">Competitive intensity</Typography>
                        <IconButton>
                            <RouteOutlinedIcon fontSize="small" />
                        </IconButton>
                        <Typography alignSelf="center" fontSize="14px">Cost to pass</Typography>
                        <IconButton>
                            <MonetizationOnOutlinedIcon fontSize="small" />
                        </IconButton>
                        <Typography alignSelf="center" fontSize="14px">Cash flow</Typography>
                        <IconButton>
                            <HubOutlinedIcon fontSize="small" />
                        </IconButton>
                        <Typography alignSelf="center" fontSize="14px">Clustering</Typography>
                    </Stack>
                    <Typography>Version 0.1</Typography>
                </Box>
            </>
        </Dashboard>
    )
}

const ProvidedDashboard = () => {
    return (
        <DashboardProvider>
            <ConfigureProvider>
                <ScenarioProvider>
                    <DashboardView />
                </ScenarioProvider>
            </ConfigureProvider>
        </DashboardProvider>
    )
}


export default ProvidedDashboard;