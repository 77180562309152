import {
    Backdrop,
    Box,
    CircularProgress,
    Drawer
} from "@mui/material";
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Mapbox from '../../components/mapbox';
import { useVega } from '../../hooks/useVega';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import LoadingBar from '../../pages/loadingBarPage';
import BusinessCaseOverviewFooter from '../../views/BusinessCase/BusinessCaseOverviewFooter';
import BusinessCaseOverviewSummary from '../../views/BusinessCase/BusinessCaseOverviewSummary';
import { IScenarios, useScenario } from "../../context/ScenarioContext";
import { VegaParams } from "../../@types/VegaParams";

interface IDetailsModal {
    closeDetailsModal? : () => void;
    scenario:IScenarios
};

const DetailsModal = ({
    closeDetailsModal,
    scenario
}: IDetailsModal) => {
    let scenario_id=scenario.id as string
    let lat_max=scenario.lat_max || 0
    let lat_min=scenario.lat_min || 0
    let lon_max=scenario.lon_max || 0
    let lon_min=scenario.lon_min || 0

    let navigate = useNavigate();
    // const [ isOpen, setIsOpen ] = useState<boolean>(false);
    const { height, width } = useWindowDimensions();

    const clusterContainer: any = useRef(null);
    const mapRef: any = useRef(null);

    const [ darkStreetMapStyle, setDarkStreetMapStyle ] = useState<boolean>(false);
    const [ streetMapView, setStreetMapView ] = useState<boolean>(false);

    // Image Layers
    const [ blobImg, setBlobImg ] = useState<any>(undefined); // chloropleth.
    const [ householdImg, setHouseholdImg ] = useState<any>(undefined); // chloropleth.
    const [ fibrePathImg, setFibrePathImg ] = useState<any>(undefined); // fibrePath.

    // @=>  Get the county boundary data and pass it down below. All of these must be changed to accept the default county

    const [ longitude, setLongitude ] = useState<number>((lon_max + lon_min)/2);
    const [ latitude, setLatitude ] = useState<number>((lat_max + lat_min)/2);

    // @=> Here we need to calculate the middle of the long lat values on the fly because the map renders weird if we dont do it that way. Do it for config map also.

    const [ neBound, setNeBound ] = useState<Record<string, any>>({ lat: lat_max, lng: lon_min});
    const [ swBound, setSwBound ] = useState<Record<string, any>>({ lat: lat_min, lng: lon_max});

    // Query Layers
    const [ vegaQuery, setVegaQuery ] = useState<VegaParams>(); // Cloropleth
    const [ householdVegaQuery, setHouseholdVegaQuery ] = useState<VegaParams>()
    const [ fibreVegaQuery, setFibreVegaQuery ] = useState<VegaParams>();

    // for must build cbg
    const [cbgDataList, setCbgDataList] = useState<any[]>([])
    const [ cbgModelOpen, setCbgModelOpen ] = useState<boolean>(false);
    const [isLoadingCBGs, setIsLoadingCBGs] = useState<boolean>(false);
    const [pendingScenariosToRun, setPendingScenariosToRun] = useState<string[]>([])


    // for refresh map and sidebar after must build cbg run
    const {refreshData, setRefreshData} = useScenario();

    useEffect(() => {
        setVegaQuery({
            w:width,
            h:height,
            query_type: 'build_cluster',
            lng:longitude,
            lat:latitude,
            neBound,
            swBound,
            scenario_id
        });

        setHouseholdVegaQuery({
            w:width,
            h:height,
            query_type: 'household',
            lng:longitude,
            lat:latitude,
            neBound,
            swBound,
        });

        setFibreVegaQuery({
            w:width,
            h:height,
            query_type: 'fibre_path',
            lng:longitude,
            lat:latitude,
            neBound,
            swBound,
        });
    },
    [
        width,
        height,
        longitude,
        latitude,
        neBound,
        swBound,
        scenario_id
    ]);

    // Vega calls for each layer.
    const { data, loading, error } = useVega(vegaQuery);

    const { data: householdData, loading : householdLoading, error: househouldError } = useVega(householdVegaQuery);

    const { data: fibrePathData, loading : fibreloading, error: fibreError } = useVega(fibreVegaQuery);

    const updateVegaParams = (vegaParams: Record<string, any>) => {
        const query = {
            w:width,
            h:height,
            query_type: 'build_cluster',
            lng:vegaParams.lng,
            lat:vegaParams.lat,
            neBound:vegaParams.northEastBounds,
            swBound:vegaParams.southWestBounds,
            scenario_id
        };

        const householdQuery = {
            w:width,
            h:height,
            query_type: 'household',
            lng:vegaParams.lng,
            lat:vegaParams.lat,
            neBound:vegaParams.northEastBounds,
            swBound:vegaParams.southWestBounds,
        };

        const fibreQuery = {
            w:width,
            h:height,
            query_type: 'fibre_path',
            lng:vegaParams.lng,
            lat:vegaParams.lat,
            neBound:vegaParams.northEastBounds,
            swBound:vegaParams.southWestBounds,
        };

        setVegaQuery(query);
        setHouseholdVegaQuery(householdQuery);
        setFibreVegaQuery(fibreQuery);

        setLongitude(vegaParams.lng);
        setLatitude(vegaParams.lat);
        setNeBound(vegaParams.northEastBounds);
        setSwBound(vegaParams.southWestBounds);
    };

    const viewClusteringOutput = () => {
        navigate("/clustering-output");
    };

    // Update images if they change from the vega API. (Control)
    useEffect(() => {
        if (data) setBlobImg(data);
    }, [data]);

    useEffect(() => {
        if (householdData) setHouseholdImg(householdData);
    }, [householdData]);

    useEffect(() => {
        if (fibrePathData) setFibrePathImg(fibrePathData);
    }, [fibrePathData]);

    const mapZoomIn = () => {
        mapRef.current.navMapZoomIn();
    };

    const mapZoomOut = () => {
        mapRef.current.navMapZoomOut();
    };

    const updatePendingScenariosRuns = (scenarioId:string)=>{
        if(scenarioId){
            setPendingScenariosToRun((pendings) => {
                if(pendings.indexOf(scenarioId) === -1){
                    return [...pendings, scenarioId]
                }else{
                    return [...pendings]
                }
            })
        }
    }

    useEffect(()=>{
        if(pendingScenariosToRun.length > 0) {
            localStorage.setItem('pending_runs',JSON.stringify(pendingScenariosToRun))
        }
    },[pendingScenariosToRun])

    useEffect(() => {
        const pendings = JSON.parse(localStorage.getItem('pending_runs') || '[]');
        if (pendings) {
            setPendingScenariosToRun(pendings);
        }
      }, []);

    return (
        <>
            <Box ref={clusterContainer}>
                { blobImg ?
                        <>
                            <Mapbox
                                ref={mapRef}
                                scenarioId={scenario_id}
                                long={longitude}
                                latitude={latitude}
                                onGetLocation={(params) => updateVegaParams(params)}
                                mapZoom={11}
                                sourceName='map1'
                                sourceUrl={blobImg}
                                householdUrl={householdImg}
                                fibrePathUrl={fibrePathImg}
                                mapType={darkStreetMapStyle ? 'satellite-v9' : 'dark-v10' }
                                streetMapView={streetMapView}
                                sourceCoordinates={[ // [XMax, YMin],[XMin, YMin],[XMin,YMax],[XMax, YMax] // Dynamic Query
                                    [ swBound.lng, neBound.lat ],
                                    [ neBound.lng, neBound.lat ],
                                    [ neBound.lng, swBound.lat ],
                                    [ swBound.lng, swBound.lat ],
                                ]}
                                cbgDataList = {cbgDataList}
                                cbgModelOpen={cbgModelOpen}
                                setCbgModelOpen={setCbgModelOpen}
                                setCbgDataList={setCbgDataList}
                                refreshData={refreshData}
                                setIsLoadingCBGs={setIsLoadingCBGs}
                                updatePendingScenariosRuns={updatePendingScenariosRuns}
                                pendingScenariosToRun={pendingScenariosToRun}
                                is_bead_funding={scenario.is_bead_funding}
                            />
                            <Drawer
                                variant='permanent'
                                anchor='bottom'
                                open={true}
                            >
                                <BusinessCaseOverviewFooter
                                    switchMapView={() => setStreetMapView(!streetMapView)}
                                    switchMapType={() => setDarkStreetMapStyle(!darkStreetMapStyle)}
                                    navigateToClusterPage={() => viewClusteringOutput()}
                                    scenario={scenario}
                                />
                            </Drawer>
                            <Drawer
                                variant="persistent"
                                anchor='right'
                                open={true}
                                sx={{
                                    backgroundColor: '#121212',
                                    // opacity: 0.78,
                                    opacity :1,
                                }}
                                PaperProps={{
                                    sx: {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                            >
                                <BusinessCaseOverviewSummary
                                    scenario={scenario}
                                    closeModal={() => closeDetailsModal?.()}
                                    navZoomIn={() => mapZoomIn()}
                                    navZoomOut={() => mapZoomOut()}
                                    cbgDataList = {cbgDataList}
                                    setCbgDataList={setCbgDataList}
                                    refreshData = {refreshData}
                                    setRefreshData={setRefreshData}
                                    setIsLoadingCBGs={setIsLoadingCBGs}
                                    isLoadingCBGs={isLoadingCBGs}
                                    setPendingScenariosToRun={setPendingScenariosToRun}
                                    pendingScenariosToRun={pendingScenariosToRun}
                                    updatePendingScenariosRuns={updatePendingScenariosRuns}
                                />
                            </Drawer>
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={loading}
                            >
                                <CircularProgress />
                            </Backdrop>
                        </>
                : <LoadingBar />}
            </Box>
        </>
    )
}

export default DetailsModal;
