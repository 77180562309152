import { LoginCallback } from "@okta/okta-react";
import { Suspense } from "react"; // TODO: Add lazy from react for Suspense.
import { RouteObject } from "react-router-dom";
import SuspenseLoader from "../components/suspenseLoader";
import { ProtectedRoute } from "../hocs/ProtectedRoute";
// import CashFlowModelPage from '../pages/cashFlowModalPage';
// import ClusteringResultsPage from '../pages/clusteringResultsPage';
// // ** Configuration Views **
// import ConfigureCashFlowsPage from '../pages/configureCashFlowsPage';
// import ConfigureClusteringPage from '../pages/configureClusteringPage';
// import ConfigureCostToPassPage from '../pages/configureCostToPassPage';
// import ConfigureDatasetPage from '../pages/configureDatasetPage';
// import ConfigureFibrePathPage from '../pages/configureFibrePathPage';
// import FibreNetworkPlannerPage from '../pages/fibreNetworkPlannerPage';
// import GeojsonMapPage from '../pages/geojsonMapPage';
// import ConfigureCashFlowModelPage from '../pages/configureCashFlowModelPage';
// ** Main Mapbox / Immerse Page **
// import ImmersePage from '../pages/immerse';
// TODO: Add unauthed and authed routes when the login screen is implemented.
// ** Loading **
import LoadingBar from "../pages/loadingBarPage";
// ** Output Views **
// import ReportDataTablePage from '../pages/reportDataTablePage';
// import VerifyCashFlowModelPage from '../pages/verifyCashFlowPage';
// import VerifyDatasetsPage from '../pages/verifyDatasetsPage';
// ** Pages **
// import WelcomePage from "../pages/welcomePage";
// import BusinessCaseOverview from '../views/BusinessCase';
import Dashboard from "../views/Dashboard";

// TODO: @=> Make sure Lazy Loader works.
const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const router: RouteObject[] = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/loading", // TODO: Extract this out as a layout feature.
        element: <LoadingBar />,
      },
      {
        path: "dashboard",
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/login/callback",
    element: <LoginCallback />,
  },
];

export default router;
