import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#029FDB',
    },
    secondary: {
      main: '#0057B8',
    },
  },
  typography: {
    fontFamily: ['"Open Sans"', 'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(", "),
    button: {
      textTransform: 'none'
    }
  }
});

export default theme;