import { Box, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import BusinessCaseTableHeader from './BusinessCaseTableHeader';
import { useOktaAuth } from "@okta/okta-react";
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import BASE_API_URL from '../../utils/baseUrl';
import { format } from '../../utils/functions';

interface IColumnHeader {
    field: string, 
    headerName: string,
    width: number,
    sortable?:boolean,
    renderCell?: (params:any) => void,
    cellClassName? : string
}

const MustBuildCbg = (props:any) => {
  const { title, cbgDataList, isLoadingCBGs, setIsLoadingCBGs, scenarioId, setCbgDataList, updatePendingScenariosRuns } = props;
  const [showTable, setShowTable] = useState(true)
  const { authState } = useOktaAuth();

  const config = {
    headers: {
        Authorization: 'Bearer ' + authState?.idToken?.idToken,
        "Content-Type": "application/json"
    }
};

  const handleDeleteClick = (id:string)=>{
    setIsLoadingCBGs(true)
    axios.delete(`${BASE_API_URL}clustering/must_build_areas?scenario_id=${scenarioId}&geoid=${id}`, config).then((response) =>{
        setCbgDataList([...cbgDataList.filter((a:any) => a.id !== id)]);
        setIsLoadingCBGs(false)
        updatePendingScenariosRuns(scenarioId)
    }); 
}

    const aggregatedColumns: IColumnHeader[] = [
        {
            field: 'id',
            headerName: 'GeoId',
            width: 200,
        },
        {
            field: 'net_costperhome_all',
            headerName: 'Cost Per Home',
            width: 150,
            cellClassName : 'middleCell',
            renderCell: (params) => {
                return `$${format(params.value)}`;
            },        
        },
        {
            field: "action",
            headerName: "",
            sortable: false,
            width : 50,
            renderCell: (params:any) => {
              const onClick = (e:any) => {
                handleDeleteClick(params.getValue(params.id, 'id'))
              };
        
              return (
                        <IconButton onClick={onClick} title='Delete'>
                            <DeleteIcon fontSize='small' sx={{color:"red"}} />
                        </IconButton>
                    )
            }
          },
        
    ]

    return (
        <Box sx={{marginTop:'8px', opacity:'1', borderRadius:'10px', background:'black'}}>
        <BusinessCaseTableHeader
            showTable={showTable}
            setShowTable={setShowTable}
            title = {title}
            isChart={false}
        />
        <Box sx={{paddingBottom:'5px'}}>
        {showTable &&
           <Box sx={{ width: '98%', marginLeft:'10px' }}>
            <DataGrid 
                sx={{
                '.MuiDataGrid-columnSeparator': {
                    display: 'none',
                },
                '.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root' : {
                    color:'#0057B8'
                },
                '.MuiDataGrid-columnHeaders': {
                    color: "#0057B8",
                    border:'none',
                    fontWeight:'bold',
                    height:'10px !important',
                    borderBottom:'1px solid gray',
                },
                '.MuiTablePagination-toolbar' : {
                    color: "white"
                },
                '.MuiDataGrid-cell' : {
                    border:'none',
                    height:'10px',
                },
                '& .middleCell': {
                    borderRight: '1px solid gray',
                    borderLeft:'1px solid gray',
                },
                '.MuiDataGrid-footerContainer' : {
                    border:'none',
                    fontSize:'10px',
                    fontWeight:'bold',
                },
                '&.MuiDataGrid-root': {
                    border: 'none',
                    color: "white",
                    height:'100%',
                    fontSize:'13px',
                    fontWeight:'bold',
                  },
                  '.MuiDataGrid-overlay' : {
                    background:'black'
                  }
                  
                
                }}
                autoHeight
                rowHeight={30}
                loading={isLoadingCBGs}
                rows={cbgDataList ?? []}
                //@ts-ignore
                columns={aggregatedColumns}
                pageSize={10}
                rowsPerPageOptions={[5]}
                hideFooterSelectedRowCount={true}
                getRowId={(row: any) => row.id + row.scenario_name}
            />
        </Box>
      }
      </Box></Box>
    );
}

export default MustBuildCbg