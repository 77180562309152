import { useState } from 'react';
import { Badge, Box, Paper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import ViewSelector from './ViewSelector';
import { IScenarios } from '../../context/ScenarioContext';

interface MapFooterProps {
    switchMapView?: () => void;
    switchMapType?: () => void;
    navigateToClusterPage?: () => void;
    scenario: IScenarios;
};

const BusinessCaseOverviewFooter = ({
    switchMapView,
    switchMapType,
    scenario,
}: MapFooterProps) => {
    const [mapView, setMapView] = useState('area');

    const handleChange = () => {
        const activeTab = mapView === 'area' ? 'path': 'area';
        setMapView(activeTab);
    };

    const toggleChildren = [
        <ToggleButton value="area" key="area">
            AREA
        </ToggleButton>,
        <ToggleButton value="path" key="path">
            PATH
        </ToggleButton>
    ];

    return (
        <Paper
            sx={{
                position: 'fixed',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'transparent',
                bottom: 0,
                left: 0,
                right: 0,
                padding: 3,
                width: "60%"
            }}
            elevation={2}
        >
            <Box display="flex" justifyItems="center">
                <Paper
                    elevation={0}
                    sx={{
                        display: "flex",
                        border: "1px solid #fff",
                        flexWrap: "wrap",
                        backgroundColor: "rgba(12,12,12,0.78)",
                        marginRight: '1em'
                    }}
                >
                    <ToggleButtonGroup
                        size="small"
                        aria-label="Map View"
                        color="primary"
                        value={mapView}
                        onChange={() => {
                                switchMapView?.()
                                handleChange()
                            }
                        }
                        sx={{
                            '& .MuiToggleButtonGroup-grouped': {
                                margin: "4px",
                                border: 0,
                                color: "#ffffff",
                                backgroundColor: "primary",
                                padding: "10px 30px",
                                "& .MuiToggleButton-root " : {
                                },
                                "&.Mui-selected": {
                                    color: "#ffffff",
                                    backgroundColor: "rgba(2, 159, 219, 1)",
                                },
                                '&.Mui-disabled': {
                                    border: 0,
                                },
                                '&:not(:first-of-type)': {
                                    borderRadius: 1,
                                },
                                '&:first-of-type': {
                                    borderRadius: 1,
                                },
                            },
                            "& .MuiButtonBase-root" : {
                                backgroundColor: "primary",
                            },
                        }}
                    >
                        {toggleChildren}
                    </ToggleButtonGroup>
                </Paper>
                <Paper
                    elevation={0}
                    sx={{
                        display: "flex",
                        border: "1px solid #fff",
                        flexWrap: "wrap",
                        backgroundColor: "rgba(12,12,12,0.78)",
                        padding: '0 1em'
                    }}
                >
                    <ViewSelector onChange={() => switchMapType?.()} />
                    <Typography
                        color="white"
                        fontWeight="600"
                        alignSelf="center"
                        variant="body1"
                        fontSize="12px"
                    >
                            SATELLITE VIEW
                    </Typography>
                </Paper>

               { ((scenario?.bead_funding ?? 0) !== 0) && <Paper
                    elevation={0}
                    sx={{
                        display: "flex",
                        border: "1px solid #fff",
                        // flexWrap: "wrap",
                        backgroundColor: "rgba(12,12,12,0.78)",
                        padding: '0 1em',
                        margin: "0 2em"
                    }}
                >
                    <Typography
                        color="white"
                        fontWeight="600"
                        alignSelf="center"
                        variant="body1"
                        fontSize="13px"
                    >
                            <Badge
                                badgeContent=" "
                                color="primary"
                                overlap="circular"
                                sx={{
                                    marginRight: "2em",
                                    marginLeft:"1em",
                                    '.MuiBadge-badge':  { backgroundColor: mapView === 'area' ? "#bdcf32" : "#a7f542" }
                                }}
                            />
                            { mapView === 'area' ? "New Backhaul" : "BEAD Eligible" }
                    </Typography>
                    <Typography
                        color="white"
                        fontWeight="600"
                        alignSelf="center"
                        variant="body1"
                        fontSize="13px"
                    >
                            <Badge
                                badgeContent=" "
                                color="primary"
                                overlap="circular"
                                sx={{
                                    margin: "2em",
                                    '.MuiBadge-badge':  { backgroundColor: mapView === 'area' ? "#f46a9b" : "#db3d3d"}
                                }}
                            />
                            { mapView === 'area' ? "Existing Backhaul": "Not BEAD Eligible" }
                    </Typography>
                </Paper> }
            </Box>
        </Paper>
    )
}

export default BusinessCaseOverviewFooter;
