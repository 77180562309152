// TODO: Refine these configs with some default types.

export const initialAppState = {
    userId: '',
    mapType: '',
    marketConfig: {},
    clusteringConfig: {},
    cashFlowConfig: {},
    fibrePathConfig: {},
};

export const AppReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'local_storage': {
            return action.value;
        }
        case 'set_user_id': {
            return {
                ...state,
                userId: action.value
            };
        }
        case 'config_market': {
            return {
                ...state,
                marketConfig: action.value
            };
        }
        case 'config_clustering': {
            return {
                ...state,
                clusteringConfig: action.value
            };
        }
        case 'config_cashflow': {
            return {
                ...state,
                cashFlowConfig: action.value
            };
        }
        case 'config_fibre_path': {
            return {
                ...state,
                fibrePathConfig: action.value
            };
        }
        case 'clear_config': {
            return action.value;
        }
        case 'set_new_scenario': {
            return action.value;
        }
        default:
            console.warn('Unknown State Action');
            return null;
    }
};
