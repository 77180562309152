import Plot from 'react-plotly.js'

interface IFinancialChartProps {
  chartData : {
    x : number[],
    y : number[],
    marker : IColor,
    type :string
  }[]
}

interface IColor {
  color : string[]
}

const FinancialChart = (props:IFinancialChartProps) => {
    const {chartData} = props      

    return(
        <Plot
            //@ts-ignore
            data={chartData}
            layout={{
                width: 380, 
                height: 240, 
                legend : {
                    x: -1,
                    y: -1,
                  }, 
                paper_bgcolor:'rgba(0,0,0,0)',
                plot_bgcolor:"rgba(0,0,0,0)",
                font : {color:"white"},
                margin: {
                    l: 50,
                    r: 50,
                    b: 50,
                    t: 50,
                    pad: 4
                  },
                  xaxis : {
                    tickmode :'linear',
                    tick0 : 1,
                    dtick : 1,
                  },
                  yaxis: {
                    title: 'Dollars',
                    showgrid: false,
                    tickangle: -90

                  },
            }}
          />
    )
}

export default FinancialChart