import Plot from 'react-plotly.js'

interface IChartData {
    chartData : IData
}

interface IData {
    data : ITrace[]
}

interface ITrace {   
    x: number[];
    y: string[];
    name: string;
    type: string;
    orientation: string;    
}

const BuildMatrixChart = (props:IChartData) => {
    const {chartData} = props

    return(
        <Plot
            //@ts-ignore
            data={chartData.data}
            layout={{
                width: 380, 
                height: 240, 
                barmode:'stack', 
                legend : {
                    x: -1,
                    y: -1,
                  }, 
                xaxis : {visible:false},
                paper_bgcolor:'rgba(0,0,0,0)',
                plot_bgcolor:"rgba(0,0,0,0)",
                font : {color:"white"},
                margin: {
                    l: 50,
                    r: 50,
                    b: 50,
                    t: 50,
                    pad: 4
                  }
            }}
          />
    )
}

export default BuildMatrixChart