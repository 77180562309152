import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import BASE_API_URL from '../utils/baseUrl';
// Authentication
import { useOktaAuth } from "@okta/okta-react";

const ConfigureContext = createContext<IConfigureState | undefined>(undefined);

interface IConfigureProvder {
    children: JSX.Element
}

export enum ModelStatus {
    NOT_CONFIGURED = '',
    CONFIGURED = "CONFIGURED",
    RUNNING = "RUNNING",
    SUCCESSFUL_RUN = "SUCCESSFUL_RUN",
    FAILED_RUN = "FAILED_RUN"
}

export enum ConfigItems {
    COMPETITIVE_STATUS = 'cost_to_pass_status',
    COST_TO_PASS_STATUS = 'competitive_status',
    CASHFLOW_STATUS = 'cash_flow_status',
    CLUSTERING_STATUS = 'clustering_status'
}

export interface ICounty {
    id?: string,
    county_name: string
    Fiber_length?: number,
    Total_parcels?: number,
    Avg_area?: number,
    Avg_ft_per_house?: number,
    Avg_area_per_house?: number,
    geometry?: string,
    lon_min?: number,
    lat_min?: number,
    lon_max?: number,
    lat_max?: number
}

interface IConfigureState {
    defaultConfigs: Record<string, any>,
    changeSelectedCounty: (county: ICounty) => void,
    // validationBackground: string | null, // @=> This belongs in another space as well.
    isConfigEnabled: boolean,
    toggleConfigEnabled: () => void,
    clearConfiguration: () => void,
    selectedCounty: ICounty | null,
    configEnabled: () => void,
    resetState: () => void,
    loadingValidation: boolean,
    setLoadingValidation: any,
    counties : ICounty[],
    getConfigDefaults: (id?: string) => Promise<void>
}

const ConfigureProvider = ({ children }: IConfigureProvder) => {
    const [ isConfigEnabled, setIsConfigEnabled ] = useState<boolean>(false);
    const [ counties, setCounties ] = useState<ICounty[]>([]);
    const [ defaultConfigs, setDefaultConfigs ] = useState({});
    const [ selectedCounty, setSelectedCounty ] = useState<ICounty | null>(null);
    // const [ validationBackground, setValidationBackround ] = useState<string | null>(null); // @=> .Move this and handle it in AddScenario.
    const [ loadingValidation, setLoadingValidation ] = useState(false);
    const { height, width } = useWindowDimensions();
    const [, setVegaQuery ] = useState<Record<string,any>>({});
    const { authState } = useOktaAuth();
    // const { data } = useVega(vegaQuery); // @=> Vega call being used here.

    const resetState = () => {
        setIsConfigEnabled(false);
        setSelectedCounty(null);
        // setValidationBackround(null); // @=> Move to add scenario.
    }

    const toggleConfigEnabled = () => {
        setIsConfigEnabled(!isConfigEnabled);
    }

    const configEnabled = () => {
        setIsConfigEnabled(true)
    }
    const changeSelectedCounty = (county: ICounty) => {
        setSelectedCounty(county)
    }

    const clearConfiguration = () => {
        setIsConfigEnabled(false);
        setSelectedCounty(null)
    }

    const getConfigDefaults = async (id?: string) => {
        if (authState?.idToken?.idToken) {
            const config = {
                headers: {
                    Authorization: 'Bearer ' + authState?.idToken?.idToken,
                }
            };
            const res = await axios.get(id? `${BASE_API_URL}scenario/config?scenario_id=${id}`: `${BASE_API_URL}scenario/config`, config);
            setDefaultConfigs(res.data);
        }
    }

    const getCounties = async () => {
        if (authState?.idToken?.idToken) {
            const config = {
                headers: {
                    Authorization: 'Bearer ' + authState?.idToken?.idToken,
                }
            };
            const res = await axios.get(`${BASE_API_URL}counties`, config);
            setCounties(res.data);
        }
    }

    useEffect(() => {
        getConfigDefaults();
        getCounties();
    }, []);

    useEffect(() => {
        // setValidationBackround(null); // @=> Move to add scenario.
        if (!selectedCounty) return;
        setLoadingValidation(true);
        // updateBackground(); // @=> Here is the old logic (3)
    }, [selectedCounty]);


    return (
        <ConfigureContext.Provider
            value={{
                defaultConfigs,
                changeSelectedCounty,
                // validationBackground, // @=> Move to add scenario.
                isConfigEnabled,
                toggleConfigEnabled,
                clearConfiguration,
                selectedCounty,
                configEnabled,
                resetState,
                loadingValidation,
                setLoadingValidation,
                counties,
                getConfigDefaults
            }}
        >
            {children}
        </ConfigureContext.Provider>
    )
}


const useConfigure = () => {
    const context = useContext(ConfigureContext);
    if(context === undefined) {
        throw new Error("useConfigure can only used inside BusinessOverviewProvider")
    }

    return context;
}

export {
    ConfigureProvider,
    useConfigure
};
