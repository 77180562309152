import { createContext, useContext, useEffect, useState } from 'react';
import { Alert, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import axios from 'axios';
import { useLocalStorage } from '../hooks/useLocalStorage';
import BASE_API_URL from '../utils/baseUrl';
// Authentication
import { useOktaAuth } from "@okta/okta-react";

const ScenarioContext = createContext<IScenarioState | undefined>(undefined);

interface IScenarioProvder {
    children: JSX.Element
}
export interface IScenarios {
    id?: string,
    scenario_name?: string,
    created_at?: string,
    last_update?: string,
    county_id?: string,
    terminal_penetration?: string,
    cable_feet_per_home?: string,
    irr?: string,
    total_hhs?: string,
    percent_eligible_homes?: string,
    competitive_id?: unknown,
    cost_to_pass_id?: unknown,
    cash_flow_id?: unknown,
    clustering_id?: unknown,
    clustering_status?: string,
    cash_flow_status?: string,
    state?: string,
    county_name?: string
    cablefeetperhome?: string,
    fiber_percent?: string,
    lat_max?: number;
    lat_min?: number;
    lon_max?: number;
    lon_min?: number;
    bead_funding?: string;
    adjusted_bead_funding?: number;
    arpu?: string;
    take_up_assumptions?: any;
    is_bead_funding?: boolean;
}

interface IScenarioState {
    scenarios: IScenarios[] | null,
    archiveScenario: (id: string) => void
    createScenario: (scenario: IScenarios) => void,
    isScenarioCreated: boolean,
    loading: boolean,
    setIsScenarioCreated: any,
    updateScenarioConfig: (data: IScenarioConfigInput, detailsOpen:boolean) => void,
    createdScenario: string | null,
    run_cashflow: (scenario_id: string, beadFundEnabled: boolean, county_id?: string) => void,
    run_clustering: (scenario_id: string, beadFundEnabled: boolean, county_id?: string) => void,
    rename: (id: string, name: string) => void,
    isValid: UniqueScenarioName,
    validNameLoading: boolean,
    validScenarioName: (name: string) => void,
    setIsValid: (name: UniqueScenarioName) => void,
    page: number,
    setPage: (value: number) => void,
    size: number,
    setSize: (value: number) => void,
    count:  number,
    setCount: (value: number) => void
    isRunning: boolean,
    isSaving: boolean,
    currentTime: Date | null,
    run_models: (scenario_id: string, county_id: string, beadFundEnabled: boolean) => void,
    getScenarios: () => Promise<any>,
    getArchetypeTotalHouseholds: (scenario_id: string) => Promise<any>,
    startShortPolling: (notificationMsg?:string) =>  void,
    intervalId: NodeJS.Timer | undefined,
    clearIntervalId: () => void,
    getBusinessOverviewMetrics: (scenario_id: string) => Promise<IBusinessOverviewMetrics | undefined>,
    openedScenario : string,
    setOpenedScenario : (id:string) => void
    refreshData: boolean,
    setRefreshData: (refreshData:boolean) => void
    setIsRunning: (value:boolean) => void
}

interface IScenarioConfigInput {
    id: string,
    county_id: string | undefined,
    irr_thresh: string | number,
    min_thresh: string | number,
	time_to_terminal_penetration?: string | number,
	cost_per_foot?: string,
	fixed_cost_per_home?: string,
    fiber_percent?: string,
    terminal_penetration?: string,
    total_sfuhhs?: string,
    total_hhs?: string,
    cable_feet_per_home?: string,
    total_mduhhs?: string,
    bead_funding?: string,
    arpu?: string,
    take_up_assumptions?: any
}

export interface IBusinessOverviewMetrics {
  avg_cablefeetperhome: number;
  total_sfus: number;
  total_mdus: number;
  total_households: number;
  avg_percent_fiber: number;
  avg_irr: number;
  avg_terminal_penetration: number;
  total_bead_eligible: number;
  average_cost_per_home: number;
  average_subsidised_cost: number;
  total_subsidy_requested: number;
  percent_eligible_homes: number;
  avg_cablefeetperhome_all: number;
  total_households_all: number;
  avg_irr_all: number;
  avg_terminal_penetration_all: number;
  total_bead_eligible_all: number;
  avg_cablefeetperhome_clustered: number;
  total_households_clustered: number;
  avg_irr_clustered: number;
  avg_terminal_penetration_clustered: number;
  total_bead_eligible_clustered: number;
  net_costperhome_all: number;
  upfront_costperhome_all: number;
  requested_subsidyperhome_all: number;
  total_cap_without_sub_all: number;
  total_cap_with_sub_all: number;
  effective_subsidy_all: number;
  net_costperhome_clustered: number;
  upfront_costperhome_clustered: number;
  requested_subsidyperhome_clustered: number;
  total_cap_without_sub_clustered: number;
  total_cap_with_sub_clustered: number;
  effective_subsidy_clustered: number;
  avg_backhaul_cost_per_cbg_clustered: number;
  avg_backhaul_cost_per_cbg: number;
  y0: number;
  y1: number;
  y2: number;
  y3: number;
  y4: number;
  y5: number;
  y6: number;
  y7: number;
  y8: number;
  y9: number;
  y10: number;
  y11: number;
  y12: number;
  y13: number;
  y14: number;
  y15: number;
  y16: number;
  y17: number;
  y18: number;
  y19: number;
  y20: number;
}

export enum UniqueScenarioName {
    IS_UNQIQUE = "Is Unique",
    NOT_UNQIQUE = "Not Unique",
    NOT_CHECKED = "Unchecked"
}

const DEFAULT_PAGE_SIZE = 10;
const POLLING_INTERVAL = 4000; 

const ScenarioProvider = ({ children }: IScenarioProvder) => {
    const [ scenarios, setScenarios] = useState<any>(null);
    const [ isScenarioCreated, setIsScenarioCreated ] = useState<boolean>(false);
    const [ createdScenario, setCreatedScenario ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ showNotification, setShowNotification ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ alertType, setAlertType ] = useState<"info" | "error" | "warning" | "success">('info')
    const [ validNameLoading, setValidNameLoading ] = useState(false);
    const [ isValid, setIsValid ] = useState<UniqueScenarioName>(UniqueScenarioName.NOT_CHECKED);
    const [ page, setPage ] = useState(0);
    const [ count, setCount ] = useState(0);
    const [ size, setSize ] = useLocalStorage("fiber_pagination_size", DEFAULT_PAGE_SIZE );
    const [ isRunning, setIsRunning ] = useState(false);
    const [ isSaving, setIsSaving ] = useState(false);
    const [ currentTime, setCurrentTime ] = useState<Date | null>(null);
    const { authState } = useOktaAuth();
    const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
    const [openedScenario, setOpenedScenario] = useState('');
    const [refreshData, setRefreshData] = useState(false)

    const startShortPolling = (notificationMsg?:string) => {
        if(!intervalId){
            const timer = setInterval(async () => {
                if (authState?.idToken?.idToken) {
                    const config = {
                        headers: {
                            Authorization: 'Bearer ' + authState?.idToken?.idToken,
                        }
                    };
                    try {
                        const response = await axios.head(`${BASE_API_URL}models/status`, config);
                        if(response.status === 200) {
                            clearInterval(timer);
                            setIntervalId(undefined);

                            await getScenarios();
                            if(notificationMsg){
                                setRefreshData(true);
                                setIsRunning(false);
                                setAlertType("success");
                                setShowNotification(true);
                                setMessage(notificationMsg);
                            }
                        }
                    } catch(e){
                        //@ts-ignore
                        if(e?.response?.status === 404){
                            await getScenarios();
                        } else{
                            setIsRunning(false);
                            clearInterval(timer);
                            setIntervalId(undefined);
                            throw e;
                        }
                    }
                }
            }, POLLING_INTERVAL);
            
            setIntervalId(timer);
        } else{
            setAlertType("info");
            setShowNotification(true);
            setMessage("It's running already");
        }
    }

    const validScenarioName = async (name: string) => {
        setValidNameLoading(true)
        try {
            if (authState?.idToken?.idToken) {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + authState?.idToken?.idToken,
                    }
                };
                const { data } = await axios.post(`${BASE_API_URL}scenario/unique_name`, {
                    scenario_name: name
                }, config);

                if(data.is_unique === true) {
                    setIsValid(UniqueScenarioName.IS_UNQIQUE);
                } else if(data.is_unique === false) {
                    setIsValid(UniqueScenarioName.NOT_UNQIQUE);
                } else {
                    setIsValid(UniqueScenarioName.NOT_CHECKED)
                }
            }
        } catch(error) {
            setAlertType("error")
            setShowNotification(true);
            setMessage("Error validating unique name");
        } finally {
            setValidNameLoading(false)
        }
    }

    const createScenario = async(scenario: IScenarios) => {
        setLoading(true);
        setCreatedScenario(null);

        try {
            if (authState?.idToken?.idToken) {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + authState?.idToken?.idToken,
                    }
                };
                const { data } = await axios.post(`${BASE_API_URL}scenario`, scenario, config);
                setCreatedScenario(data.id);
                await getArchetypeTotalHouseholds(data.id);
                getScenarios();
                setAlertType("success")
                setShowNotification(true);
                setMessage("Scenario successfully created")
            }
        } catch(error) {
            setAlertType("error")
            setShowNotification(true);
            setMessage("Error creating scenario");
        }
        setIsScenarioCreated(true)
        setLoading(false);
    }

    const updateScenarioConfig = async (scenarioConfig: IScenarioConfigInput, detailsOpen:boolean) => {
        setLoading(true);
        setIsSaving(true);
        if(detailsOpen){
            setOpenedScenario(scenarioConfig.id)
        }
        try {
            if (authState?.idToken?.idToken) {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + authState?.idToken?.idToken,
                    }
                };
                const { data } = await axios.put(`${BASE_API_URL}scenario/config`, scenarioConfig, config)
                let scenarioIndex = scenarios.findIndex((s:any) => s.id === data.id);
                scenarios[scenarioIndex] = Object.assign(scenarios[scenarioIndex], data); 
                setScenarios(scenarios);
                setAlertType("success")
                setShowNotification(true);
                setMessage("Scenario config successfully updated");
            }
        } catch(error) {
            setAlertType("error")
            setShowNotification(true);
            setMessage("Error updating scenario config")
        } finally {
            setLoading(false);
            setIsSaving(false);
        }
    }

    const getScenarios = async () => {
        try {
            if (authState?.idToken?.idToken) {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + authState?.idToken?.idToken,
                    }
                };
                const { data } = await axios.get(`${BASE_API_URL}scenario?page=${page}&size=${size}`, config);
                setScenarios(data);
            }
        } catch(error) {
            setAlertType("error")
            setShowNotification(true);
            setMessage("Error: couldn't fetch scenarios")
        }
    }

    const getCount = async () => {
        try {
            if (authState?.idToken?.idToken) {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + authState?.idToken?.idToken,
                    }
                };
                const { data } = await axios.get(`${BASE_API_URL}scenario/count`, config);
                setCount(data?.count || 0);
            }
        } catch (error) {
            setAlertType("error")
            setShowNotification(true);
            setMessage("Error: couldn't get scenario count")
        }
    }

    const archiveScenario = async (id: string) => {
        setLoading(true)
        if(!id) {
            setShowNotification(true);
            setMessage("Error deleting scenario")
            setLoading(false);
            return
        }
        try {
            if (authState?.idToken?.idToken) {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + authState?.idToken?.idToken,
                    }
                };
                await axios.delete(`${BASE_API_URL}scenario?id=${id}`, config);
                await getScenarios();
                setAlertType("success")
                setShowNotification(true);
                setMessage("Removed scenario")
            }
        } catch(error) {
            setAlertType("error")
            setShowNotification(true);
            setMessage("Error deleting scenario")
        } finally {
            setLoading(false);
        }
    }

    const run_clustering = async (scenario_id: string, beadFundEnabled: boolean, county_id?: string) => {
        setShowNotification(false);

        try {
            if (authState?.idToken?.idToken) {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + authState?.idToken?.idToken,
                    }
                };
                setAlertType("info")
                setShowNotification(true);
                setMessage("Running Clustering model for scenario")
                await axios.post(`${BASE_API_URL}models/execute`,
                {
                    id: scenario_id,
                    county_id,
                    adjust_bead_subsidy: beadFundEnabled
                },
                config);
                startShortPolling("Models run completed successfully");
            }
        } catch(error) {
            setAlertType("error")
            setShowNotification(true);
            setMessage("Failed to run clustering")
        } finally {
            setLoading(false);
        }
    }

    const run_models = async (scenario_id: string, county_id: string, beadFundEnabled: boolean) => {
        try {
            if (authState?.idToken?.idToken) {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + authState?.idToken?.idToken,
                    }
                };
                setAlertType("info")
                setShowNotification(true);
                setMessage("Started models run for scenario");
                await axios.post(`${BASE_API_URL}models/execute`, {
                    scenario_id: scenario_id,
                    county_id,
                    adjust_bead_subsidy: beadFundEnabled
                }, config);
                startShortPolling("Models run completed successfully");
                setAlertType("info");
                setShowNotification(true);
                setMessage("Running models for scenario");
            }
        } catch(error) {
            setAlertType("error")
            setShowNotification(true);
            setMessage("Failed to run models")
        } finally {
            setLoading(false);
        }
    }

    const run_cashflow = async (scenario_id: string, beadFundEnabled: boolean, county_id?: string) => {
        try {
            if (authState?.idToken?.idToken) {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + authState?.idToken?.idToken,
                    }
                };
                setAlertType("info")
                setShowNotification(true);
                setMessage("Running Cashflow model for scenario");
                await axios.post(`${BASE_API_URL}models/execute`, {
                    scenario_id: scenario_id,
                    county_id: county_id,
                    adjust_bead_subsidy: beadFundEnabled
                }, config);
                startShortPolling("Models run completed successfully");
            }
        } catch(error) {
            setAlertType("error")
            setShowNotification(true);
            setMessage("Failed to run cashflow")
        } finally {
            setLoading(false);
        }
    }

    const rename = async (id: string, name: string) => {
        setLoading(true);
        try {
            if (authState?.idToken?.idToken) {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + authState?.idToken?.idToken,
                    }
                };
                await axios.put(`${BASE_API_URL}scenario/rename`, { id, scenario_name: name }, config);
                await getScenarios();
                setAlertType("success")
                setShowNotification(true);
                setMessage(`Renamed scenario to ${name}`)
            }
        } catch(error) {
            setAlertType("error")
            setShowNotification(true);
            setMessage("Failed to rename scenario")
        } finally {
            setLoading(false);
        }
    }

    const getArchetypeTotalHouseholds = async (scenario_id: string) => {
        if (authState?.idToken?.idToken) {
            const config = {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authState?.idToken?.idToken,
                }
            };

            const { data } = await axios.get(`${BASE_API_URL}business-overview/archetype_totals?scenario_id=${scenario_id}`, config);
            return data.archetype_totals;
        }
    }

    const clearIntervalId = () => {
        if(intervalId){
            clearInterval(intervalId);
            setIntervalId(undefined);
        }
    }

    const getBusinessOverviewMetrics = async (scenario_id: string): Promise<IBusinessOverviewMetrics | undefined> => {
        try {
            if (authState?.idToken?.idToken) {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + authState?.idToken?.idToken,
                        "Content-Type": "application/json"
                    }
                };
                const { data } = await axios.get(`${BASE_API_URL}business-overview/summary_metrics?scenario_id=${scenario_id}`, config);

                return data as IBusinessOverviewMetrics;
            }
        } catch (error) {
            console.error(error);
            setAlertType("error")
            setShowNotification(true);
            setMessage("Error: couldn't getting scenario business overview metrics");
        }
    }

    useEffect(() => {
        setScenarios(null);
        getCount();
        startShortPolling();

        return () =>{
            if(intervalId){
                clearInterval(intervalId)
            }
        };
    }, [page, size]);

    return (
        <ScenarioContext.Provider
            value={{
                scenarios,
                archiveScenario,
                createScenario,
                isScenarioCreated,
                loading,
                setIsScenarioCreated,
                createdScenario,
                run_cashflow,
                run_clustering,
                rename,
                isValid,
                validNameLoading,
                validScenarioName,
                setIsValid,
                page,
                setPage,
                size,
                setSize,
                count,
                setCount,
                isRunning,
                isSaving,
                currentTime,
                run_models,
                updateScenarioConfig,
                getScenarios,
                getArchetypeTotalHouseholds,
                startShortPolling,
                intervalId,
                clearIntervalId,
                getBusinessOverviewMetrics,
                openedScenario,
                setOpenedScenario,
                refreshData,
                setRefreshData,
                setIsRunning
            }}
        >
            {children}
            <Backdrop
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                autoHideDuration={5000}
                open={showNotification}
                onClose={() => {
                    setShowNotification(false);
                }}
                message={message}
            >
                <Alert severity={alertType}>{message}</Alert>
            </Snackbar>
        </ScenarioContext.Provider>
    )
}


const useScenario = () => {
    const context = useContext(ScenarioContext);
    if(context === undefined) {
        throw new Error("useScenario can only used inside BusinessOverviewProvider")
    }

    return context;
}

export {
    ScenarioProvider,
    useScenario
};

