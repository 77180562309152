import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface DefaultLayoutProps {
    title?: string;
    view:  ReactNode; //FunctionComponent;
};

const mainStyle = {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    background: `transparent linear-gradient(241deg, #029FDB 0%, #0057B8 100%) 0% 0% no-repeat padding-box;`,
    opacity: 1,
}

const DefaultLayout = ({
    title,
    view,
}: DefaultLayoutProps) => {

    return (
        <Box sx={mainStyle}>
           {view}
        </Box>
    )
};

export default DefaultLayout;
