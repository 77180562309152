import { FC, useEffect } from 'react';
import { Box, Container, Typography } from "@mui/material";
import { LinearProgress } from "@mui/material";

import { useNavigate, useLocation } from 'react-router-dom';

import DefaultLayout from "../../layouts/main";

interface LoadingProps{
    navigateRoute?: string;
    duration?: number;
};

const LoadingBar: FC<LoadingProps> = () => {
    let navigate = useNavigate();
    const location: any = useLocation();

    const loadingStyle = {
        height: '40px',
        borderRadius: '40px',
        border: '4px solid'
    };

    useEffect(() => {
        if (location?.state?.route && location?.state?.duration) {
            setTimeout(function(){
                navigate(location?.state?.route);
            }, location?.state?.duration);
        };
    });

    return (
        <DefaultLayout
        view={
            <Container
                sx={{
                    color: (theme: any) => theme.palette.common.white
                }}
            >
                <Box paddingTop='25%'>
                    <Typography
                        textAlign='center'
                        variant='h3'
                        fontWeight={700}
                    >
                        GATHERING DATA
                    </Typography>
                    <Typography
                        textAlign='center'
                        fontWeight={500}
                    >
                        Please wait
                    </Typography>
                    <Box m={2}>
                        <LinearProgress
                            sx={loadingStyle}
                        />
                    </Box>
                    <Typography
                        textAlign='center'
                        fontWeight={500}
                    >
                    </Typography>
                </Box>
            </Container>
        }
        />
    );
};

export default LoadingBar;