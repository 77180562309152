import { MONTHS } from "./constant";

const pow = Math.pow,
  floor = Math.floor,
  abs = Math.abs,
  log = Math.log;
const ABBREV = "KMB"; // could be an array of strings: [' m', ' Mo', ' Md']

const round = (n: number, precision: number) => {
  let prec = Math.pow(10, precision);
  return Math.round(n * prec) / prec;
};

export const format = (n: number) => {
  let base = floor(log(abs(n)) / log(1000));
  const suffix = ABBREV[Math.min(2, base - 1)];
  base = ABBREV.indexOf(suffix) + 1;
  return suffix ? round(n / pow(1000, base), 1) + suffix : "" + round(n, 2);
};

export const toDecimal = (percent: any) => {
  return parseFloat(percent) / 100;
};

export const toPercent = (value: any) => {
  if(value){
    return (parseFloat(value) * 100).toFixed(2)+"%"
  }else{
    return '';
  }
};

export const timeAgo = (current: Date, date: Date) => {
  var seconds = Math.floor(
    (current?.getTime() - new Date(date).getTime()) / 1000
  );
  var interval = seconds / 31536000;
  if (interval > 1) return Math.floor(interval) + " years";
  interval = seconds / 2592000;
  if (interval > 1) return Math.floor(interval) + " months";
  interval = seconds / 86400;
  if (interval > 1) return Math.floor(interval) + " days";
  interval = seconds / 3600;
  if (interval > 1) return Math.floor(interval) + " hours";
  interval = seconds / 60;
  if (interval > 1) return Math.floor(interval) + " minutes";
  return Math.floor(seconds) + " seconds";
};

export const date_format = (timestamp: number | string) => {
  const dateObject = new Date(timestamp);

  let formatted_day: any = dateObject.getDate();
  if (formatted_day < 10) formatted_day = "0" + formatted_day;
  const time = dateObject.toTimeString().split(' ')[0].split(":");
  const hours = time[0];
  const minutes = time[1];
  const humanDateFormat = `${formatted_day} ${
    MONTHS[dateObject.getMonth()]
  } ${dateObject.getFullYear()} ${hours}:${minutes}`;
  return humanDateFormat;
};
