import { useEffect, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Export from "../Dashboard/Export";
import { IBusinessOverviewMetrics, IScenarios, useScenario } from "../../context/ScenarioContext";
import { format } from "../../utils/functions";
import BusinessBuildMatrix from "./BusinessBuildMatrix";
import BusinessSubsidy from "./BusinessSubsidy";
import BusinessFinancials from "./BusinessFinancials";
import axios from "axios";
import BASE_API_URL from '../../utils/baseUrl';
import { useOktaAuth } from "@okta/okta-react";
import MustBuildCbg from "./MustBuildCbg";
import ConfigurationModal from "../Dashboard/ConfigurationModal";


interface INavigationControls {
  zoomIn: () => void;
  zoomOut: () => void;
}

const NavigationControls = ({ zoomIn, zoomOut }: INavigationControls) => {
  return (
    <Paper
      sx={{
        backgroundColor: "#121212",
        marginBottom: 2.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #fff",
          borderRadius: 1,
        }}
      >
        <IconButton onClick={() => zoomIn()} size="medium">
          <Add sx={{ color: "#fff" }} />
        </IconButton>
        <Divider
          sx={{
            height: "1px",
            backgroundColor: "#fff",
            margin: "0 0.5em",
          }}
        />
        <IconButton onClick={() => zoomOut()} size="medium">
          <Remove sx={{ color: "#fff" }} />
        </IconButton>
      </Box>
    </Paper>
  );
};

interface IOverviewProps {
  closeModal?: () => void;
  navigateToClusterPage?: () => void;
  navZoomIn?: () => void;
  navZoomOut?: () => void;
  scenario?: IScenarios;
  cbgDataList : any[]
  setCbgDataList: React.Dispatch<React.SetStateAction<any[]>>
  refreshData: boolean
  setRefreshData: (toggle:boolean)=>void
  setIsLoadingCBGs : (id:boolean) => void
  isLoadingCBGs : boolean
  setPendingScenariosToRun : (ids:string[]) => void;
  pendingScenariosToRun: string[]
  updatePendingScenariosRuns: (scenarioId : string) =>void
}

const BusinessCaseOverviewSummary = ({
  closeModal,
  navZoomIn,
  navZoomOut,
  scenario,
  cbgDataList,
  setCbgDataList,
  refreshData,
  setRefreshData,
  setIsLoadingCBGs,
  isLoadingCBGs,
  setPendingScenariosToRun,
  pendingScenariosToRun,
  updatePendingScenariosRuns
}: IOverviewProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);
  const [adjusted_bead_funding, setAdjustedBeadFunding] = useState<number>(scenario?.adjusted_bead_funding ?? 0);

  const { getBusinessOverviewMetrics, setOpenedScenario, isRunning, setIsRunning } = useScenario();

  const uppercaseFirstLetter = (str: string) => {
    if (!str) {
      return str;
    }
    const lower = str.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
  };

  let name = scenario?.scenario_name || "";
  let scenario_id = scenario?.id as string;
  let county = `${scenario?.state?.toUpperCase()}-${uppercaseFirstLetter(
    scenario?.county_name ?? ""
  )}` as string;

  const [businessOverviewMetrics, setBusinessOverviewMetrics] = useState<IBusinessOverviewMetrics>({
    avg_cablefeetperhome: 0,
    total_sfus: 0,
    total_mdus: 0,
    total_households: 0,
    avg_percent_fiber: 0,
    avg_irr: 0,
    avg_terminal_penetration: 0,
    total_bead_eligible: 0,
    average_cost_per_home: 0,
    average_subsidised_cost: 0,
    total_subsidy_requested: 0,
    percent_eligible_homes: 0,
    avg_cablefeetperhome_all: 0,
    total_households_all: 0,
    avg_irr_all: 0,
    avg_terminal_penetration_all: 0,
    total_bead_eligible_all: 0,
    avg_cablefeetperhome_clustered: 0,
    total_households_clustered: 0,
    avg_irr_clustered: 0,
    avg_terminal_penetration_clustered: 0,
    total_bead_eligible_clustered: 0,
    net_costperhome_all: 0,
    upfront_costperhome_all: 0,
    requested_subsidyperhome_all: 0,
    total_cap_without_sub_all: 0,
    total_cap_with_sub_all: 0,
    effective_subsidy_all: 0,
    net_costperhome_clustered: 0,
    upfront_costperhome_clustered: 0,
    requested_subsidyperhome_clustered: 0,
    total_cap_without_sub_clustered: 0,
    total_cap_with_sub_clustered: 0,
    effective_subsidy_clustered: 0,
    avg_backhaul_cost_per_cbg_clustered: 0,
    avg_backhaul_cost_per_cbg: 0,
    y0: 0,
    y1: 0,
    y2: 0,
    y3: 0,
    y4: 0,
    y5: 0,
    y6: 0,
    y7: 0,
    y8: 0,
    y9: 0,
    y10: 0,
    y11: 0,
    y12: 0,
    y13: 0,
    y14: 0,
    y15: 0,
    y16: 0,
    y17: 0,
    y18: 0,
    y19: 0,
    y20: 0,
  });

  const formatToPercentage = (value: number) => {
    return `${(value * 100).toFixed(2)}%`;
  };
  const formatToDollars = (value: number) => {
    return `$${format(value)}`;
  };

  const { authState } = useOktaAuth();
  const config = {
      headers: {
          Authorization: 'Bearer ' + authState?.idToken?.idToken,
          "Content-Type": "application/json"
      }
  };

  useEffect(() => {
    if (scenario?.id) {
      setIsLoading(true);
      getBusinessOverviewMetrics(scenario?.id)
        .then((metrics) => {
          setIsLoading(false);
          setBusinessOverviewMetrics(metrics as IBusinessOverviewMetrics);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  useEffect(() => {
      setIsLoadingCBGs(true);
      axios.get(`${BASE_API_URL}clustering/must_build_areas?scenario_id=${scenario_id}`, config).then((response) =>{
          setCbgDataList([...response.data].filter(Boolean));
          setIsLoadingCBGs(false);
      }).catch((error) => {
        setIsLoadingCBGs(false);
      });
}, []);


  const calculatePercentageDifference = (x: number, y: number) => {
    return x - y;
  }
  const calculateDifference = (x: number, y:number) => {
    return y>0 ? y/x : 0;
  }

  const buildMetrics = [
    {
      item: "Total households",
      market_opportunity: businessOverviewMetrics.total_households_all,
      build_output: businessOverviewMetrics.total_households_clustered,
      difference: formatToPercentage(calculateDifference(businessOverviewMetrics.total_households_all, businessOverviewMetrics.total_households_clustered)),
    },
    {
      item: "Eligible households",
      market_opportunity: businessOverviewMetrics.total_bead_eligible_all,
      build_output: businessOverviewMetrics.total_bead_eligible_clustered,
      difference: formatToPercentage(calculateDifference(businessOverviewMetrics.total_bead_eligible_all, businessOverviewMetrics.total_bead_eligible_clustered)),
    },
    {
      item: "Feet per household",
      market_opportunity: `${format(businessOverviewMetrics.avg_cablefeetperhome_all)}ft/h`,
      build_output: `${format(businessOverviewMetrics.avg_cablefeetperhome_clustered)}ft/h`,
      difference: formatToPercentage(calculateDifference(businessOverviewMetrics.avg_cablefeetperhome_all, businessOverviewMetrics.avg_cablefeetperhome_clustered)),
    },
    {
      item: "Market take-up rate",
      market_opportunity: formatToPercentage(businessOverviewMetrics.avg_terminal_penetration_all),
      build_output: formatToPercentage(businessOverviewMetrics.avg_terminal_penetration_clustered),
      difference: formatToPercentage(calculatePercentageDifference(businessOverviewMetrics.avg_terminal_penetration_all, businessOverviewMetrics.avg_terminal_penetration_clustered)),
    },
  ];

  const houseHoldData = [
    {
      item: "Upfront cost per home",
      market_opportunity: formatToDollars(businessOverviewMetrics.upfront_costperhome_all),
      build_output: formatToDollars(businessOverviewMetrics.upfront_costperhome_clustered),
      difference: formatToPercentage(calculateDifference(businessOverviewMetrics.upfront_costperhome_all, businessOverviewMetrics.upfront_costperhome_clustered)),
    },
    {
      item: "Requested subsidy per home",
      market_opportunity: formatToDollars(businessOverviewMetrics.requested_subsidyperhome_all),
      build_output: formatToDollars(businessOverviewMetrics.requested_subsidyperhome_clustered),
      difference: formatToPercentage(calculateDifference(businessOverviewMetrics.requested_subsidyperhome_all, businessOverviewMetrics.requested_subsidyperhome_clustered)),
    },
    {
      item: "Net cost per home",
      market_opportunity: formatToDollars(businessOverviewMetrics.net_costperhome_all),
      build_output: formatToDollars(businessOverviewMetrics.net_costperhome_clustered),
      difference: formatToPercentage(calculateDifference(businessOverviewMetrics.net_costperhome_all, businessOverviewMetrics.net_costperhome_clustered)),
    },
  ];

  const marketData = [
    {
      item: "Capital without subsidy",
      market_opportunity: formatToDollars(businessOverviewMetrics.total_cap_without_sub_all),
      build_output: formatToDollars(businessOverviewMetrics.total_cap_without_sub_clustered),
      difference: formatToPercentage(calculateDifference(businessOverviewMetrics.total_cap_without_sub_all, businessOverviewMetrics.total_cap_without_sub_clustered)),
    },
    {
        item: "Effective subsidy",
        market_opportunity: formatToDollars(businessOverviewMetrics.effective_subsidy_all),
        build_output: formatToDollars(businessOverviewMetrics.effective_subsidy_clustered),
        difference: formatToPercentage(calculateDifference(businessOverviewMetrics.effective_subsidy_all, businessOverviewMetrics.effective_subsidy_clustered)),
      },
    {
      item: "Capital subsidy",
      market_opportunity: formatToDollars(businessOverviewMetrics.total_cap_with_sub_all),
      build_output: formatToDollars(businessOverviewMetrics.total_cap_with_sub_clustered),
      difference: formatToPercentage(calculateDifference(businessOverviewMetrics.total_cap_with_sub_all, businessOverviewMetrics.total_cap_with_sub_clustered)),
    },
  ];

  const financials = [
    {
      item: "Internal rate of returns (IRR)",
      market_opportunity: formatToPercentage(businessOverviewMetrics.avg_irr_all),
      build_output: formatToPercentage(businessOverviewMetrics.avg_irr_clustered),
      difference: formatToPercentage(calculatePercentageDifference(businessOverviewMetrics.avg_irr_all, businessOverviewMetrics.avg_irr_clustered)),
    },
  ];

  // Backhaul Cost Per CBG
  const backhaulCostPerCBGData = {
    avg_backhaul_cost_per_cbg: formatToDollars(businessOverviewMetrics.avg_backhaul_cost_per_cbg),
    avg_backhaul_cost_per_cbg_clustered: formatToDollars(businessOverviewMetrics.avg_backhaul_cost_per_cbg_clustered),
    difference: formatToPercentage(calculateDifference(businessOverviewMetrics.avg_backhaul_cost_per_cbg, businessOverviewMetrics.avg_backhaul_cost_per_cbg_clustered)),
  };

  // Graph Data
  const marketWideData = [ businessOverviewMetrics.total_bead_eligible_all, businessOverviewMetrics.total_households_all ];
  const suggestedBuildAreaData = [ businessOverviewMetrics.total_bead_eligible_clustered, businessOverviewMetrics.total_households_clustered ];

  const subsidyHomeData = [ businessOverviewMetrics.total_cap_without_sub_all, businessOverviewMetrics.effective_subsidy_all, businessOverviewMetrics.total_cap_with_sub_all]
  const subsidyMarketData = [ businessOverviewMetrics.total_cap_without_sub_clustered, businessOverviewMetrics.effective_subsidy_clustered, businessOverviewMetrics.total_cap_with_sub_clustered]

  // Financials
  const yearFCF = [
    businessOverviewMetrics.y0,
    businessOverviewMetrics.y1,
    businessOverviewMetrics.y2,
    businessOverviewMetrics.y3,
    businessOverviewMetrics.y4,
    businessOverviewMetrics.y5,
    businessOverviewMetrics.y6,
    businessOverviewMetrics.y7,
    businessOverviewMetrics.y8,
    businessOverviewMetrics.y9,
    businessOverviewMetrics.y10,
  ];

  const handleCbgRun = async () => {
    setIsRunning(true);
    try{
        await axios.post(`${BASE_API_URL}clustering/execute_must_build_areas`, { scenario_id, county_id:scenario?.county_id }, config);
        const {data} = await axios.get(`${BASE_API_URL}scenario?id=${scenario_id}`, config);
        if(data){
          setAdjustedBeadFunding(data[0].adjusted_bead_funding);
          setPendingScenariosToRun(pendingScenariosToRun.filter(pending => pending!== scenario_id))
        }
    }catch(e){
        console.log(e);
    } finally{
        setRefreshData(!refreshData)
        setIsRunning(false);
    }
}

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#121212",
          position: "fixed",
          right: 0,
          top: 0,
        }}
      >
        <IconButton
          onClick={() => setIsOpen(!isOpen)}
          color="primary"
          size="large"
        >
          {!isOpen ? <ChevronLeftIcon /> : <></>}
        </IconButton>
      </Box>
      {
        <Fade in={isOpen}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              gap: "10px",
              height: "100%",
            }}
          >
            <NavigationControls
              zoomIn={() => navZoomIn?.()}
              zoomOut={() => navZoomOut?.()}
            />
            <Paper
              sx={{
                backgroundColor: "#121212",
                minWidth: "30vw",
                display: isOpen ? "flex" : "none",
                flexDirection: "column",
                paddingBottom: 4,
                justifyContent: "space-between",
                paddingX: 3,
                paddingTop: 1,
                right: 0,
                height: "100%",
                color: "#fff",
                borderRadius: 0,
                overflowY: "auto",
              }}
              elevation={3}
            >
              <Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <IconButton
                    onClick={() => setIsOpen(!isOpen)}
                    color="primary"
                  >
                    {!isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                  </IconButton>
                  <IconButton onClick={() => {
                      setOpenedScenario('')
                      closeModal?.()
                    }} color="primary">
                    <HighlightOffIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ height: "1px", backgroundColor: "white" }} />
                <Box
                  sx={{ paddingTop: "2px", paddingBottom: "1px" }}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box sx={{ marginTop: "5px" }}>
                    <Typography alignSelf="center" fontWeight="900">
                      Business Case Overview
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      aria-label="expand"
                      color="inherit"
                      size="medium"
                      onClick={() => {}}
                      sx={{ padding: "1px" }}
                    >
                      <TuneIcon
                        sx={{
                          color: "#0057B8",
                          height: "1.5em",
                          width: "1.5em",
                        }}
                        fontSize="inherit"
                      />
                    </IconButton>
                  </Box>
                </Box>
                <Box>
                  <Typography>Scenario name: {name}</Typography>
                </Box>
                <Box>
                  <Typography>County: {county}</Typography>
                </Box>
                <Box
                  sx={{
                    paddingBottom: "5px",
                    paddingTop: "5px",
                  }}
                >
                  { (scenario?.is_bead_funding ? 1 : 0) === 1 ?
                    <Typography>
                      <Stack flexDirection="row" gap="4px" alignItems="center">
                        <span>Required BEAD funding to build what's in blue</span>
                        <span style={{background: "#349BD3", display: "inline-block", width: "16px", height: "16px"}}></span>:
                        <span>${adjusted_bead_funding} (Assumed BEAD Funding)</span>
                      </Stack>
                    </Typography> : null
                  }
                </Box>
                {
                  isLoading ? <CircularProgress /> :<Box>
                    <BusinessBuildMatrix
                      title="Build Matrix"
                      items={buildMetrics}
                      marketWideData={marketWideData}
                      suggestedBuildAreaData={suggestedBuildAreaData}
                    />
                    <BusinessSubsidy
                      title="Subsidy Needs"
                      houseHoldData={houseHoldData}
                      marketData={marketData}
                      subsidyHomeData={subsidyHomeData}
                      subsidyMarketData={subsidyMarketData}
                      backhaulCostPerCBG={backhaulCostPerCBGData}
                    />
                    <BusinessFinancials
                      title="Financials"
                      items={financials}
                      yearFCF={yearFCF}
                    />
                    {
                      scenario?.adjusted_bead_funding &&
                      <MustBuildCbg
                        title="Must Build CBG List (click map to add / remove)"
                        cbgDataList={cbgDataList}
                        isLoadingCBGs={isLoadingCBGs}
                        setIsLoadingCBGs={setIsLoadingCBGs}
                        scenarioId={scenario_id}
                        setCbgDataList={setCbgDataList}
                        updatePendingScenariosRuns={updatePendingScenariosRuns}
                      />
                    }
                  </Box>
                }
              </Box>
              <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-end'}}>
                {
                    (scenario?.is_bead_funding ? 1 : 0) === 1 ? (pendingScenariosToRun.indexOf(scenario_id) !==-1  &&
                    <Alert severity="warning">Please rerun your model, the must build areas has been updated</Alert>): null
                }
                <Box sx={{display:'flex', flexDirection:'row', justifyContent:"space-between"}}>
                  <Button
                          onClick={() => setIsModalOpen(true)}
                          size="medium"
                          sx={{color: 'white', fontWeight: 900, margin:'10px 2px' }}
                          variant='contained'
                      >
                          Configure Assumptions
                  </Button>
                  { (scenario?.is_bead_funding ? 1 : 0) === 1 ?
                    <Button
                            size="medium"
                            sx={{color: 'white', width:'250px', fontWeight: 900, margin:'10px 2px', "&.Mui-disabled": {
                              background: "#eaeaea",
                              color: "#c0c0c0"
                            } }}
                            variant='contained'
                            disabled={isRunning || cbgDataList.length===0}
                            onClick={handleCbgRun}
                        >
                            Rerun model and calculate subsidy need
                            {isRunning && <IconButton><CircularProgress sx={{ color: "#029FDB" }} size={25} /> </IconButton>}
                    </Button> : null
                  }
                </Box>
                <Divider
                  sx={{
                    height: "1px",
                    backgroundColor: "white",
                    marginBottom: "5px",
                    marginTop: "5px",
                  }}
                />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  minWidth="22vw"
                  bottom={0}
                >
                  <Button
                    onClick={() => closeModal?.()}
                    size="medium"
                    sx={{ color: "white", fontWeight: 900 }}
                    variant="text"
                  >
                    <ArrowBack fontSize="small" />
                    Back to dashboard
                  </Button>
                  {scenario_id && (
                    <Export
                      name={name}
                      scenario_id={scenario_id as string}
                      variant="map_view"
                    />
                  )}
                </Box>
              </Box>
            </Paper>
            {
              isModalOpen && (
                <Modal
                  open={isModalOpen}
                  onClose={() => {
                    setIsModalOpen(false);
                  }}
                >
                  <>
                    <ConfigurationModal
                      closeModal={() => setIsModalOpen(false)}
                      scenario={scenario}
                      detailsOpen={true}
                      sectionFrom="side_panel"
                      />
                  </>
                </Modal>
              )
            }
          </Box>
        </Fade>
      }
    </>
  );
};

export default BusinessCaseOverviewSummary;
