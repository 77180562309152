import { Alert, Snackbar } from '@mui/material';
import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';
import BASE_API_URL from '../utils/baseUrl';
import { MONTHS } from '../utils/constant';
// Authentication
import { useOktaAuth } from "@okta/okta-react";

const ExportContext = createContext<IExportState | undefined>(undefined);

interface IExportProvder {
    children: JSX.Element
}

export interface ISummary {
    scenario_id?: string,
    address_index?: string,
    latitude?: string | number,
    longitude?: string | number,
    address?: string,
    city?: string,
    county?: string,
    country?: string
    number_of_units?: string | string,
    classifications?: string,
    build_status?: string,
    irr?: string | number
}


interface IExportState {
    summary_view: ISummary[],
    getSummaryView: (id: string) => void,
    getAggregatedView: (id: string) => void,
    aggregated_view: any, // TODO,
    downloadSummaryView: (id: string, only_clustered: boolean, name: string, columns: string[]) => void,
    downloadAddressView: (id: string, only_clustered: boolean, name: string, columns: string[]) => void,
    loading: boolean,
    isDownloading: boolean
}

const ExportProvider = ({ children }: IExportProvder) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ summary, setSummary ] = useState([]);
    const [ aggregated, setAggregated ] = useState([]);
    const [ showNotification, setShowNotification ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ alertType, setAlertType ] = useState<"info" | "error" | "warning" | "success">('info');
    const [ isDownloading, setIsDownloading ] = useState(false);
    const { authState } = useOktaAuth();

    const downloadSummaryView = async (id: string, only_clustered: boolean, name: string = 'download', columns: string[] | string = '*') => {
        setIsDownloading(true);
        try {
            if (authState?.idToken?.idToken) {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + authState?.idToken?.idToken,
                    }
                };
                setAlertType("success")
                setShowNotification(true);
                setMessage("Downloading Summary Data");
                const param = only_clustered ? `&only_clustered`: "";
                const response = await axios.post(`${BASE_API_URL}scenario/summary/download?id=${id}${param}`, {columns}, {
                    responseType: "blob",
                    ...config,
                });
                const file = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');

                link.href = file;
                const today = new Date();
                const yyyy = today.getFullYear();
                let mm: any = today.getMonth();
                let dd: any = today.getDate();

                if (dd < 10) dd = '0' + dd;
                const formattedToday = `${dd}_${MONTHS[mm]}_${yyyy}`;

                link.setAttribute('download', `${name}-Summary-${formattedToday}.csv`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(file);
            }
        } catch (error) {
            setAlertType("error")
            setShowNotification(true);
            setMessage("Error downloading summary");
        } finally {
            setIsDownloading(false);
        }
    }

    const downloadAddressView = async (id: string, only_clustered: boolean, name: string = 'download', columns: string[] | string = '*') => {
        setIsDownloading(true);
        try {
            if (authState?.idToken?.idToken) {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + authState?.idToken?.idToken,
                    }
                };

                setAlertType("success")
                setShowNotification(true);
                setMessage("Downloading Addresses Data")

                const param = only_clustered ? `&only_clustered` : "";
                const response = await axios.post(`${BASE_API_URL}scenario/aggregated/download?id=${id}${param}`, {columns}, {
                    responseType: "blob", ...config
                });

                const file = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');

                link.href = file;
                const today = new Date();
                const yyyy = today.getFullYear();
                let mm: any = today.getMonth() + 1; // Months start at 0!
                let dd: any = today.getDate();

                if (dd < 10) dd = '0' + dd;
                // if (mm < 10) mm = '0' + mm;

                const formattedToday = `${dd}_${MONTHS[mm]}_${yyyy}`

                link.setAttribute('download', `${name}-Addresses-${formattedToday}.csv`); // @TODO: make extendable for more extentions
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(file);
            }
            //window.location.assign(file);
        } catch (error) {
            setAlertType("error")
            setShowNotification(true);
            setMessage("Error downloading address view");
        } finally {
            setIsDownloading(false);
        }
    }

    const getSummaryView = async (id: string) => {
        setIsLoading(true);
        try {
            if (authState?.idToken?.idToken) {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + authState?.idToken?.idToken,
                    }
                };
                const res = await axios.get(`${BASE_API_URL}scenario/summary?id=${id}`, config);
                setSummary(res.data.map((item: any) =>{
                    return {
                        ...item,
                        id: item.geoid
                    }
                }))
            }
        } catch (error) {
            setAlertType("error")
            setShowNotification(true);
            setMessage("Error fetching Summary View");
        } finally {
            setIsLoading(false);
        }
    }

    const getAggregatedView = async(id: string) => {
        setIsLoading(true);
        try {
            if (authState?.idToken?.idToken) {
                const config = {
                    headers: {
                        Authorization: 'Bearer ' + authState?.idToken?.idToken,
                    }
                };
                const res = await axios.get(`${BASE_API_URL}scenario/aggregated?id=${id}`, config);
                setAggregated(res.data.map((item: any) =>{
                    return {
                        ...item,
                        id: item.geoid
                    }
                }))
            }
        } catch (error) {
            setAlertType("error")
            setShowNotification(true);
            setMessage("Error getting Addresses Summary");
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <ExportContext.Provider
            value={{
                summary_view: summary,
                getSummaryView,
                getAggregatedView,
                aggregated_view: aggregated,
                downloadSummaryView,
                downloadAddressView,
                loading: isLoading,
                isDownloading
            }}
        >
            {children}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                autoHideDuration={5000}
                open={showNotification}
                onClose={() => {
                    setShowNotification(false);
                }}
                message={message}
            >
                <Alert severity={alertType}>{message}</Alert>
            </Snackbar>
        </ExportContext.Provider>
    )
}


const useExport = () => {
    const context = useContext(ExportContext);
    if(context === undefined) {
        throw new Error("useExport can only used inside BusinessOverviewProvider")
    }

    return context;
}

export {
    ExportProvider,
    useExport
};
