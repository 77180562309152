import AccountCircle from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { UserClaims } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AccountMenu from "./AccountMenu";
import AddScenario from "./AddSenario";

const DashboardOptions = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims | undefined>(undefined);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(undefined);
    } else {
      setUserInfo(authState.idToken?.claims);
    }
  }, [authState, oktaAuth]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    oktaAuth.signOut();
    navigate("/", { replace: true });
  };

  return (
    <Box sx={{ display: { xs: "none", md: "flex", color: "#797C96" } }}>
      <AddScenario variant="button" />
      <IconButton
        onClick={handleClick}
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <AccountMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        name={userInfo?.name ?? ""}
        handleLogout={handleLogout}
      />
    </Box>
  );
};

export default DashboardOptions;
