import React, {
    createContext,
    ReactNode,
    useContext,
    useState,
    useEffect,
    useMemo,
    useReducer,
} from 'react';
import { AppReducer, initialAppState } from './AppReducer';

// interface AppContextInterface ?
//     scenarioId: string;
//     cashFlowRunId: string;
//     clusteringId: string;
//     costToPassRunId: string;
//     fibrePathId: string;

type InitialStateType = {
    state: any;
    dispatch: React.Dispatch<any> | null;
};

type AppContextType = {
    user: boolean | null;
    contextValue: InitialStateType;
    login: () => void;
    logout: () => void;
};

const appStateDefaultValues: AppContextType = {
    user: null,
    contextValue: {
        state: null,
        dispatch: null
    },
    login: () => {},
    logout: () => {}
};

// Create & use Context
const AppContext = createContext<AppContextType | null>(appStateDefaultValues);

export function useAppContext() {
    return useContext(AppContext);
};

type ProviderProps = {
    children: ReactNode;
};

export const AppProvider = ({ children }: ProviderProps) => {
    const [ state, dispatch ] = useReducer(AppReducer, initialAppState);

    useEffect(() => {
        const lsObject = JSON.parse(localStorage.getItem('bain-app-state') || '{}');

        if (lsObject) {
            dispatch({
                type: 'local_storage',
                value: JSON.parse(localStorage.getItem('bain-app-state')!)
            });
        };
    }, []);

    useEffect(() => {
        if (state !== initialAppState) {
            localStorage.setItem('bain-app-state', JSON.stringify(state))
        };
    }, [state]);

    const [ user, setUser ] = useState<boolean | null>(null);

    const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

    const login = () => setUser(true);
    const logout = () => setUser(false);

    const value = {
        user,
        contextValue,
        login,
        logout,
    };

    return (
        <>
            <AppContext.Provider value={value}>
                {children}
            </AppContext.Provider>
        </>
    );
};
