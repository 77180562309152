import { useNavigate, useRoutes } from "react-router-dom";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

import router from "./router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import oktaConfig from "./helpers/constants/authentication";

const oktaAuth = new OktaAuth(oktaConfig(`${window.location.origin}/login/callback`).oidc);

function App() {
  const content = useRoutes(router);
  const navigate = useNavigate();

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    navigate(toRelativeUrl(originalUri, window.location.origin), {
      replace: true,
    });
  };

  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <Security
          oktaAuth={oktaAuth}
          restoreOriginalUri={restoreOriginalUri}
        >
          {content}
        </Security>
      </div>
    </QueryClientProvider>
  );
}

export default App;
