// TODO: @=> Change these exports to enums for better access.
// APP TEXT
export const DASHBOARD_TITLE = '';

// PAGES TITLE
export const PAGE_TITLE_HOME = '';
export const CASHFLOW_MODEL = 'CASH FLOW POC';
export const NEW_SCENARIO = 'NEW SCENARIO';

// MODAL TITLES
export const MODAL_CASHFLOW = 'Configure Cashflow';
export const CONFIGURE_DATASET = 'Configure datasets';
export const CONFIGURE_FIBRE_PATH = 'Configure fibre path algorithm';
export const CONFIGURE_CASHFLOWS = 'Configure cashflows';
export const CONFIGURE_CLUSTERING = 'Clustering Configuration';

// UI CONSTANTS

// HEADER height and footer height.
export const DRAWER_WIDTH = 350;

// New Scenario progress stepper steps
export const steps = ['', '', '', ''];


// ENDPOINTS
// @TODO: Use process.env.dev to toggle between URL or get url from env
// export const BASE_API_URL = 'http://localhost:5000/api/v1/'

export const BUSINESS_OVERVIEW_ENDPOINT = 'business-overview/output'
// CashFlow Constants

export const tableInputs = [
    {
        name: 'cbgId',
        label: 'CBGID',
        value: '',
    },
    {
        name: 'state',
        label: 'State',
        value: '',
    },
    {
        name: 'county',
        label: 'County',
        value: '',
    },
    {
        name: 'municipality',
        label: 'Municipality',
        value: '',
    },
    {
        name: 'TotalSFUHHs',
        label: 'Total SFU HHs',
        value: 15,
    },
    {
        name: 'TotalMDUHHs',
        label: 'Total MDU HHs',
        value: 0,
    },
    {
        name: 'totalHHs',
        label: 'Total HHs',
        value: 15,
    },
    {
        name: 'percentageFiber',
        label: '% Fiber',
        value: '0%',
    },
    {
        name: 'terminalPenetration',
        label: 'Terminal Penetration',
        value: '31,33%',
    },
    {
        name: 'cableFeetPerHome',
        label: 'Cable feet per home',
        value: 63,
    },
];

export const globalInputs = [
    {
        name: 'timeToTerminalPenetration',
        label: 'Time to Terminal Penetration (yrs)',
        value: 5,
    },
    {
        name: 'cablePerFoot',
        label: 'Cable per foot',
        value: 'US$31',
    },
    {
        name: 'fixedCostPerHome',
        label: 'Fixed Cost Per Home',
        value: 'US$31',
    },
];


export const MONTHS = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];