import React, { memo, useEffect, useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    Modal,
    Paper,
    Stack,
    Tab,
    Tabs,
    Tooltip,
    Typography
} from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridColumnHeaderParams
} from '@mui/x-data-grid';
import {
    ExportProvider,
    useExport
} from '../../context/ExportContext';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { toPercent } from '../../utils/functions';

const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box component="div" sx={{ p: 0 }}>
                 {children}
            </Box>
            )}
        </div>
    );
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1600,
    border: '2px solid grey',
    boxShadow: 24,
    p: 4,
};

const Export = ({ scenario_id, name,  variant = "card_view"}: { scenario_id: string, name: string, variant: "card_view" | "map_view"}) => {
    const [ isOpen, setIsOpen ] = useState<boolean>(false);
    const [ onlyClustered, setOnlyClustered] = useState<boolean>(false);
    const [ value, setValue] = useState(0);
    const { loading, getSummaryView, aggregated_view ,getAggregatedView, summary_view, downloadSummaryView, downloadAddressView, isDownloading } = useExport();
    const [ selectedColumns , setSelectedColumns ] = useState<Record<string, any>[]>([]);

    const selectHeaders = (params: GridColumnHeaderParams) => (
        <div className='MuiDataGrid-columnHeaderTitle'
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            
                <Checkbox
                    size='small'
                    sx={{paddingLeft:'0px'}}
                    onChange={() => setSelectedArray(params.field , params.colDef.headerName as string)}
                    onClick={(event) => event.stopPropagation()}
                    onKeyDown={(event) => event.stopPropagation()}
                />
                <Tooltip title={`${params.colDef.headerName}`}>
           <Box>{params.colDef.headerName}</Box>
            
            </Tooltip>
        </div>
    )

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Address Index',
            width: 150,
            renderHeader: selectHeaders,
        },
        {
            field: 'lat',
            headerName: 'Latitude',
            width: 160,
            renderHeader: selectHeaders,
        },
        {
            field: 'lon',
            headerName: 'Longitude',
            width: 160,
            renderHeader: selectHeaders,
        },
        {
            field: 'address',
            headerName: 'Address',
            width: 230,
            renderHeader: selectHeaders,
        },
        {
            field: 'city',
            headerName: 'City',
            width: 160,
            renderHeader: selectHeaders,
        },
        {
            field: 'country',
            headerName: 'Country',
            width: 160,
            renderHeader: selectHeaders,
        },
        {
            field: 'res_units',
            headerName: 'Unit',
            width: 160,
            renderHeader: selectHeaders,
        },
        {
            field: 'bain_use',
            headerName: 'Classification',
            width: 160,
            renderHeader: selectHeaders,
        },
        {
            field: 'derived',
            headerName: 'Build Status',
            width: 160,
            renderHeader: selectHeaders,
        },
        {
            field: 'irr',
            headerName: 'IRR',
            width: 160,
            renderHeader: selectHeaders,
            renderCell: (params) => {
                return `${toPercent(params.value)}`;
            },
        },
    ];

    const aggregatedColumns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Geoid',
            width: 160,
            renderHeader: selectHeaders,
        },
        {
            field: 'scenario_name',
            headerName: 'Scenario',
            width: 160,
            renderHeader: selectHeaders,
        },
        {
            field: 'county_name',
            headerName: 'County',
            width: 115,
            renderHeader: selectHeaders,
        },
        {
            field: 'country',
            headerName: 'Country',
            width: 115,
            renderHeader: selectHeaders,
        },
        {
            field: 'percent_fiber',
            headerName: 'Fiber Percent',
            width: 160,
            renderHeader: selectHeaders,
            renderCell: (params) => {
                return `${toPercent(params.value)}`;
            },
        },
        {
            field: 'cablefeetperhome',
            headerName: 'Feet Per House',
            width: 160,
            renderHeader: selectHeaders,
            renderCell: (params) => {
                return `${parseFloat(params.value).toFixed(2)}`;
            },
        },
        {
            field: 'irr',
            headerName: 'IRR',
            width: 110,
            renderHeader: selectHeaders,
            renderCell: (params) => {
                return `${toPercent(params.value)}`;
            },
            
        },
        {
            field: 'terminal_penetration',
            headerName: 'Terminal Penetration',
            width: 170,
            renderHeader: selectHeaders,
            renderCell: (params) => {
                return `${toPercent(params.value)}`;
            },
        },
        {
            field: 'cable_footage',
            headerName: 'Total Fiber Length',
            width: 160,
            renderHeader: selectHeaders,
            renderCell: (params) => {
                return `${parseFloat(params.value).toFixed(2)}`;
            },
        },
        {
            field: 'total_hhs',
            headerName: 'Total HHS',
            width: 115,
            renderHeader: selectHeaders,
        },
        {
            field: 'total_mdus',
            headerName: 'Total MDUS',
            width: 115,
            renderHeader: selectHeaders,
        },
        {
            field: 'total_sfus',
            headerName: 'Total SFUS',
            width: 115,
            renderHeader: selectHeaders,
        },
        {
            field: 'derived',
            headerName: 'Build Status',
            width: 160,
            renderHeader: selectHeaders,
        },
        {
            field: 'bead_eligible',
            headerName: 'BEAD Eligible',
            width: 125,
            renderHeader: selectHeaders,
        },
    ]

    useEffect(() => {
        if(isOpen) {
            getSummaryView(scenario_id);
            getAggregatedView(scenario_id);
        }
    }, [isOpen]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedColumns([])
        setValue(newValue);
    };

    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const setSelectedArray = (columnName: string, columnLabel: string) => {
        columnName = columnName === 'id' ? 'geoid' : columnName;
        const indexToRemove = selectedColumns.findIndex((column) => column.columnName === columnName);
        // Remove the item if column was already selected. For export list.
        if (indexToRemove !== -1) {
            const existingColumns = [...selectedColumns];
            existingColumns.splice(indexToRemove, 1);
            setSelectedColumns(existingColumns);
        } else {
            const newSelectedColumns: Record<string,any>[] = [...selectedColumns, { columnName, columnLabel }]
            setSelectedColumns(newSelectedColumns)
        }
    };

    return (
        <>
            {
                variant === "card_view" && (
                    <Button
                        onClick={() => setIsOpen(true)}
                        variant="outlined"
                        sx={{
                            borderColor: "#E4E6F4",
                            color: "#747579",
                            fontWeight: 400
                        }}
                    >
                        Export
                    </Button>
                )
            }
            {
                variant === "map_view" && (
                    <Button
                        startIcon={<DescriptionOutlinedIcon />}
                        onClick={() => setIsOpen(true)}
                        variant="contained"
                        color="info"
                        sx={{
                            backgroundColor: "#019BD9",
                            borderRadius: '5px'
                        }}
                    >
                        Export Business Case
                    </Button>
                )
            }
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <Paper sx={style}>
                    <IconButton onClick={() => setIsOpen(false)} style={{
                        background: 'white',
                        position: 'absolute',
                        top: 0,
                        right: 0
                    }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography color="text.secondary" sx={{textAlign: "center" }}>Business case output data</Typography>
                    <Divider sx={{ marginTop: '2%', marginBottom: '2%'}} />
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="sample CBG output data">
                            <Tab label="SUMMARY"  {...a11yProps(0)} />
                            <Tab label="ADDRESSES" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Box sx={{ height: 400, width: '100%' }}>
                            <DataGrid
                                sx={{
                                '.MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '.MuiDataGrid-columnHeaders': {
                                    backgroundColor: '#797C96',
                                    color: "#fff"
                                },
                                '.MuiDataGrid-cell' : {
                                    paddingLeft:'25px'
                                },
                                }}
                                loading={loading}
                                rows={summary_view}
                                columns={aggregatedColumns}
                                pageSize={10}
                                rowsPerPageOptions={[5]}
                                getRowId={(row: any) => row.id + row.scenario_name}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Box sx={{ height: 400, width: '100%' }}>
                            <DataGrid
                                sx={{
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                    },
                                    '.MuiDataGrid-columnHeaders': {
                                        backgroundColor: '#797C96',
                                        color: "#fff"
                                    },
                                    '.MuiDataGrid-cell' : {
                                        paddingLeft:'25px'
                                    },
                                }}
                                loading={loading}
                                rows={aggregated_view}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[5]}
                                getRowId={(row: any) => row.id + Math.random().toString(36).slice(2, 7)}
                            />
                        </Box>
                    </TabPanel>
                    <Alert severity="warning" sx={{marginTop: "16px"}}> The above table detail is just a preview of the data and please be aware that this export might take a few minutes to download</Alert>
                    <Box sx={{ marginTop: "2%"}} display="flex" justifyContent="space-between">
                        <Button onClick={() => setIsOpen(false)} color="inherit" variant="text">
                            Cancel
                        </Button>

                        <Stack direction="row">
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        onChange={(e: any) => setOnlyClustered(e.target.checked)}
                                        value={onlyClustered}
                                        size="small" defaultChecked />} label="Only export households included in final build cluster" />
                            </FormGroup>
                            <Button
                                disabled={isDownloading}
                                onClick={() => {
                                    if(value !== 0) {
                                        downloadAddressView(scenario_id, onlyClustered, name, selectedColumns.map(column => column.columnName));
                                    } else {
                                        downloadSummaryView(scenario_id, onlyClustered, name, selectedColumns.map(column => column.columnName));
                                    }
                                }}
                                startIcon={<DescriptionOutlinedIcon />}
                                size="medium"
                                variant="contained"
                                color="info" >
                                Export Report
                                {
                                    isDownloading && <CircularProgress sx={{ color: "white" }} size={15} />
                                }
                            </Button>
                        </Stack>
                    </Box>
                </Paper>
            </Modal>
        </>
    )
}

const withExportProvider = ({ scenario_id, variant, name }: { name: string, scenario_id: string, variant: "card_view" | "map_view" }) => {
    return (
        <ExportProvider>
            <Export name={name} scenario_id={scenario_id} variant={variant} />
        </ExportProvider>
    )
}

export default memo(withExportProvider);