import AddIcon from '@mui/icons-material/Add';
import { Avatar, Button } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const CreateScenarioCard = ({ onClick }: any) => {
  return (
    <Card onClick={onClick} variant="outlined">
      <Box
        component={Button}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minWidth="10vw"
        paddingTop="20%"
      >
        <Avatar sx={{ alignSelf: 'center' }}>
          <AddIcon />
        </Avatar>
        <CardActions sx={{ paddingTop: '10%'}}>
          <CardContent>
            <Typography sx={{ fontSize: 16, textAlign: 'center' }} color="primary" gutterBottom>
              Get Started.
            </Typography>
            <Typography sx={{ fontSize: 16, textAlign: 'center' }} color="primary" gutterBottom>
              Create a scenario
            </Typography>
          </CardContent>
        </CardActions>
      </Box>
    </Card>
  )
}

export default CreateScenarioCard;