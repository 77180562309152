import { useEffect, useState } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEventsOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { Box, Button, Divider, Stack, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ModelStatus } from '../../context/ConfigureContext';
import { IScenarios, useScenario } from '../../context/ScenarioContext';
import { date_format, format } from '../../utils/functions';
import Details from './Details';
import Export from './Export';
import ModelInputButton from './ModelInputButton';
import ModelOptions from './ModelOptions';

interface IScenarioCard {
    scenario: IScenarios
    openedScenario:string
}

const infoIconStyle = {
    color: 'primary.light',
    fontSize: '0.95rem',
    marginTop: "-2px"
};

const maybeConvertToPercentage = (item:  {
    value?: string;
    label: string;
    unit: string;
    isFloat?: boolean;
}) => {
    if(item.unit === "%" && item.isFloat && item.value) {
        return {
            ...item,
            value: (parseFloat(item.value) * 100)
        }
    }

    return item
}

const uppercaseFirstLetter = (str: string) => {
    if(!str) {
        return str
    }
    const lower = str.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
}

export interface IResults {
    total_bead_eligible: number;
    avg_cablefeetperhome: number;
    total_sfus: number;
    total_mdus: number;
    total_households: number;
    avg_percent_fiber: number;
    avg_irr: number;
    avg_terminal_penetration: number;
}


const ScenarioCard = ({ scenario, openedScenario }: IScenarioCard)  => {
    const { run_models } = useScenario();
    const [ irrValue, setIrrValue ] = useState<string>('');
    const [ totalHhsValue, setTotalHhsValue] = useState<string>('')
    const [ totalEligibleHomes, setTotalEligibleHomes] = useState<string>('')
    const [ terminalPenetration, setTerminalPenetration] = useState<string>('')
    const [ cableFeetPerHome, setCableFeetPerHome] = useState<string>('')
    const [ beadFundEnabled, setBeadFundEnabled ] = useState<boolean>(true)
    useEffect(() => {
        setIrrValue(scenario?.irr?? '');
        setTotalHhsValue(scenario?.total_hhs ?? '');
        setTotalEligibleHomes(scenario?.percent_eligible_homes ?? '');
        setTerminalPenetration(scenario?.terminal_penetration ?? '');
        setCableFeetPerHome(scenario.cablefeetperhome ?? '');
        setBeadFundEnabled(scenario?.is_bead_funding ?? true)
    }, [scenario, scenario.cash_flow_status, scenario.clustering_status]);

    const isReadyToRun = (scenario: IScenarios) => {
        if(scenario.clustering_status === ModelStatus.CONFIGURED && scenario.cash_flow_status === ModelStatus.CONFIGURED) {
            return true
        }
        return false;
    };
    const showDetail = (scenario.cash_flow_status ==='SUCCESSFUL_RUN' &&
    scenario.clustering_status==='SUCCESSFUL_RUN') || openedScenario === scenario.id;

    return (
        <Card sx={{ margin: 0, marginRight: 2, marginBottom: 2 }}>
            <CardHeader
                sx={{wordWrap:'break-word', width:'300px'}}
                action={
                    <>
                        { scenario.id && <ModelOptions name={scenario.scenario_name || ''} id={scenario.id} /> }
                    </>
                }
                subheader={scenario.scenario_name}
            />
            <Box
                display="flex"
                justifyContent="space-between"
                marginTop="0"
                paddingTop="0"
                paddingLeft="5%"
                paddingRight="5%"
            >
                <Typography variant="caption" color="text.secondary">
                    {scenario?.county_name ? `${scenario?.state?.toUpperCase()}-${uppercaseFirstLetter(scenario?.county_name)}`  : 'No County'}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    {scenario.created_at && date_format(scenario.created_at)}
                </Typography>
            </Box>
        <CardContent>
            <Box
                display="flex"
                justifyContent="flex-start"
            >
                <Typography variant="caption" color="text.secondary">
                    Model Configuration
                </Typography>
                <Tooltip title="Provide model configurations">
                    <IconButton size="small">
                        <InfoOutlined
                            fontSize="small"
                            sx={infoIconStyle}
                        />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box>
                <Stack direction="row" spacing={0.5}>
                    <ModelInputButton
                        type="competitive_status"
                        scenario={scenario}
                        icon={<EmojiEventsIcon sx={{ fontSize: '15px'}} />}
                    />
                    <ModelInputButton
                        type="cost_to_pass_status"
                        scenario={scenario}
                        icon={<RouteOutlinedIcon sx={{ fontSize: '15px'}}  />}
                    />
                    <ModelInputButton
                        type="cash_flow_status"
                        scenario={scenario}
                        beadFundEnabled={beadFundEnabled}
                        icon={<MonetizationOnOutlinedIcon sx={{ fontSize: '15px'}}  />}
                    />
                    <ModelInputButton
                        type="clustering_status"
                        scenario={scenario}
                        beadFundEnabled={beadFundEnabled}
                        icon={<HubOutlinedIcon sx={{ fontSize: '15px'}}  />}
                    />
                    <Tooltip title="Run configured models">
                        <span>
                            <Button
                                style={{
                                    minWidth: "50px",
                                    height: "40px"
                                }}
                                disabled={!isReadyToRun(scenario)}
                                onClick={ () => {
                                    if(scenario.id) {
                                        run_models(scenario.id, scenario?.county_id as string, beadFundEnabled)
                                    }
                                }}
                                color="success"
                                variant="outlined"
                            >
                                <PlayArrowOutlinedIcon  sx={{ fontSize: '15px'}} />
                            </Button>
                        </span>
                    </Tooltip>
                </Stack>
            </Box>
            <Divider style={{ paddingTop: 10, marginBottom: 10}} light />
            <Box
                display="flex"
                justifyContent="flex-start"
            >
                <Typography  variant="caption" color="text.secondary">
                    Metrics
                </Typography>
            </Box>
            <Box>
                <Stack direction="row" spacing={0.5}>
                    {
                        [
                            {
                                value: terminalPenetration,
                                label: "Estimated terminal penetration",
                                unit: "%",
                                isFloat: true,
                            },
                            {
                                value: cableFeetPerHome,
                                label: "Cable feet per house",
                                unit: "ft/h",
                            },
                            {
                                value: irrValue,
                                label: "Internal rate of return",
                                unit: "%",
                                isFloat: true,
                            },
                            {
                                value: totalHhsValue,
                                label: "Houses in build cluster",
                                unit: "",
                            },
                            {
                                value: ((scenario?.bead_funding ?? 0) !== 0) ? totalEligibleHomes: undefined,
                                label: "Eligible homes covered",
                                unit: "%",
                            }
                        ].map((itemData, key) => {
                            const item = maybeConvertToPercentage(itemData);
                            return (
                                <Tooltip title={item.label} key={key}>
                                <Button key={key}
                                sx={{
                                    margin: 0,
                                    padding: 0,
                                    fontSize: "10px",
                                    minWidth: "50px",
                                    height: "40px",
                                    backgroundColor: '#F8F9FD',
                                    border: '0px',
                                    color: '#029fdb'}} variant="outlined" >
                                    { item.value ? `${item.unit === "$" ? item.unit : ''}${format(parseFloat(item?.value as string || '0' ))}${item.unit !== "$" ?item.unit: ''}` : <PendingActionsIcon sx={{ fontSize: '15px', color: '#000000' }} /> }
                                </Button>
                            </Tooltip>
                            )
                        })
                    }
                </Stack>
            </Box>
        </CardContent>
        <CardActions style={{display: 'flex', justifyContent: showDetail ? 'space-between' : 'flex-end', paddingLeft: '5%',
                    paddingRight: '5%', paddingBottom: '5%'}} disableSpacing>
            { showDetail &&
                <Details
                    scenario={scenario}
                />
            }
            <Export name={scenario?.scenario_name || ''} scenario_id={scenario.id as string} variant="card_view" />
        </CardActions>
        </Card>
    );
}

export default ScenarioCard;