import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";

export const ProtectedRoute = ({ children }: any) => {
  const { oktaAuth, authState } = useOktaAuth();

  if (oktaAuth.isLoginRedirect()) {
    try {
      oktaAuth.handleRedirect();
    } catch (e) {
      console.log("error:", e);
    }
  } else if (!authState?.isAuthenticated) {
    const originalUri = toRelativeUrl(
      globalThis.location.href,
      globalThis.location.origin
    );
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.signInWithRedirect();
  } else {
    return children;
  }
};
