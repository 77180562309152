import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import axios from 'axios';
import { useOktaAuth } from "@okta/okta-react";
import BASE_API_URL from '../utils/baseUrl';
import { VegaParams } from '../@types/VegaParams';

export const useVega = (vegaParams?: VegaParams) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const { authState } = useOktaAuth();

    const getData = async(params: VegaParams) => {
        try {
            setLoading(true);
            const config = {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authState?.idToken?.idToken,
                }
            };

            const blobImage = await axios.post(`${BASE_API_URL}scenario/vega-api`, params, config);
            const imageUrl = await new Promise (resolve => {
                    try {
                        resolve(`data:image/png;base64,${blobImage.data}`);
                    } catch (error) {
                        console.error('Error [renderBlob]:', error);
                        resolve(error);
                    }
            });

            setData(imageUrl as any);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        };
    };

    const debouncedQuery = useRef(
        debounce(async (query) => {
            getData(query);
        }, 500)
    ).current;

    useEffect(() => {
        if(!vegaParams) return;
        if (Object.keys(vegaParams).length === 0) return;
        debouncedQuery(vegaParams);
    }, [vegaParams, debouncedQuery]);

    useEffect(() => {
        return () => {
            debouncedQuery.cancel();
        };
    }, [debouncedQuery]);

    return { data, error, loading };
};

