import Plot from 'react-plotly.js'

interface ISubsidyChartMarketProps {
    chartData : {
        type : string,
        orientation : string,
        measure : string[],
        x: string[],
        textposition: string,
        text: string[]
        y:number[]
        connector : ILine,
        increasing : IMarker,
        totals : IMarker

    }[]
}

interface ILine {
    line : IColor
}

interface IMarker {
    marker : IColor
}

interface IColor {
    color : string
}





const SubsidyChartMarket = (props : ISubsidyChartMarketProps) => {
    const {chartData} = props

    return(
        <Plot
            //@ts-ignore
            data={chartData}
            layout={{
                width: 205,
                height: 250,
                xaxis: {
                    type: "category",
                    tickangle: -90
                },
                yaxis: {
                    type: "linear", visible : false
                },
                legend : {
                    x: -1,
                    y: -1,
                },
                paper_bgcolor:'rgba(0,0,0,0)',
                plot_bgcolor:"rgba(0,0,0,0)",
                font : {color:"white"},
                margin: {
                    l: 10,
                    r: 10,
                    b: 120,
                    t: 10,
                    pad: 4
                }
            }}
        />
    )
}

export default SubsidyChartMarket