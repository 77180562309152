import CloseIcon from '@mui/icons-material/Close';
import { Button, Box, CircularProgress, Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useConfigure } from '../../context/ConfigureContext';
import { useScenario } from '../../context/ScenarioContext';
import { toDecimal } from '../../utils/functions';
import ScenarioConfiguration, { DEFAULT_IRR_THRESHOLD } from './ScenarioConfiguration';
import { OnOffSwitch } from '../../components/switch';
const configStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width : '90%',
    height: '92%',
    border: '2px solid grey',
    boxShadow: 24,
    borderRadius: '10px',
    p: 1,
    overflow : 'auto'
}

interface IFormData {
    cost_per_foot: string,
    fixed_cost_per_home: string,
    time_to_terminal_penetration: string,
    irr_thresh: string,
    min_thresh: string
    irr: string,
    exclude: string,
}

interface Assumptions {
    archetype : string,
    value : number,
    total_households:number
}

export const convertPercentage = (value: number | string, multiply: boolean) => {
    return multiply ? (parseFloat(value as string) * 100).toFixed(2) : (parseFloat(value as string)/ 100);
}

const ConfigurationModal = ({ scenario, closeModal, detailsOpen, sectionFrom }: { scenario: any, closeModal: () => void, detailsOpen: boolean, sectionFrom:string }) => {
    const { getValues, control, register, handleSubmit, setValue,  watch, formState: { errors } } = useForm();
    const [ irr_thresh, setIrrThresh ] = useState<any>('0');
    const [ min_thresh, setMinThresh ] = useState<any>('0');
    const [ beadCoverAllHouse, setBeadCoverAllHouse ] = useState(false);
    const [ beadFundEnabled, setBeadFundEnabled ] = useState(Boolean(scenario?.bead_funding ?? true));
    const [ assumptions, setAssumptions ] = useState<Assumptions[]>([]);
    const [ archetypeTotalHouseholds, setArchetypeTotalHouseholds ] = useState<{archetype: number; total_hhs: number;}[]>([]);
    const [ isSaved, setIsSaved ] = useState(false);
    const { getConfigDefaults } = useConfigure();

    const handleBeadFundToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBeadFundEnabled(event.target.checked);
    };

    useEffect(() => {
        setBeadCoverAllHouse(!(parseInt(min_thresh) === DEFAULT_IRR_THRESHOLD));
    }, [min_thresh]);

    const {
        defaultConfigs,
        clearConfiguration,
        resetState
    } = useConfigure();

    const {
        isSaving,
        run_models,
        loading,
        setIsScenarioCreated,
        updateScenarioConfig,
        getArchetypeTotalHouseholds,
        setIsRunning
    } = useScenario();

    useEffect(() => {
        if(scenario) {
            setIrrThresh(convertPercentage(scenario?.irr_thresh || defaultConfigs?.clustering.irr_thresh, true));
            setMinThresh(convertPercentage(scenario?.min_thresh || defaultConfigs?.clustering.min_thresh, true));
            getConfigDefaults(scenario?.id);
            getArchetypeTotalHouseholds(scenario?.id).then((householdData: {archetype: number; total_hhs: number;}[]) =>{
                setArchetypeTotalHouseholds(householdData);
                let households1 = householdData?.find(h=> h.archetype === 1)?.total_hhs ?? 0
                let households2 = householdData?.find(h=> h.archetype === 2)?.total_hhs ?? 0
                let households3 = householdData?.find(h=> h.archetype === 3)?.total_hhs ?? 0
                let households4 = householdData?.find(h=> h.archetype === 4)?.total_hhs ?? 0
                let households5 = householdData?.find(h=> h.archetype === 5)?.total_hhs ?? 0
                let households6 = householdData?.find(h=> h.archetype === 6)?.total_hhs ?? 0
                let households7 = householdData?.find(h=> h.archetype === 7)?.total_hhs ?? 0
                const assums = [
                    { archetype: "no_services_available", value: (households1 ? defaultConfigs?.take_up_assumptions?.no_services_available : 0) * 100, total_households: households1 },
                    { archetype: "one_competitor_at_low_speed", value: (households2 ? defaultConfigs?.take_up_assumptions?.one_competitor_at_low_speed : 0) * 100, total_households: households2 },
                    { archetype: "one_competitor_at_high_speed", value: (households3 ? defaultConfigs?.take_up_assumptions?.one_competitor_at_high_speed : 0) * 100, total_households: households3 },
                    { archetype: "two_competitors_with_one_at_high_speed", value: (households4 ? defaultConfigs?.take_up_assumptions?.two_competitors_with_one_at_high_speed : 0) * 100, total_households: households4 },
                    { archetype: "two_competitors_at_high_speed", value: (households5 ? defaultConfigs?.take_up_assumptions?.two_competitors_at_high_speed : 0) * 100, total_households: households5 },
                    { archetype: "more_than_two_competitors_with_two_at_high_speed", value: (households6 ? defaultConfigs?.take_up_assumptions?.more_than_two_competitors_with_two_at_high_speed : 0) * 100, total_households: households6 },
                    { archetype: "more_than_two_competitors_at_high_speed", value: (households7 ? defaultConfigs?.take_up_assumptions?.more_than_two_competitors_at_high_speed : 0) * 100, total_households: households7 }
                ];
                setAssumptions(assums);
                assums.forEach((item)=>{
                    setValue(item.archetype, item.value)
                    setValue(item.archetype+"_households", item.total_households)
                });
            });
        }
    }, [scenario]);

    useEffect(() => {
        setValue('time_to_terminal_penetration', scenario?.time_to_terminal_penetration || defaultConfigs?.cashflow.time_to_terminal_penetration);
        setValue('cost_per_foot', scenario?.cost_per_foot || defaultConfigs?.cashflow.cost_per_foot);
        setValue('fixed_cost_per_home', scenario?.fixed_cost_per_home || defaultConfigs?.cashflow.fixed_cost_per_home);
        setValue("bead_funding", scenario?.bead_funding || defaultConfigs?.cashflow.bead_funding);
        setValue("arpu", scenario?.arpu || defaultConfigs?.cashflow.arpu);
    }, [defaultConfigs]);

    const onSubmit = (data: IFormData | any) => {
        setIsSaved(true);
        if(scenario.id) {
            updateScenarioConfig({
                id: scenario.id,
                county_id: scenario?.county_id,
                time_to_terminal_penetration: data.time_to_terminal_penetration,
                cost_per_foot: data.cost_per_foot,
                fixed_cost_per_home: data.fixed_cost_per_home,
                irr_thresh: toDecimal(irr_thresh || '0'),
                min_thresh: toDecimal(min_thresh || '0'),
                bead_funding: beadFundEnabled ? data?.bead_funding: 0,
                arpu: data?.arpu,
                take_up_assumptions: {
                    no_services_available:data?.no_services_available > 0 ? data?.no_services_available/100: 0,
                    one_competitor_at_low_speed: data?.one_competitor_at_low_speed > 0 ? data?.one_competitor_at_low_speed/100 : 0,
                    one_competitor_at_high_speed: data?.one_competitor_at_high_speed > 0 ? data?.one_competitor_at_high_speed/100: 0,
                    two_competitors_with_one_at_high_speed: data?.two_competitors_with_one_at_high_speed > 0 ?data?.two_competitors_with_one_at_high_speed/100: 0,
                    two_competitors_at_high_speed: data?.two_competitors_at_high_speed > 0 ? data?.two_competitors_at_high_speed/100: 0,
                    more_than_two_competitors_with_two_at_high_speed: data?.more_than_two_competitors_with_two_at_high_speed > 0 ? data?.more_than_two_competitors_with_two_at_high_speed/100: 0,
                    more_than_two_competitors_at_high_speed: data?.more_than_two_competitors_at_high_speed > 0 ? data?.more_than_two_competitors_at_high_speed/100: 0
                }
            }, detailsOpen)
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Paper sx={configStyle}>
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <Typography fontSize="large" textAlign="center" mb={2}  color="text.secondary">Set input for scenario: {scenario?.scenario_name}</Typography>
                <Stack direction="row" spacing={1} paddingRight={4} >
                    <Typography>BEAD funding</Typography>
                    <OnOffSwitch
                        checked = {beadFundEnabled}
                        handleChange={handleBeadFundToggle}
                    />
                </Stack>
            </Box>
                <IconButton
                    size="small"
                    onClick={() => {
                        resetState();
                        setIsScenarioCreated(false);
                        clearConfiguration();
                        closeModal();
                    }}
                    sx={{
                        background: 'white',
                        position: 'absolute',
                        top: 0,
                        right: 0
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Divider />
                <ScenarioConfiguration
                    min_thresh={min_thresh}
                    setMinThresh={setMinThresh}
                    irr_thresh={irr_thresh}
                    setIrrThresh={setIrrThresh}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    beadFundEnabled={beadFundEnabled}
                    beadCoverAllHouse={beadCoverAllHouse}
                    setBeadCoverAllHouse={setBeadCoverAllHouse}
                    errors={errors} watch={watch} register={register}
                    isTotalsLoading={archetypeTotalHouseholds?.length <= 0}
                    scenarioId={scenario.id}
                    sectionFrom={sectionFrom}
                    />
                <Stack
                    direction="row"
                    display="flex"
                    justifyContent="flex-end"
                    spacing={2}
                    marginRight={"33px"}
                    gap={"52px"}
                >
                    <Button
                        type="submit"
                        sx={{
                            minWidth: "15%"
                        }}
                        variant="contained"
                        disabled={!scenario.id || loading}
                    >
                        Save
                        {
                            isSaving && <CircularProgress sx={{ color: "white" }} size={15} />
                        }
                    </Button>
                    <Button
                        disabled={!scenario.id || loading || !isSaved}
                        onClick={() => {
                            if(sectionFrom==='side_panel'){
                                setIsRunning(true);
                            }
                            if(scenario.id) {
                                setIsSaved(false);
                                run_models(scenario.id, scenario?.county_id as string, beadFundEnabled as boolean);
                                resetState();
                                setIsScenarioCreated(false);
                                clearConfiguration();
                                closeModal();
                            }
                        }}
                        sx={{
                            minWidth: "15%"
                        }}
                        variant="contained"
                    >
                        Run
                    </Button>
                </Stack>
            </Paper>
        </form>
    )
}

export default ConfigurationModal;


