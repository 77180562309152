import { Box, IconButton, Typography } from '@mui/material';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const BusinessCaseTableHeader = (props:any) => {
    const { title, showChart, setShowChart, showTable, setShowTable, isChart} = props
    const textStyle = {
        fontWeight:'bold',
        fontSize:'12px'
    }
    const toggleChart = () =>{
      if(!showChart){
        setShowTable(false)
      }
      setShowChart(!showChart); 
      
    }

    const toggleTable = () => {
      if(!showTable && isChart){
        setShowChart(false)
      }
      setShowTable(!showTable)
    }
    return (
        <Box sx={{color:'#0057B8', marginLeft:'10px', paddingTop:'2px', paddingBottom:'2px', borderBottom:'1px solid #797C96', display:'flex', justifyContent:'space-between'}}>
          <Box sx={{marginTop:'10px'}}>
            <Typography sx={textStyle}>
              
              {title}</Typography>
          </Box>
          <Box>
            {isChart &&
              <IconButton
                    aria-label="chart"
                    color="inherit"
                    size="small"
                    onClick={toggleChart}
              >
                  <ShowChartOutlinedIcon fontSize="inherit" sx={{color: showChart ? 'white' : ''}} />
              </IconButton>
            }
            <IconButton
                  aria-label="expand"
                  color="inherit"
                  size="small"
                  onClick={toggleTable}
            >
                {!showTable &&
                  <KeyboardArrowDownIcon sx={{ height:'1.5em', width:'1.5em'}} fontSize="inherit" />
                }
                {showTable &&
                  <KeyboardArrowUpIcon sx={{ height:'1.5em', width:'1.5em'}} fontSize="inherit" />
                }
            </IconButton>
          </Box>
        </Box>
    )
}

export default BusinessCaseTableHeader