import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Alert, CircularProgress, Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { ModelStatus } from '../../context/ConfigureContext';
import { useScenario } from '../../context/ScenarioContext';

const DRAWER_WIDTH = 350;


interface IAlert {
    id: string,
    label: string, 
    timelapsed: string, 
    alertType: ModelStatus,
    run: () => void
}

export const AlertItem = ({ label, timelapsed, alertType, run }: IAlert ) => {
    
    switch(alertType) {
        case ModelStatus.RUNNING:
            return (
                <Alert 
                    action={<IconButton>
                        <CircularProgress color="success" size={20} />
                    </IconButton>}
                    icon={<HourglassBottomIcon fontSize="inherit" />} variant="outlined" severity="success">
                        {label} <br />
                        <small>Time elapsed: {timelapsed}</small>
                </Alert>
            )
        case ModelStatus.FAILED_RUN:
            return (
                <Alert
                    icon={<ErrorIcon fontSize="inherit" />}
                    action={<IconButton onClick={() => run()}>
                        <RefreshIcon />
                    </IconButton>}
                    variant="outlined" 
                    severity="error"
                >
                    {label} <br />
                 <small>Time elapsed: {timelapsed}</small>
                </Alert>
            )
        case ModelStatus.SUCCESSFUL_RUN:
            return (
                <Alert
                    icon={<ErrorIcon fontSize="inherit" />}
                    action={<IconButton>
                        <RefreshIcon />
                    </IconButton>}
                    variant="outlined" 
                    severity="error"
                >
                    {label} <br />
                    <small>Time elapsed: {timelapsed}</small>
                </Alert>
            )
        default:
            return null;
    }
}

const convertScenariosToActivity = (scenarios: any, run_cashflow: any, run_clustering: any) => {
    const activities = []; 
    // const { run_cashflow, run_clustering } = useScenario(); 

    for(const scenario of scenarios) {
        if(scenario?.clustering_status === ModelStatus.RUNNING || scenario?.clustering_status === ModelStatus.FAILED_RUN ) {
            activities.push({
                id: scenario.id,
                label: `${scenario?.scenario_name} - Clustering`, 
                timelapsed: "-",
                alertType: scenario?.clustering_status,
                run: () => run_clustering(scenario.id, scenario?.county_id)
            })
        }
            
        
        if(scenario?.cash_flow_status === ModelStatus.RUNNING || scenario?.cash_flow_status === ModelStatus.FAILED_RUN ) {
            activities.push({
                id: scenario.id,
                label: `${scenario?.scenario_name} - Cashflow`, 
                timelapsed: "-",
                alertType: scenario?.cash_flow_status,
                run: () => run_cashflow(scenario.id, scenario?.county_id)
            })
        }
    }

    return activities;
}

const AlertLog = () => {
  const [isOpen, setOpen] = useState(false);
  const { scenarios, run_cashflow, run_clustering } = useScenario();
 
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  
  const handleDrawerClose = () => {
    setOpen(false);
  };
  
  return (
    <Box display="flex">
        {
          isOpen ?  (
            <Paper elevation={1} style={{ width: DRAWER_WIDTH, paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                >
                    <Typography
                        color="#24232A"
                        fontSize="18px"
                    >Activity Log</Typography>
                    <IconButton
                        sx={{
                            alignSelf: "center"
                        }}
                        onClick={() => handleDrawerClose()}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider light />
                
                <Box
                    display="flex"
                    height="92%"
                    flexDirection="column"
                    justifyContent="space-between"
                >
                    
                    <Stack sx={{ width: '100%', marginTop: '2%' }} spacing={2}>
                        {
                            scenarios && convertScenariosToActivity(scenarios, run_cashflow, run_clustering).map((item) => (
                                <AlertItem 
                                    id={item.id}
                                    label={item.label}
                                    timelapsed={item.timelapsed}
                                    alertType={item.alertType}
                                    run={item.run}
                                    key={item.id}
                                /> 
                            ))
                        }
                        {
                            scenarios && convertScenariosToActivity(scenarios, run_cashflow, run_clustering).length  === 0 && (
                                <Typography align="center"> No items...</Typography>
                            )
                        }
                    </Stack>
                    <Box
                        display="flex"
                        flexDirection="row-reverse"
                        justifyContent="space-between"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            alignSelf="center"
                        >
                            {/* <Typography  color="GrayText" variant="caption" >{ currentTime  && `${timeAgo(new Date(), new Date(currentTime))} ago`}</Typography> */}
                            {/* <Typography  color="GrayText" variant="caption" >{ currentTime }</Typography> */}
                        </Box>
                        <Stack color="#797C96" direction="row">
                            <IconButton>
                                <HourglassBottomIcon fontSize="small" />
                            </IconButton>
                            <Typography alignSelf="center" fontSize="14px">Running</Typography>
                            <IconButton>
                                <ErrorIcon fontSize="small" />
                            </IconButton>
                            <Typography alignSelf="center" fontSize="14px">Error</Typography>

                        </Stack>
                    </Box>
                </Box>
            </Paper>
          ): (
            <>
                <Box
                    component="div"
                    position="fixed"
                    top="40%"
                    sx={{
                        writingMode: "vertical-rl",
                        transform: "rotate(180deg)"
                    }}
                    right='20px'
                >
                    <Typography  
                        onClick={handleDrawerOpen} 
                        color="#029FDB"
                        sx={{
                            cursor: "pointer"
                        }}    
                    >Activity Log</Typography>
                </Box>
            </>
          )
        }
    </Box>
  );
}

export default AlertLog