const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const OKTA_URL = process.env.REACT_APP_OKTA_URL;
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;

const oktaConfig = (redirectUri: string) => {
  return {
    oidc: {
      clientId: CLIENT_ID,
      issuer: `${OKTA_URL}/oauth2/default`,
      redirectUri,
      scopes: ["openid", "profile", "email"],
      pkce: true,
      disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    },
  };
};
export default oktaConfig;
