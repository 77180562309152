import { Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/system';
import * as React from 'react';

const LoadingCard = () => {
    return (
        <Box sx={{ margin: 1}} width="20vw" height="30vh">
           <Stack spacing={1}>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Stack spacing={3} direction="row">
                <Skeleton sx={{borderRadius: '10px'}} variant="rectangular" width={60} height={60} />
                <Skeleton sx={{borderRadius: '10px'}} variant="rectangular" width={60} height={60} />
                <Skeleton sx={{borderRadius: '10px'}} variant="rectangular" width={60} height={60} />
                <Skeleton sx={{borderRadius: '10px'}} variant="rectangular" width={60} height={60} />
                <Skeleton sx={{borderRadius: '10px'}} variant="rectangular" width={60} height={60} />
            </Stack>
            <Skeleton sx={{borderRadius: '10px'}} variant="rectangular" width={'100%'} height={160} />
           
            </Stack>
        </Box>
    )
}

export default LoadingCard;