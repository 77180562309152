import Box from '@mui/material/Box';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import axios from 'axios';
import BASE_API_URL from '../../utils/baseUrl';
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useOktaAuth } from "@okta/okta-react";
import { format } from '../../utils/functions';
import { debounce } from 'lodash';


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN ? process.env.REACT_APP_MAPBOX_API_TOKEN : ''; // TODO: Move to helper function.


const mapContainerStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
};

const popUpStyle = {
    backgroundColor: 'blue',
    borderRadius:'20px'
}

const features = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '50%,',
    overflow: 'auto',
    background: 'rgba(255, 255, 255, 0.8)',
}


interface MapboxProps {
    long?: number;
    latitude?: number;
    mapZoom?: number;
    mapWidth?: number;
    mapHeight?: number;
    scenarioId?: string;
    sourceName? : string;
    sourceUrl?: string;
    householdUrl?: string;
    fibrePathUrl?: string;
    sourceCoordinates?: any[];
    mapType?: string;
    streetMapView?: boolean;
    onGetLocation: (params: Record<string,any>) => void;
    navZoomIn?: () => void;
    navZoomOut?: () => void;
    onClick?: () => void;
    cbgDataList : any[]
    cbgModelOpen : boolean
    setCbgModelOpen : (open:boolean)=>void
    setCbgDataList: React.Dispatch<React.SetStateAction<any[]>>
    refreshData: boolean
    setIsLoadingCBGs:(open:boolean)=>void
    updatePendingScenariosRuns: (scenarioId : string) =>void
    pendingScenariosToRun:string[]
    is_bead_funding?: boolean
};

const Mapbox = forwardRef(({
    long = -70.9,
    latitude = 42.35,
    mapZoom = 9,
    mapWidth = 100,
    mapHeight = 100,
    scenarioId,
    sourceName,
    sourceUrl,
    householdUrl,
    fibrePathUrl,
    sourceCoordinates,
    mapType,
    streetMapView,
    onGetLocation,
    navZoomIn,
    navZoomOut,
    onClick,
    cbgDataList,
    cbgModelOpen,
    setCbgModelOpen,
    setCbgDataList,
    refreshData,
    setIsLoadingCBGs,
    updatePendingScenariosRuns,
    pendingScenariosToRun,
    is_bead_funding,
    ...props
}: MapboxProps, ref) => {
    const { authState } = useOktaAuth();
    const mapContainer : any = useRef(null);
    const map : any = useRef(null);
    const [lng, setLng] = useState(long);
    const [lat, setLat] = useState(latitude);
    const [zoom, setZoom] = useState(mapZoom);

    const [northEastBounds, setNorthEastBounds] = useState<Record<string,any> >();
    const [southWestBounds, setSouthEastBounds] = useState<Record<string,any>>();

    useImperativeHandle(ref, () => ({
        navMapZoomIn(){
            map.current.zoomIn();
        },
        navMapZoomOut(){
            map.current.zoomOut();
        }
    }));
    // @=> Move this to a higher file and pass the state up.
    const config = {
        headers: {
            Authorization: 'Bearer ' + authState?.idToken?.idToken,
            "Content-Type": "application/json"
        }
    };

    const getMapPosition = async () => {
        // Move
        setLng(await map.current.getCenter().lng.toFixed(4));
        setLat(await map.current.getCenter().lat.toFixed(4));
        setZoom(await map.current.getZoom().toFixed(2));
        // Zoom
        setNorthEastBounds(await map.current.getBounds()._ne);
        setSouthEastBounds(await map.current.getBounds()._sw);
        // Emit to parent
        if (northEastBounds && southWestBounds) onGetLocation({lng, lat, zoom, northEastBounds, southWestBounds});
    };

    const changeImage = (url: string, mapSrc: string) => {
        if (!map.current) return;
        if (map.current.isSourceLoaded(mapSrc)) {
            if (northEastBounds && southWestBounds) {
                const imgSource = map.current.getSource(mapSrc);
                imgSource.updateImage({
                    url: url,
                    coordinates: [
                        [southWestBounds.lng, northEastBounds.lat],
                        [northEastBounds.lng, northEastBounds.lat],
                        [northEastBounds.lng, southWestBounds.lat],
                        [southWestBounds.lng, southWestBounds.lat]
                    ]
                });
            };
        };
    };

    const removeImage = (mapLayer: string, mapSrc: string) => {
        if (!map.current) return;
        map.current.removeLayer(mapLayer);
        map.current.removeSource(mapSrc);
    };

    const addMapSource = (
        srcName: string,
        layerName: string,
        blobImgUrl: string
    ) => {
        map.current.on('load', () => {
            if (map.current.getSource(srcName) !== undefined) {
                removeImage(layerName, srcName);
            };

            map.current.addSource(srcName, {
                'type': 'image',
                'url': blobImgUrl,
                'coordinates': sourceCoordinates
            });

            map.current.addLayer({
                'id': layerName,
                'source': srcName,
                'type': 'raster',
                'paint': {
                    'raster-fade-duration': 0
                }
            });

        });

        if (map.current.getSource(srcName) === undefined) {
            map.current.addSource(srcName, {
                'type': 'image',
                'url': blobImgUrl,
                'coordinates': sourceCoordinates
            });

            map.current.addLayer({
                'id': layerName,
                'source': srcName,
                'type': 'raster',
                'paint': {
                    'raster-fade-duration': 0
                }
            });


        };
    };

    let popup = new mapboxgl.Popup({
        className: `${popUpStyle}`,
        closeButton: false,
        closeOnClick: false
    })

    let clickPopup = new mapboxgl.Popup({
        className: `${popUpStyle}`,
        closeButton: false,
        closeOnClick: false
    })

    const showPopup = async(e:any) => {
        map.current.getCanvas().style.cursor = 'wait';
            const { data } = await axios.post(`${BASE_API_URL}business-overview/cbg_summary_metrics`, { scenario_id: scenarioId , lon: e.lngLat.lng, lat: e.lngLat.lat }, config);
            if (data?.geoid) {
                const popupMetrics = `
                <div style="width:300px;">
                    <div style="color:#029FDB; font-size:16px; font-weight:bold; padding-bottom:10px" >
                        CBG ID - ${data?.geoid}
                    </div>
                <div style="display:flex; justify-content:space-between; font-size:14px;">
                        <span>Total HHs :</span>
                        <span style="color:#029FDB" >
                            ${data?.total_households_all && !isNaN(data?.total_households_all) ? format(data?.total_households_all): '0'}
                        </span>
                    </div>
                <div style="display:flex; justify-content:space-between; font-size:14px;">
                        <span>Eligible Households :</span>
                        <span style="color:#029FDB">
                        ${data?.total_bead_eligible_all && !isNaN(data?.total_bead_eligible_all) ? data?.total_bead_eligible_all.toFixed(2) : '0'}
                        </span>
                    </div>
                <div style="display:flex; justify-content:space-between; font-size:14px;">
                        <span>Feet Per Household :</span>
                        <span style="color:#029FDB">
                        ${data?.avg_cablefeetperhome_all && !isNaN(data?.avg_cablefeetperhome_all) ? format(data?.avg_cablefeetperhome_all): '0'}
                        </span>
                    </div>
                <div style="display:flex; justify-content:space-between; font-size:14px;">
                        <span>Take-up Rate :</span>
                        <span style="color:#029FDB">
                        ${data?.avg_terminal_penetration_all && !isNaN(data?.avg_terminal_penetration_all) ? (data?.avg_terminal_penetration_all * 100).toFixed(2): '0'}% 
                        </span>
                    </div>
                <div style="display:flex; justify-content:space-between; font-size:14px;">
                        <span>Upfront Cost Per Home :</span>
                        <span style="color:#029FDB">
                        $${data?.upfront_costperhome_all && !isNaN(data?.upfront_costperhome_all) ? format(data?.upfront_costperhome_all): '0.00'}
                        </span>
                    </div>
                <div style="display:flex; justify-content:space-between; font-size:14px;">
                        <span>Effective Subsidy Per Home :</span>
                        <span style="color:#029FDB">
                        $${data?.requested_subsidyperhome_all && !isNaN(data?.requested_subsidyperhome_all) ? format(data?.requested_subsidyperhome_all): '0'}
                        </span>
                    </div>
                <div style="display:flex; justify-content:space-between; font-size:14px;">
                        <span>Total Capital Without Subsidy :</span>
                        <span style="color:#029FDB">
                        $${data?.total_cap_without_sub_all && !isNaN(data?.total_cap_without_sub_all) ? format(data?.total_cap_without_sub_all): '0'}
                        </span>
                    </div>
                <div style="display:flex; justify-content:space-between; font-size:14px;">
                        <span>Total Capital With Subsidy :</span>
                        <span style="color:#029FDB">
                        $${data?.total_cap_with_sub_all && !isNaN(data?.total_cap_with_sub_all) ? format(data?.total_cap_with_sub_all): '0'}
                        </span>
                    </div>
                <div style="display:flex; justify-content:space-between; font-size:14px;">
                        <span>IRR :</span>
                        <span style="color:#029FDB">
                            ${data?.avg_irr_all && !isNaN(data?.avg_irr_all) ? (data?.avg_irr_all * 100).toFixed(2) : `> ${(data?.irr_threshold * 100).toFixed(2)}`}%
                        </span>
                    </div>
                </div>
                `

                popup.setLngLat(e.lngLat)
                    .setHTML(popupMetrics)
                    .setMaxWidth('none')
                    .addTo(map.current);
            }else{
                popup.remove();
            }
            map.current.getCanvas().style.cursor = '';
    }

    const debouncedPopup =  debounce(showPopup, 2000)

    const handleAddCvg = async (id: string) => {
        setIsLoadingCBGs(true)
        await axios.put(`${BASE_API_URL}clustering/must_build_areas`,{scenario_id: scenarioId, geoid: id}, config);
        const response = await axios.get(`${BASE_API_URL}clustering/must_build_areas?scenario_id=${scenarioId}`, config);
        setCbgDataList([...response.data].filter(Boolean));
        setIsLoadingCBGs(false)
        if(scenarioId){
            updatePendingScenariosRuns(scenarioId)
        }
    }

    const handleRemoveCvg = (id:string) => {
        setIsLoadingCBGs(true)
        axios.delete(`${BASE_API_URL}clustering/must_build_areas?scenario_id=${scenarioId}&geoid=${id}`, config).then((response) =>{
            let cbglist = (document?.getElementById('cbglist') as HTMLInputElement)?.value
            let newCbglist = JSON.parse(cbglist)
            setCbgDataList([...newCbglist.filter((a : any) => a.id !== id)]);
            clickPopup.remove()
            if(scenarioId){
                updatePendingScenariosRuns(scenarioId)
            }
            setIsLoadingCBGs(false)
        });
    }

    useEffect(() => {
        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/dark-v10',
            center: [lng, lat],
            zoom: zoom
        });

        map.current.on('mousemove', async (e: any) => {
            let mapview = (document?.getElementById('map_view') as HTMLInputElement)?.value
            if(mapview ==='area') {
                popup.remove();
                debouncedPopup(e);
            }else{
                clickPopup.remove()
                popup.remove();
            }
        });
        
        if(is_bead_funding){
            // // Add the changes here: @=> https://docs.mapbox.com/mapbox-gl-js/example/queryrenderedfeatures/
            map.current.on('click', async (e: any) => {
                clickPopup.remove()
                popup.remove();
                let mapview = (document?.getElementById('map_view') as HTMLInputElement)?.value
                if(mapview ==='path') return;
                debouncedPopup.cancel()
                map.current.getCanvas().style.cursor = 'wait';
                let cbglist = (document?.getElementById('cbglist') as HTMLInputElement)?.value
                let newCbglist = JSON.parse(cbglist)
                const { data } = await axios.post(`${BASE_API_URL}business-overview/cbg_summary_metrics`, { scenario_id: scenarioId , lon: e.lngLat.lng, lat: e.lngLat.lat }, config);
                if (data) {
                    const divElement = document.createElement('div');                

                    const popupMetrics = `<div style="width:280px;">                
                    <div style="color:#029FDB; font-size:16px; font-weight:bold; padding-bottom:10px" >
                        CBG ID - ${data?.geoid}
                    </div>
                    `;

                    divElement.innerHTML = popupMetrics;
                    const addCbgButton = document.createElement('div');
                    const removeCbgButton = document.createElement('div');
                    if(newCbglist.some((o:any) => o.id === data?.geoid)){
                        const addedCbgButton = document.createElement('div');
                        addedCbgButton.innerHTML = `<button style="margin-left: 15px; width:250px; background-color: #2ecc40; color: #fff; border-radius: 3px; padding: 0.5rem 1rem; border: 1px solid transparent;">Added to Must Build CBG areas</button>`;
                        divElement.appendChild(addedCbgButton);

                        removeCbgButton.innerHTML = `<button style="margin-left: 15px; margin-top:10px; width:250px; border-radius: 3px; padding: 0.5rem 1rem; border: 1px solid red; color:red; background: #fff;">Remove from Must Build CBG areas</button>`;
                        divElement.appendChild(removeCbgButton);
                        removeCbgButton.addEventListener('click', (e) => {
                            handleRemoveCvg(data.geoid);
                        });
                    }else{
                        addCbgButton.innerHTML = `<button style="margin-left: 15px; width:250px; background-color: #0074d9; color: #fff; border-radius: 3px; padding: 0.5rem 1rem; border: 1px solid transparent;">Add to Must Build CBG areas</button>`;
                        divElement.appendChild(addCbgButton);
                        addCbgButton.addEventListener('click', (e) => {
                            data["id"] = data.geoid;
                            newCbglist.push(data);
                            handleAddCvg(data.geoid);
                            (document?.getElementById('cbglist') as HTMLInputElement).value = JSON.stringify(newCbglist);
                            addCbgButton.innerHTML = `<button style="margin-left: 15px; width:250px; background-color: #2ecc40; color: #fff; border-radius: 3px; padding: 0.5rem 1rem; border: 1px solid transparent;">Added to Must Build CBG areas</button>`;
                            divElement.appendChild(addCbgButton);
                            removeCbgButton.innerHTML = `<button style="margin-left: 15px; margin-top:10px; width:250px; border-radius: 3px; padding: 0.5rem 1rem; border: 1px solid red; color:red; background: #fff;">Remove from Must Build CBG areas</button>`;
                            divElement.appendChild(removeCbgButton);
                            removeCbgButton.addEventListener('click', (e) => {
                                handleRemoveCvg(data.geoid);
                            });
                        });
                    }
                    
                
                    popup.remove();
                    clickPopup.setLngLat(e.lngLat)
                    .setDOMContent(divElement)
                    .setMaxWidth('none')
                    .addTo(map.current);
                }
                map.current.getCanvas().style.cursor = '';
                }
            );
        }

    }, []);

    useEffect(() => {
        if (!map.current || !mapType) return;

            map.current.setStyle('mapbox://styles/mapbox/' + mapType);
            map.current.on('style.load', async () => {

                if (!streetMapView) {
                    if (sourceUrl)  addMapSource('map1', 'map1-layer', sourceUrl);
                    if (map.current.getSource('householdSrc') !== undefined)  removeImage('household-layer', 'householdSrc');
                    if (map.current.getSource('fibrePathSrc') !== undefined)  removeImage('fibrePath-layer', 'fibrePathSrc');
                };

                if (streetMapView) {
                    if (householdUrl)  addMapSource('householdSrc', 'household-layer', householdUrl);
                    if (fibrePathUrl)  addMapSource('fibrePathSrc', 'fibrePath-layer', fibrePathUrl);
                    if (map.current.getSource('map1') !== undefined) removeImage('map1-layer', 'map1');
                };
            });
    }, [mapType])

    useEffect(() => {
        if (!map.current) return; // Wait for map to initialize.

        map.current.on('move', () => {
            getMapPosition();
        });

        map.current.on('zoom', () => {
            getMapPosition();
        });
    });

    // for refresh the map after must build cbg run
    useEffect(()=>{
        if (!map.current) return;
        getMapPosition();
    },[refreshData])

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize // Zoom is 13.38
        if (!sourceUrl) return;

        map.current.on('load', () => {
            addMapSource('map1', 'map1-layer', sourceUrl);
        });
    }, []);

    useEffect(() => {
        if (!sourceUrl) return;
        if (map.current.getSource('map1') !== undefined) changeImage(sourceUrl, 'map1');
    }, [sourceUrl]); // Never add changeImage function to the dependency array.

    useEffect(() => {
        if (!householdUrl) return;
        if (map.current.getSource('householdSrc') !== undefined) changeImage(householdUrl, 'householdSrc');
    }, [householdUrl]); // Never add changeImage function to the dependency array.

    useEffect(() => {
        if (!fibrePathUrl) return;
        if (map.current.getSource('fibrePathSrc') !== undefined) changeImage(fibrePathUrl, 'fibrePathSrc');
    }, [fibrePathUrl]); // Never add changeImage function to the dependency array.

    const addStreetLayers = async () => {
        if (householdUrl) await addMapSource('householdSrc', 'household-layer', householdUrl);
        if (fibrePathUrl) await addMapSource('fibrePathSrc', 'fibrePath-layer', fibrePathUrl);
    };

    useEffect(() => {
        if (!sourceUrl) return;
        if (streetMapView) {
            if (map.current.getSource('map1') !== undefined) removeImage('map1-layer', 'map1');
            addStreetLayers();
        } else {
            if (map.current.getSource('householdSrc') !== undefined) removeImage('household-layer', 'householdSrc');
            if (map.current.getSource('fibrePathSrc') !== undefined) removeImage('fibrePath-layer', 'fibrePathSrc');

            if (!map.current.isStyleLoaded()) return;

            addMapSource('map1', 'map1-layer', sourceUrl);
        };
    }, [streetMapView]);

    return (
        <>
            <Box id="features" sx={features}></Box>
            <input type="hidden" id="cbglist" value={JSON.stringify(cbgDataList)}></input>
            <input type="hidden" id="map_view" value={streetMapView ? 'path' : 'area'}></input>
            <Box
                ref={mapContainer}
                sx={
                    {
                        ...mapContainerStyle,
                        height: `${mapHeight}%`,
                        width: `${mapWidth}%`
                    }
                }
            />
        </>
    );
});

export default Mapbox;
